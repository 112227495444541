import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { TUser } from "../types/user.type";
import { TProfile } from "../types/profile.type";
import { ENV } from "../config";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Storage } from "@ionic/storage";
import axios from "axios";
import { TEventParticipant } from "../types/event.type";

const JWT_TOKEN: string = "JWT_TOKEN";
const PROFILE: string = "PROFILE";
const store = new Storage();
var isStorecreated: boolean = false;
const createStore = async (): Promise<void> => {
  try {
    if (!isStorecreated) {
      await store.create();
      isStorecreated = true;
    }
  } catch (e) {
    throw e;
  }
};

export interface IAuthContext {
  user: TUser | null;
  profiles: Array<TProfile>;
  profile: TProfile | null;
  jwt: string;
  showForgottenPassword: boolean;
  setShowForgottenPassword(p: boolean): any;
  login(
    email: string,
    password?: string,
    token?: string,
    code?: string,
    forgotten_password?: boolean
  ): Promise<any>;
  register(email: string, password: string): Promise<void>;
  logout(): Promise<boolean>;
  resetPassword(email: string): Promise<void>;
  update(userData: TUser): Promise<void>;
  updatePassword(password: string): Promise<void>;
  saveJWT(JWT: string): Promise<any>;
  loadCurrentUser(): Promise<any>;
  getProfiles(): Promise<any>;
  setCurrentProfile(profile_id: string): Promise<any>;
  createProfile(data: TProfile): Promise<any>;
  updateProfile(profile_id: string, data: TProfile): Promise<any>;
  deleteProfile(profile_id: string): Promise<any>;
  uploadProfilePicture(profile_id: string, photoUri: any): Promise<any>;
  setLoading(p: boolean): any;
  showProfileChangeModal: boolean;
  setShowProfileChangeModal(p: boolean): any;
  getAccount(): Promise<any>;
  setVariable(name: string, value: any): Promise<any>;
  getVariable(name: string): Promise<any>;
}

const AuthContext = createContext<IAuthContext | any>(null);

export function useAuthState() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setCurrentUser] = useState<TUser | null>(null);
  const [jwt, setJwt] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [showProfileChangeModal, setShowProfileChangeModal] =
    useState<boolean>(false);
  const [showForgottenPassword, setShowForgottenPassword] =
    useState<boolean>(false);
  const [profiles, setProfiles] = useState<Array<TProfile>>([]);
  const [profile, setProfile] = useState<TProfile | null>(null);

  let header = { headers: { Authorization: `Bearer ${jwt}` } };
  /********************************************************
   * PROFILE FUNCTIONS
   ********************************************************/
  const setCurrentProfile = async (profile_id: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const p = profiles.find((e) => e.id === profile_id);
      await createStore();
      await store.remove(PROFILE);
      if (p) {
        setProfile(p);
        await store.set(PROFILE, p);
        resolve(true);
      } else {
        setProfile(null);
        reject(false);
      }
    });
  };

  const getProfiles = (): Promise<Array<TProfile>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<Array<TProfile>>(
          ENV.API_URL + "/profile",
          header
        );
        setProfiles(res.data);
        await createStore();
        let p: TProfile = await store.get(PROFILE);
        if (p) {
          const np: TProfile = res.data.reduce(
            (a: TProfile, e: TProfile): TProfile => {
              if (e.id === p.id) {
                return e;
              }
              return a;
            },
            { name: "" }
          );
          setProfile(np);
        } else {
          const p =
            res.data.find((e) => e.id === profile?.id!) ||
            res.data.find((e) => !e.is_limited) ||
            res.data[0]!;
          if (p) {
            setProfile(p);
            await store.set(PROFILE, p);
          }
        }
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const createProfile = (data: TProfile): Promise<any> => {
    return axios.post(ENV.API_URL + "/profile", data, header);
  };

  const updateProfile = (profile_id: string, data: TProfile): Promise<any> => {
    return axios.put(ENV.API_URL + "/profile/" + profile_id, data, header);
  };

  const deleteProfile = (profile_id: string): Promise<any> => {
    return axios.delete(ENV.API_URL + "/profile/" + profile_id, header);
  };

  const uploadProfilePicture = (
    profile_id: string,
    dataUrl: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(
          ENV.API_URL + "/profile/" + profile_id + "/avatar",
          {
            imageData: dataUrl,
          },
          header
        );
        return resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  /********************************************************
   * ACCOUNT FUNCTIONS
   ********************************************************/
  const register = (data: any): Promise<any> => {
    return axios.post(ENV.API_URL + "/register", data);
  };

  const login = (
    email: string,
    password?: string,
    token?: string,
    code?: string,
    forgotten_password?: boolean
  ): Promise<any> => {
    return axios.post(ENV.API_URL + "/login", {
      email,
      password,
      token,
      code,
      forgotten_password,
    });
  };
  /**
   *
   * @returns Promise boolean
   */
  const logout = (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        await createStore();
        setJwt("");
        await store.remove(JWT_TOKEN);
        await store.remove(PROFILE);
        await store.remove("NOTIFICATIONS");
        setProfiles([]);
        setProfile(null);
        setCurrentUser(null);

        return resolve(true);
      } catch (e) {
        return reject(false);
      }
    });
  };

  const resetPassword = (email: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      return resolve();
    });
  };
  /**
   * Update user record
   * @param userData
   * @returns
   */
  const update = (userData: TUser): Promise<void> => {
    return axios.put(ENV.API_URL + "/account/", userData, header);
  };

  const getAccount = (): Promise<void> => {
    return axios.get(ENV.API_URL + "/account/", header);
  };

  const updatePassword = (password: string): Promise<void> => {
    return axios.put(
      ENV.API_URL + "/account/update-password",
      { password },
      header
    );
  };

  const loadCurrentUser = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await createStore();
        const token = await store.get(JWT_TOKEN);
        setJwt(token);

        header = { headers: { Authorization: `Bearer ${token}` } };
        const payload = jwtDecode<JwtPayload & TUser>(token);
        payload.exp = payload.exp || 0;
        var res: TUser | null = null;
        if (payload.exp * 1000 < Date.now()) {
          res = null;
        } else {
          res = {
            id: payload.id,
            first_name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            authentication_method: payload.authentication_method,
            twofa_method: payload.twofa_method,
            twofa_phone: payload.twofa_phone,
            pin: payload.pin,
            email_notifications_allowed: payload.email_notifications_allowed,
            email_notifications_schedule: payload.email_notifications_schedule,
          };
        }
        setCurrentUser(res);
        if (res) {
          //try to load current profile
          let p = await store.get(PROFILE);
          if (p) {
            setProfile(p);
          }
        }
        return resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  const saveJWT = async (JWT: string): Promise<any> => {
    await createStore();
    setJwt(JWT);
    return store.set(JWT_TOKEN, JWT);
  };

  const setVariable = async (name: string, value: any): Promise<any> => {
    try {
      await createStore();
      return store.set(name, value);
    } catch (error) {
      console.log(error);
    }
  };

  const getVariable = async (name: string): Promise<any> => {
    try {
      await createStore();
      return store.get(name);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    loadCurrentUser()
      .then((res) => {
        getProfiles().finally(() => setLoading(false));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const value: IAuthContext = {
    user: user,
    profiles: profiles,
    login,
    register,
    logout,
    resetPassword,
    update,
    updatePassword,
    saveJWT,
    jwt,
    loadCurrentUser,
    getProfiles,
    createProfile,
    updateProfile,
    setCurrentProfile,
    profile,
    deleteProfile,
    uploadProfilePicture,
    showProfileChangeModal,
    setShowProfileChangeModal,
    showForgottenPassword,
    setShowForgottenPassword,
    setLoading,
    getAccount,
    setVariable,
    getVariable,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import {
  checkmarkOutline,
  checkmarkSharp,
  removeOutline,
  closeSharp,
  trashSharp,
} from "ionicons/icons";
import React, { useState } from "react";
import { TEventWishEngagement } from "../../types/event.type";
import { ENV } from "../../config";
import NumberFormat from "react-number-format";
import PurchaseModal from "./PurchaseModal";
import { useEventState } from "../../components/EventProvider";
import { getErrorMessage } from "../../types/errorMessages";

interface Props {
  ew: TEventWishEngagement;
  loadWish(): void;
}

const ShoppingItem: React.FC<Props> = ({ ew, loadWish }) => {
  const {
    event,
    getEvent,
    getEventWishEngagements,
    updateEngagement,
    disengageWish,
    engageWish,
  } = useEventState();
  const [showAS, setShowAS] = useState<boolean>(false);
  const [showDisengageConfirm, setShowDisengageConfirm] =
    useState<boolean>(false);
  const [showDispurchaseConfirm, setShowDispurchaseConfirm] =
    useState<boolean>(false);
  const [editEventWishEngage, setEditEventWishEngage] =
    useState<TEventWishEngagement | null>(null);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const buttons = [];
  if (ew.is_purchased) {
    buttons.push({
      text: "Mégsem szereztem be",
      icon: removeOutline,
      handler: () => setShowDispurchaseConfirm(true),
    });
  } else {
    buttons.push({
      text: "Beszereztem",
      icon: checkmarkSharp,
      handler: () => setShowPurchaseModal(true),
    });
    buttons.push({
      text: "Törlöm a foglalást",
      icon: removeOutline,
      handler: () => {
        setShowDisengageConfirm(true);
      },
    });
  }
  buttons.push({ text: "Bezár", role: "cancel", icon: closeSharp });

  const handleDispurchase = async () => {
    try {
      const res = await updateEngagement(ew.id, {
        purchased: false,
      });
      await loadWish();
    } catch (err: any) {
      console.log(err.response);
      presentToast({
        message: getErrorMessage(err.response.data.error),
        duration: 3000,
        color: "danger",
      });
    }
  };

  const handleDisengage = async () => {
    try {
      await present();
      let res;
      res = await disengageWish(ew.id);
      presentToast("Rendben, levettük a foglalást!", 3000);
      await loadWish();
    } catch (err: any) {
      console.log(err.response);
      presentToast({
        message: getErrorMessage(err.response.data.error),
        duration: 3000,
        color: "danger",
      });
      await loadWish();
    }
    await dismiss(); 
  };

  return (
    <>
      <IonActionSheet
        isOpen={showAS}
        onDidDismiss={() => setShowAS(false)}
        buttons={buttons}
      ></IonActionSheet>

      <IonModal
        isOpen={showPurchaseModal}
        onDidDismiss={() => setShowPurchaseModal(false)}
        children={
          <PurchaseModal
            onDismiss={() => {
              setShowPurchaseModal(false);
              loadWish();
            }}
            eventWishEngagement={ew}
          />
        }
      />

      <IonAlert
        isOpen={showDisengageConfirm}
        onDidDismiss={() => setShowDisengageConfirm(false)}
        header="Foglalás törlése"
        message={`Biztos, hogy törölni szeretnéd a foglalást?`}
        buttons={[
          "Bocs, véletlen kattintottam",
          {
            text: "Igen, töröljük",
            handler: (d) => handleDisengage(),
          },
        ]}
      />

      <IonAlert
        isOpen={showDispurchaseConfirm}
        onDidDismiss={() => setShowDispurchaseConfirm(false)}
        header="Beszerzés törlése"
        message={`Biztos, hogy nem tudtad beszerezni?`}
        buttons={[
          "Bocs, véletlen kattintottam",
          {
            text: "Igen, töröljük",
            handler: (d) => handleDispurchase(),
          },
        ]}
      />

      <IonItem onClick={() => !event.is_past && setShowAS(true)}>
        <IonImg
          src={ENV.CDN_URL + ew?.Wish?.image_xs!}
          slot="start"
          style={{ width: "30px" }}
        />
        <IonLabel className="ion-text-wrap">
          {ew?.Wish?.name}
          {ew.event_wish_id ? (
            <></>
          ) : (
            <div className="warning">Ez a kívánság törölve lett!</div>
          )}
        </IonLabel>
        {ew.is_purchased && (
          <>
            {ew?.price! > 0 && (
              <IonLabel color="success" slot="end">
                <NumberFormat
                  value={Math.round(ew.price!)}
                  thousandSeparator="&nbsp;"
                  decimalSeparator=","
                  suffix={" " + ew.currency}
                  displayType="text"
                />
              </IonLabel>
            )}
            {!ew?.price! && (
              <IonIcon color="success" slot="end" icon={checkmarkOutline} />
            )}
          </>
        )}
        {!ew.is_purchased && (
          <IonLabel color="warning" slot="end">
            Beszerzés alatt
          </IonLabel>
        )}
      </IonItem>
    </>
  );
};

export default ShoppingItem;

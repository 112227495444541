import {
  IonHeader,
  IonMenu,
  IonTitle,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonMenuToggle,
} from "@ionic/react";
import React from "react";
import { useAuthState } from "./AuthProvider";

interface Props {}

export const MenuComponent = (props: Props) => {
  const { profile } = useAuthState();
  let showSettings = true;
  if (profile && profile.is_limited) {
    showSettings = false;
  }
  return (
    <IonMenu side="end" contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menü</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/home"} routerDirection="none">
              <IonLabel>Kezdőlap</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/events"} routerDirection="none">
              <IonLabel>Események</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/wishes"} routerDirection="none">
              <IonLabel>Kivánságaim</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {showSettings && (
            <IonMenuToggle auto-hide="false">
              <IonItem button routerLink={"/settings"} routerDirection="none">
                <IonLabel>Beállítások</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/info"} routerDirection="none">
              <IonLabel>Info</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

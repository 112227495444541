import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ENV } from "../../config";
import { getErrorMessage } from "../../types/errorMessages";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { TWish } from "../../types/wish.type";
import { useWishState } from "../../components/WishProvider";
import { closeSharp, checkmarkSharp, cameraSharp } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import { TEvent, TEventWish } from "../../types/event.type";

interface Props {
  wish: TWish | null;
  onDismiss(wish_id?: string): void;
  assignToEvent?: string;
}

const schema = yup
  .object({
    name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(50, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    url: yup
      .string()
      .required("Kérlek, töltsd ki ezt a mezőt!")
      .max(500, "Maximum ${max} betű lehetséges"),
    description: yup
      .string()
      .required("Kérlek, töltsd ki ezt a mezőt!")
      .max(2000, "Maximum ${max} betű lehetséges"),
    qty: yup
      .number()
      .min(0, "Kérlek, válassz egy lehetőséget")
      .max(1, "Kérlek, válassz egy lehetőséget")
      .nullable(true)
      .required("Kérlek, válassz egy lehetőséget!"),
    imageData: yup.string(),
    events: yup.array(),
  })
  .required();

type IFormInputs = {
  name: string;
  description: string;
  qty: number;
  url: string;
  [key: string]: any;
};

export const EditWishComponent: React.FC<Props> = ({
  wish,
  onDismiss,
  assignToEvent = "",
}) => {
  const { createWish, updateWish, getImageByUrl, getWishes } = useWishState();
  const { events } = useEventState();
  const { profile } = useAuthState();
  const myWish = wish || ({ id: "" } as TWish);
  if (assignToEvent) {
    myWish.events = [assignToEvent];
  }
  if (myWish.EventWish && myWish.EventWish.length) {
    myWish.events = myWish.EventWish.map((e: TEventWish) => e.event_id!);
  }
  const title = !myWish.name
    ? "Új kívánság létrehozása"
    : "kívánság módosítása";
  const [present, dismiss] = useIonLoading();
  const [presentToast, dismissToast] = useIonToast();
  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: myWish,
  });
  const [message, setMessage] = useState<string>("");
  const [imageData, setimageData] = useState<string>("");
  const [messageHeader, setmessageHeader] = useState<string>("");
  const [isError, setIserror] = useState<boolean>(false);
  const [showCamera, setShowCamera] = useState(true);
  const onSubmit = async (data: IFormInputs) => {
    try {
      data.wish_for = profile.id;
      await present();
      let res: any;
      if (!myWish.name) {
        res = await createWish(data);
      } else {
        res = await updateWish(myWish.id, data);
      }
      await dismiss();
      await getWishes();
      onDismiss();
    } catch (err: any) {
      console.log(err);
      await dismiss();
      if (err && err.response && err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "MAX_WISH_NUMBER_REACHED":
            message =
              "Nem lehet új kívánságot létrehozni, mert elérted a maximális kívánságok számát!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setmessageHeader("Oppsz!");
        setMessage(message as string);
        setIserror(true);
      }
    }
  };

  const takePhoto = async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        quality: 100,
        source: CameraSource.Prompt,
      });

      setValue("imageData", cameraPhoto.dataUrl);
      setimageData(cameraPhoto?.dataUrl!);
    } catch (e: any) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    const subscription = watch(({ url }, { name, type }) => {
      if (name === "url") {
        url = url || "";
        if (url.startsWith("https://")) {
          setShowCamera(false);
          getImageByUrl(url)
            .then((res: any) => {
              if (res?.data?.image) {
                setShowCamera(true);
                setValue("imageData", res?.data?.image!);
                setimageData(res?.data?.image!);
              }
            })
            .catch((err: any) => {
              setShowCamera(true);
              presentToast(
                "A képet sajnos nem lehetett beszerezni az honlapról :(",
                2000
              );
              console.log(err);
            });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {wish && (
        <>
          <IonAlert
            isOpen={isError}
            onDidDismiss={() => setIserror(false)}
            cssClass={"error"}
            header={messageHeader}
            message={message}
            buttons={["Bezár"]}
          />
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="end">
                <IonIcon
                  icon={closeSharp}
                  slot="end"
                  onClick={() => onDismiss()}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonGrid fixed={true}>
                <IonRow className="ion-justify-content-center">
                  <IonCol>
                    <IonList>
                      <IonItem>
                        <IonLabel position="stacked">Kivánság neve</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput
                              {...field}
                              onIonChange={field.onChange}
                              autocapitalize="on"
                            />
                          )}
                          control={control}
                          name="name"
                        />
                        {errors.name?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.name?.message}
                          </div>
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">Hol kapható?</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput
                              {...field}
                              onIonChange={field.onChange}
                              debounce={1000}
                              placeholder="Adj meg linket vagy egyéb infót!"
                            />
                          )}
                          control={control}
                          name="url"
                        />
                        {errors.url?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.url?.message}
                          </div>
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel>Fotó </IonLabel>
                        {showCamera && (
                          <IonIcon
                            onClick={async () => {
                              await takePhoto();
                            }}
                            icon={cameraSharp}
                            slot="end"
                          />
                        )}
                        {!showCamera && <IonSpinner name="bubbles" />}
                      </IonItem>
                      {imageData && (
                        <IonItem className="ion-text-center">
                          <IonImg
                            src={imageData}
                            style={{
                              margin: "0px auto",
                              width: "50%",
                              display: "block",
                            }}
                          />
                        </IonItem>
                      )}
                      {!imageData && wish?.image_lg! && (
                        <IonItem className="ion-text-center">
                          <IonImg
                            src={ENV.CDN_URL + wish?.image_lg!}
                            style={{
                              margin: "0px auto",
                              width: "50%",
                              display: "block",
                            }}
                          />
                        </IonItem>
                      )}
                      <IonItem>
                        <IonLabel position="stacked">Leírás</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonTextarea
                              {...field}
                              autocapitalize="on"
                              maxlength={500}
                              onIonChange={field.onChange}
                              rows={4}
                              placeholder="Írj bővebben valamit a kívánságodról"
                            />
                          )}
                          control={control}
                          name="description"
                        />
                        {errors.description?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.description?.message}
                          </div>
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">
                          Hány ilyet szeretnél kapni?
                        </IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonSelect
                              interface="alert"
                              okText="Rendben"
                              cancelText="Mégsem"
                              {...field}
                              onIonChange={field.onChange}
                              placeholder="Válassz egy lehetőséget"
                            >
                              <IonSelectOption value={0}>
                                Több is jöhet!
                              </IonSelectOption>
                              <IonSelectOption value={1}>
                                Csak egyet
                              </IonSelectOption>
                            </IonSelect>
                          )}
                          control={control}
                          name="qty"
                        />
                        {errors.qty?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.qty?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">
                          Hozzárendelt események
                        </IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonSelect
                              multiple
                              okText="Rendben"
                              cancelText="Mégsem"
                              {...field}
                              onIonChange={field.onChange}
                              placeholder="Válaszd ki melyik eseménynél jelenjen meg"
                            >
                              {!!events &&
                                events.map((e: TEvent) => (
                                  <IonSelectOption value={e.id} key={e.id}>
                                    {e.name}
                                  </IonSelectOption>
                                ))}
                            </IonSelect>
                          )}
                          control={control}
                          name="events"
                        />
                      </IonItem>
                    </IonList>

                    <IonRow className="ion-align-items-center">
                      <IonCol className="ion-text-end">
                        <IonButton type="submit" shape="round" color="primary">
                          Mentés
                          <IonIcon icon={checkmarkSharp} slot="end" />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonContent>
        </>
      )}
    </>
  );
};

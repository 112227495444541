import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import React, { useState } from "react";
import { useEventState } from "../../components/EventProvider";
import { TEventParticipant } from "../../types/event.type";

interface Props {
  participant: TEventParticipant;
  onDismiss(): void;
}

const PendingModal: React.FC<Props> = ({ participant, onDismiss }) => {
  const [present, dismiss] = useIonLoading();
  const { resendInvite, deleteParticipant, getEvent } = useEventState();
  const [presentToast] = useIonToast();
  const [showConfirm, setShowConfirm] = useState(false);
  const handleResend = async () => {
    try {
      await present();
      const res = await resendInvite(participant.id);
      console.log(res);
      await dismiss();
      presentToast("A meghívót újra elküldtük emailben!", 4000);
    } catch (error: any) {
      console.log(error.response.data);
      switch (error.response.data.error) {
        case "MAX_NUMBER_OF_RESEND_REACHED":
          presentToast({
            message:
              "Többször sajnos nem tudjuk elküldeni a meghívót! Ellenőrizd, hogy jól adtad-e meg az email címet",
            duration: 4000,
            color: "danger",
          });
          break;
        default:
          presentToast({
            message: "Valamilyen hiba történt!",
            duration: 4000,
            color: "danger",
          });
      }
      await dismiss();
    }
  };

  const handleDelete = async () => {
    try {
      await present();
      const res = await deleteParticipant(participant.id);
      console.log(res);
      await dismiss();
      presentToast("Törlés rendben!", 4000);
      await getEvent(participant.event_id);
      onDismiss();
    } catch (error: any) {
      console.log(error.response.data);
      switch (error.response.data.error) {
        case "MAX_NUMBER_OF_RESEND_REACHED":
          presentToast({
            message:
              "Többször sajnos nem tudjuk elküldeni a meghívót! Ellenőrizd, hogy jól adtad-e meg az email címet",
            duration: 4000,
            color: "danger",
          });
          break;
        default:
          presentToast({
            message: "Valamilyen hiba történt!",
            duration: 4000,
            color: "danger",
          });
      }
      await dismiss();
    }
  };

  if (!participant) {
    setTimeout(() => onDismiss(), 100);
    return <></>;
  } else {
    return (
      <>
        <IonAlert
          isOpen={showConfirm}
          onDidDismiss={() => setShowConfirm(false)}
          header="Meghívó törlése"
          message="Biztos, hogy törölni szerentéd ezt a meghívót?"
          buttons={[
            "Mégsem",
            {
              text: "Igen, törlöm a meghívót.",
              handler: () => handleDelete(),
            },
          ]}
        />
        <IonHeader>
          <IonToolbar>
            <IonTitle>Részletek</IonTitle>
            <IonButtons slot="end">
              <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>{participant.user.email}</IonItem>
            <IonItem>
              Hozzáadhat kívánságot
              {participant.can_post_wish ? (
                <IonIcon color="success" icon={checkmarkSharp} slot="end" />
              ) : (
                <IonIcon color="danger" icon={closeSharp} slot="end" />
              )}
            </IonItem>
            <IonItem>
              Teljesíthet kívánságot
              {participant.can_engage_wish ? (
                <IonIcon color="success" icon={checkmarkSharp} slot="end" />
              ) : (
                <IonIcon color="danger" icon={closeSharp} slot="end" />
              )}
            </IonItem>
            <IonItem>
              Adminisztrátori hozzáférés
              {participant.is_admin ? (
                <IonIcon color="success" icon={checkmarkSharp} slot="end" />
              ) : (
                <IonIcon color="danger" icon={closeSharp} slot="end" />
              )}
            </IonItem>
          </IonList>
          <IonButton expand="full" onClick={() => handleResend()}>
            Meghívó újraküldése
          </IonButton>
          <IonButton
            color="danger"
            expand="full"
            onClick={() => setShowConfirm(true)}
          >
            Meghívó törlése
          </IonButton>
        </IonContent>
      </>
    );
  }
};

export default PendingModal;

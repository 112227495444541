import React from "react";

interface Props {
  step: number;
}
const StepCounter: React.FC<Props> = ({ step }) => {
  return (
    <div className="stepCounter">
      <div className={"step " + (step >= 1 ? "selected" : "")}>1</div>
      <div className={"step " + (step >= 2 ? "selected" : "")}>2</div>
      <div className={"step " + (step >= 3 ? "selected" : "")}>3</div>
    </div>
  );
};

export default StepCounter;

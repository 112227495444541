import React, { useState } from "react";
import { TEventParticipant } from "../../types/event.type";
import { useAuthState } from "../../components/AuthProvider";
import { Controller, useForm } from "react-hook-form";
import { useEventState } from "../../components/EventProvider";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
  IonSelect,
  IonSelectOption,
  useIonLoading,
  useIonToast,
  IonAlert,
  IonNote,
} from "@ionic/react";
import { closeSharp, checkmarkSharp } from "ionicons/icons";
import { getErrorMessage } from "../../types/errorMessages";
import { RouteComponentProps, withRouter } from "react-router-dom";

type Props = RouteComponentProps & {
  onDismiss(): void;
  ep: TEventParticipant;
};
const schema = yup
  .object({
    profile_id: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
    can_post_wish: yup.boolean(),
    can_engage_wish: yup.boolean(),
    is_admin: yup.boolean(),
  })
  .required();

export type IFormInputs = {
  can_post_wish: boolean;
  can_engage_wish: boolean;
  is_admin: boolean;
  [key: string]: any;
};

const ProfileSettingsModal: React.FC<Props> = ({ ep, onDismiss, history }) => {
  const { event, updateParticipant, deleteParticipant, getEvent } =
    useEventState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    control,
    register,
    clearErrors,
    setValue,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: ep,
  });
  const handleDelete = async () => {
    try {
      await deleteParticipant(ep.id);
      presentToast("Törlés rendben!", 3000);
      await getEvent(event.id);
      history.push("/event/" + event.id + "/participants");
      onDismiss();
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();
      const res = await updateParticipant(ep.id, data);
      await getEvent(event.id);
      await dismiss(); 
      presentToast({
        message: "Adatmódosítás rendben!",
        duration: 3000,
        color: "dark",
      });
      onDismiss();
    } catch (err: any) {
      await dismiss(); 
      const { error } = err.response.data;
      if (error) {
        presentToast({
          message: getErrorMessage(error),
          duration: 3000,
          color: "warning",
        });
      }
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showConfirm}
        onDidDismiss={() => setShowConfirm(false)}
        cssClass={"error"}
        header="Törlés megerősítése"
        message={`Biztos, hogy el szeretnéd távolítani ${ep.profile.name}-t az eseményből?`}
        buttons={[
          "Bocs, véletlen kattintottam",
          {
            text: "Igen",
            handler: (d) => handleDelete(),
          },
        ]}
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle>{ep.profile.name} jogosultságai</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                {ep.profile.is_limited && (
                  <div className="ion-text-center">
                    <IonNote>Korlátozott profil. </IonNote>
                  </div>
                )}
                <IonList>
                  <IonItem>
                    <IonLabel>Hozzáadhat kívánságot</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="can_post_wish"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Teljesíthet kívánságot</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          disabled={ep.profile.is_limited}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="can_engage_wish"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Adminisztrátor</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          disabled={
                            event.myProfile.id == ep.id || ep.profile.is_limited
                          }
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="is_admin"
                    />
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-text-end">
                <IonButton type="submit" shape="round" color="primary">
                  Mentés
                  <IonIcon icon={checkmarkSharp} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <br /> <br /> <br /> <br />
        {event.myProfile.id != ep.id && (
          <IonItem color="danger" onClick={() => setShowConfirm(true)}>
            <IonLabel>Eltávolítás az eseményből</IonLabel>
            <IonIcon slot="end" icon={closeSharp} />
          </IonItem>
        )}
      </IonContent>
    </>
  );
};
export default withRouter(ProfileSettingsModal);

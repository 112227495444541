import React, { createContext, useContext, useEffect, useState } from "react";
import { TFilterParams, TWish, TWishReceive } from "../types/wish.type";
import { ENV } from "../config";
import axios from "axios";
import { useAuthState } from "./AuthProvider";

export interface IWishContext {
  wishes: Array<TWish>;
  wishCount: number;
  createWish(data: TWish): Promise<any>;
  updateWish(wish_id: string, data: TWish): Promise<any>;
  deleteWish(wish_id: string): Promise<any>;
  getImageByUrl(url: string): Promise<any>;
  getWishes(filterParam?: TFilterParams): Promise<Array<TWish>>;
  getWish(wish_id: string): Promise<any>;
  getWishReceives(wish_id: string): Promise<any>;
  createWishReceive(wish_id: string, data: TWishReceive): Promise<any>;
  updateWishReceive(
    wish_id: string,
    receive_id: number,
    data: TWishReceive
  ): Promise<any>;
  deleteWishReceive(wish_id: string, receive_id: number): Promise<any>;
}

const WishContext = createContext<IWishContext | any>(null);

export const useWishState = () => {
  const context = useContext(WishContext);
  if (context === undefined) {
    throw new Error("useWishState must be used within a WishProvider");
  } else {
    return context;
  }
};

export const WishProvider: React.FC = ({ children }) => {
  const { jwt, profile } = useAuthState();
  const header = { headers: { Authorization: `Bearer ${jwt}` } };
  const [page, setPage] = useState(0);
  const [wishes, setWishes] = useState<Array<TWish>>([]);
  const [wishCount, setWishCount] = useState<number>(0);

  const createWish = (data: TWish): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(
          ENV.API_URL + `/${profile.id}/wish`,
          data,
          header
        );
        await getWishes();
        return resolve(res.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  const deleteWish = (wish_id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = axios.delete(
          ENV.API_URL + `/${profile.id}/wish/${wish_id}`,
          header
        );
        const i = wishes.findIndex((e: TWish) => e.id === wish_id);
        wishes.splice(i, 1);
        return resolve(res);
      } catch (error) {
        return reject(error);
      }
    });
  };

  const updateWish = (wish_id: string, data: TWish): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(
          ENV.API_URL + `/${profile.id}/wish/${wish_id}`,
          data,
          header
        );
        const i = wishes.findIndex((e: TWish) => e.id === wish_id);
        wishes[i] = { ...res.data };

        return resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getWish = async (wish_id: string): Promise<any> => {
    return axios.get(ENV.API_URL + `/${profile.id}/wish/${wish_id}`, header);
  };

  const getWishes = (filterParam?: TFilterParams): Promise<Array<TWish>> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!jwt) return reject(false);
        const p = filterParam?.page!;
        const params = { ...header, params: filterParam };
        let res;
        if (!profile) {
          res = {
            data: {
              rows: [],
              count: 0,
            },
          };
        } else {
          const pid = profile.id;
          res = await axios.get<{ count: number; rows: Array<TWish> }>(
            ENV.API_URL + `/${pid}/wish`,
            params
          );
        }
        setWishCount(res.data?.count!);
        if (p) {
          setWishes([...wishes, ...res.data?.rows!]);
        } else {
          setWishes(res.data?.rows!);
        }
        return resolve(res.data.rows);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getImageByUrl = (url: string): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/get_image_by_url`,
      { url },
      header
    );
  };

  const getWishReceives = async (wish_id: string): Promise<any> => {
    return axios.get(
      ENV.API_URL + `/${profile.id}/wish/${wish_id}/receive`,
      header
    );
  };

  const createWishReceive = (
    wish_id: string,
    data: TWishReceive
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(
          ENV.API_URL + `/${profile.id}/wish/${wish_id}/receive`,
          data,
          header
        );
        return resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  };

  const updateWishReceive = (
    wish_id: string,
    receive_id: number,
    data: TWishReceive
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(
          ENV.API_URL + `/${profile.id}/wish/${wish_id}/receive/${receive_id}`,
          data,
          header
        );
        return resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  };

  const deleteWishReceive = (
    wish_id: string,
    receive_id: number
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = axios.delete(
          ENV.API_URL + `/${profile.id}/wish/${wish_id}/receive/${receive_id}`,
          header
        );
        return resolve(res);
      } catch (error) {
        return reject(error);
      }
    });
  };

  useEffect(() => {
    if (profile && jwt) {
      getWishes();
    }
  }, [profile]);

  const value: IWishContext = {
    wishes,
    getWish,
    createWish,
    updateWish,
    deleteWish,
    getWishes,
    getImageByUrl,
    wishCount,
    getWishReceives,
    createWishReceive,
    updateWishReceive,
    deleteWishReceive,
  };
  return <WishContext.Provider value={value}>{children}</WishContext.Provider>;
};

import {
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonImg,
  IonModal,
  useIonToast,
  IonNote,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import { buildSharp, chevronBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import { useEventState } from "../../components/EventProvider";
import ImageViewer from "../../components/ImageViewer";
import ProfileImageModal from "../../components/ProfileImageModal";
import { ENV } from "../../config";
import { TEventParticipant, TEventWish } from "../../types/event.type";
import ProfileSettingsModal from "./ProfileSettingsModal";
type TEventParticipantPageParam = { event_participant_id: string };
type Props = RouteComponentProps & {};

const EventParticipant: React.FC<Props> = ({ history }) => {
  const { event_participant_id } = useParams<TEventParticipantPageParam>();
  const [showImg, setShowImg] = useState(false);
  const { event, getWishes } = useEventState();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const { myProfile } = event;
  const currentProfile: TEventParticipant = event.participants.find(
    (p: TEventParticipant) => p.id == +event_participant_id
  );
  const [eventWishes, setEventWishes] = useState<Array<TEventWish>>([]);
  const [eventWishCount, setEventWishCount] = useState(0);
  //szűrés az elérhetőkhöz
  const [available, setAvailable] = useState(false);
  const loadWishes = async () => {
    try {
      if (currentProfile) {
        let params: any = { profile_id: currentProfile.profile.id };
        const res = await getWishes(params);
        let count = 0;
        let data: Array<TEventWish> = [];
        if (available) {
          data = res.data.rows.filter(
            (e: TEventWish) => !(e.Engagements && e.Engagements?.length > 0)
          );
          count = data.length;
        } else {
          count = res.data.count;
          data = res.data.rows;
        }
        setEventWishes(data);
        setEventWishCount(count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentProfile) {
      loadWishes();
    }
  }, [event_participant_id, available]);

  if (currentProfile) {
    return (
      <>
        <IonModal
          isOpen={showImg}
          onDidDismiss={() => setShowImg(false)}
          children={
            <ImageViewer
              onDismiss={() => setShowImg(false)}
              imageUrl={ENV.CDN_URL + currentProfile.profile.image_lg}
            />
          }
        />
        <IonModal
          isOpen={showSettingsModal}
          onDidDismiss={() => setShowSettingsModal(false)}
          children={
            <ProfileSettingsModal
              onDismiss={() => setShowSettingsModal(false)}
              ep={currentProfile}
            />
          }
        />
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
              <IonRow className="ion-justify-content-left ion-align-items-start">
                <IonCol>
                  {event.myProfile.id !== currentProfile.id && (
                    <>
                      <h4>{currentProfile.profile.name} kívánságai</h4>
                      <div>({currentProfile.profile.age} éves)</div>
                    </>
                  )}
                  {event.myProfile.id == currentProfile.id && (
                    <h4>A te kívánságaid:</h4>
                  )}
                </IonCol>
                <IonCol className="flexGrowZero">
                  <IonAvatar onClick={() => setShowImg(true)}>
                    <IonImg
                      src={ENV.CDN_URL + currentProfile.profile.image_xs}
                    />
                  </IonAvatar>
                </IonCol>
              </IonRow>
              <IonButton
                fill="clear"
                size="small"
                onClick={() => history.goBack()}
              >
                <IonIcon icon={chevronBack} slot="start" />
                Vissza
              </IonButton>
              {!event.is_past && myProfile.is_admin && (
                <IonButton
                  fill="clear"
                  size="small"
                  className="ion-float-right"
                  onClick={() => setShowSettingsModal(true)}
                >
                  <IonIcon icon={buildSharp} />
                </IonButton>
              )}
              <IonRow
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IonCol className="ion-text-left" size="12" size-sm="6">
                  <IonItem lines="none">
                    <IonLabel color="tertiary">
                      Összesen {eventWishCount} kívánság
                    </IonLabel>
                  </IonItem>
                </IonCol>
                {event.myProfile.can_engage_wish &&
                  event.myProfile.id !== currentProfile.id && (
                    <IonCol className="ion-text-right" size="12" size-sm="6">
                      <IonItem lines="none">
                        <IonLabel>Csak ami még szabad</IonLabel>
                        <IonToggle
                          slot="end"
                          checked={available}
                          onIonChange={(e) => setAvailable(e.detail.checked)}
                        ></IonToggle>
                      </IonItem>
                    </IonCol>
                  )}
              </IonRow>

              <IonRow className="ion-justify-content-start">
                {eventWishes.map((w: TEventWish) => (
                  <IonCol
                    size="12"
                    size-md="6"
                    size-lg="4"
                    size-xl="4"
                    key={w.id}
                  >
                    <IonCard routerLink={`/event/${event.id}/wish/${w.id}`}>
                      <IonImg src={ENV.CDN_URL + w.Wish?.image_lg!} />
                      <IonCardHeader>
                        <IonCardSubtitle>{w.Wish?.name}</IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                  </IonCol>
                ))}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  } else {
    return null;
  }
};

export default withRouter(EventParticipant);

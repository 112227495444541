import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ENV } from "../config";
import { TBillingAccount } from "../types/billingAccount.type";
import { useAuthState } from "./AuthProvider";

export interface IBillingAccountContext {
  billingAccounts: Array<TBillingAccount>;
  getRecords(): Promise<any>;
  getRecord(billing_acount_id: string): Promise<TBillingAccount>;
  updateRecord(billing_acount_id: string, data: TBillingAccount): Promise<any>;
  createRecord(data: TBillingAccount): Promise<any>;
  deleteRecord(billing_acount_id: string): Promise<any>;
}

const BillingAccountContext = createContext<IBillingAccountContext | any>(null);

export const useBillingAccountState = () => {
  const context = useContext(BillingAccountContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};

export const BillingAccountProvider: React.FC = ({ children }) => {
  const { jwt, user } = useAuthState();
  const [billingAccounts, setBillingAccounts] = useState<
    Array<TBillingAccount>
  >([]);
  const header = { headers: { Authorization: `Bearer ${jwt}` } };
  const createRecord = (data: TBillingAccount): Promise<any> => {
    return axios.post(ENV.API_URL + `/billing_account`, data, header);
  };
  const deleteRecord = (billing_acount_id: string): Promise<any> => {
    return axios.delete(
      ENV.API_URL + `/billing_account/${billing_acount_id}`,
      header
    );
  };
  //assssssasss
  const updateRecord = (
    billing_acount_id: string,
    data: TBillingAccount
  ): Promise<any> => {
    return axios.put(
      ENV.API_URL + `/billing_account/${billing_acount_id}`,
      data,
      header
    );
  };

  const getRecords = (): Promise<Array<TBillingAccount>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<Array<TBillingAccount>>(
          ENV.API_URL + `/billing_account`,
          header
        );
        setBillingAccounts(res.data);
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };
  const getRecord = (billing_acount_id: string): Promise<TBillingAccount> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<TBillingAccount>(
          ENV.API_URL + `/billing_account/${billing_acount_id}`,
          header
        );
        return resolve(res?.data!);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const value: IBillingAccountContext = {
    billingAccounts,
    updateRecord,
    createRecord,
    deleteRecord,
    getRecords,
    getRecord,
  };

  return (
    <BillingAccountContext.Provider value={value}>
      {children}
    </BillingAccountContext.Provider>
  );
};

import React from "react";
import { QuantityList, TWish, TWishReceive } from "../../types/wish.type";
import { ENV } from "../../config";
import {
  IonImg,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from "@ionic/react";

import moment from "moment";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import ReceiveList from "./ReceiveList";

interface Props {
  wish: TWish;
  receives?: boolean;
  children?: JSX.Element;
  editHandler?(wishReceive: TWishReceive): void;
  deleteHandler?(wishReceive: TWishReceive): void;
}

export const DetailWishComponent: React.FC<Props> = ({
  wish,
  children,
  receives,
  editHandler,
  deleteHandler,
}) => {
  const image = ENV.CDN_URL + wish?.image_lg!;
  return (
    <>
      {wish && (
        <>
          <IonCard>
            <IonGrid>
              <IonRow className="ion-justify-content-left">
                <IonCol size="12" size-md="4">
                  <IonImg src={image} />
                </IonCol>
                <IonCol size="12" size-md="4">
                  <IonCardContent className="ion-text-left">
                    <IonCardTitle>{wish.name}</IonCardTitle>
                    <div className="ion-margin-top">
                      <p>{wish.description}</p>
                    </div>
                    <div className="ion-margin-top">
                      Lelőhely:{" "}
                      {wish.url?.startsWith("https://") ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            InAppBrowser.create(wish.url!, "_blank");
                            return false;
                          }}
                        >
                          {wish.url}
                        </a>
                      ) : (
                        wish.url
                      )}
                    </div>

                    <div className="ion-margin-top">
                      {QuantityList[wish?.qty!]}
                    </div>
                    <div className="ion-margin-top">
                      Dátum: {moment(wish.created_at).format("YYYY-MM-DD")}
                    </div>
                  </IonCardContent>
                </IonCol>
                <IonCol size="12" size-md="4">
                  {children}
                </IonCol>
              </IonRow>
              {receives && (
                <IonRow className="ion-justify-content-left">
                  <IonCol>
                    <ReceiveList
                      wish={wish}
                      editHandler={editHandler!}
                      deleteHandler={deleteHandler!}
                    />
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </IonCard>
        </>
      )}
    </>
  );
};

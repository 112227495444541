import {
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  useIonLoading,
  useIonToast,
  IonNote,
  IonInput,
  IonTextarea,
  IonButton,
  IonCard,
  IonCardContent,
  IonAvatar,
  IonImg,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { identity } from "rxjs";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import { getErrorMessage } from "../../types/errorMessages";
import {
  TEventParticipant,
  TEventWish,
  TEventWishComment,
} from "../../types/event.type";

interface Props {
  eventWish: TEventWish;
}

const EventWishComment: React.FC<Props> = ({ eventWish }) => {
  const [remarkType, setRemarkType] = useState("public");
  const { getComments, addComment, event } = useEventState();
  const { profile } = useAuthState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const [comments, setComments] = useState<Array<TEventWishComment>>([]);
  const [commentCounts, setCommentCounts] = useState(0);
  const [myComment, setMyComment] = useState("");

  const handleSendComment = async () => {
    if (myComment.length) {
      try {
        const res = await addComment(eventWish.id, {
          text: myComment,
          remark_type: remarkType,
        });
        await loadComments();
        setMyComment("");
      } catch (err: any) {
        presentToast({
          message: getErrorMessage(err.response.data.error),
          duration: 3000,
          color: "danger",
        });
      }
    }
  };
  const loadComments = async () => {
    try {
      let res = await getComments(eventWish.id, { remark_type: remarkType });
      setComments(res.data.rows);
      setCommentCounts(res.data.count);
    } catch (err) {
      console.log(err);
    }
  };

  let showSegment = true;
  if (profile.is_limited || profile.id == eventWish.Wish?.created_by) {
    showSegment = false;
  }
  useEffect(() => {
    loadComments();
  }, [remarkType, eventWish]);

  const SegmentHint = {
    public:
      "Ezeket a kommenteket mindenki látja, beleértve a kívánság tulajdonosát is.",
    "semi-private":
      "Ezeket a kommenteket mindenki látja, kivéve a kívánság tulajdonosát.",
    private: "Ezeket a kommenteket csak te látod.",
  };
  return (
    <div>
      <hr />
      <h4>Kommentek</h4>

      {showSegment && (
        <>
          <IonSegment
            value={remarkType}
            mode="ios"
            onIonChange={(e) => {
              setRemarkType(e.detail.value!);
            }}
          >
            <IonSegmentButton value="public">Publikus</IonSegmentButton>
            <IonSegmentButton value="semi-private">
              Fél-publikus
            </IonSegmentButton>
            <IonSegmentButton value="private">Saját megj.</IonSegmentButton>
          </IonSegment>
          {remarkType == "public" && (
            <div className="segmentHint">
              Ezeket a kommenteket mindenki látja, beleértve a kívánság
              tulajdonosát is.
            </div>
          )}
          {remarkType == "semi-private" && (
            <div className="segmentHint">
              Ezeket a kommenteket mindenki látja, kivéve a kívánság
              tulajdonosát.
            </div>
          )}
          {remarkType == "private" && (
            <div className="segmentHint">
              Ezeket a kommenteket csak te látod.
            </div>
          )}
        </>
      )}
      <div className="ion-text-center ion-padding-top">
        {!!commentCounts && (
          <div className="ion-margin-bottom">
            <IonNote>Összesen {commentCounts} komment</IonNote>
          </div>
        )}

        {comments.map((c: TEventWishComment) => {
          const ep: TEventParticipant | undefined = event.participants.find(
            (p: TEventParticipant) => p.profile_id == c.created_by
          );
          if (ep) {
            const { profile } = ep;
            return (
              <div className="comment-wrapper" key={c.id}>
                <IonAvatar>
                  <IonImg src={ENV.CDN_URL + profile.image_xs} />
                </IonAvatar>
                <div className="comment">
                  <div className="author">{profile.name}</div>
                  <div className="date">
                    {moment(c.created_at).format("YYYY-MM-DD HH:mm")}
                  </div>
                  {c.text}
                </div>
              </div>
            );
          }
        })}
        {!event.is_past && (
          <>
            <IonTextarea
              placeholder="Szólj hozzá valamit"
              style={{
                textAlign: "left",
                border: "1px solid var(--ion-color-light)",
                padding: "8px",
                fontSize: "12px",
                borderRadius: "5px",
              }}
              value={myComment}
              onIonChange={(e: any) => setMyComment(e.detail.value)}
            />
            <IonButton
              color="tertiary"
              size="small"
              onClick={() => handleSendComment()}
            >
              Küldés
            </IonButton>
          </>
        )}
      </div>
    </div>
  );
};

export default EventWishComment;

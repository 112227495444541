import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonImg,
  IonNote,
  IonButton,
  IonIcon,
  useIonLoading,
  useIonToast,
  IonListHeader,
  IonModal,
  IonActionSheet,
  IonBadge,
} from "@ionic/react";
import {
  addSharp,
  mailOutline,
  personAddOutline,
  peopleOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import { TEventParticipant } from "../../types/event.type";

import PendingModal from "./PendingModal";
import { useAuthState } from "../../components/AuthProvider";
import { TProfile } from "../../types/profile.type";
import AddProfileToEvent from "./AddProfileToEvent";
import EmailInvitationForm from "./EmailInvitationForm";
import OldEventsForm from "./OldEventsForm";
import {
  RouteComponentProps,
  useRouteMatch,
  withRouter,
} from "react-router-dom";

type Props = RouteComponentProps & {};

const EventParticipants: React.FC<Props> = ({ history }) => {
  let { url } = useRouteMatch();
  const { event, getEvent } = useEventState();
  const { profile, profiles } = useAuthState();
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showOldEventsForm, setShowOldEventsForm] = useState(false);
  const [showAS, setShowAS] = useState(false);
  const [showPending, setShowPending] = useState(0);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const participants = event.participants.filter(
    (p: TEventParticipant) => p.is_accepted === true
  );
  const pendingParticipants = event.participants.filter(
    (p: TEventParticipant) => p.is_accepted !== true
  );

  const availableProfiles = profiles.filter(
    (p: TProfile) =>
      !event.participants.find((ep: TEventParticipant) => ep.profile_id == p.id)
  );

  const showAddButton =
    !event.is_past &&
    (event.myProfile.is_admin ||
      (!profile.is_limited && availableProfiles.length > 0));

  const closeModal = async () => {
    setShowPending(0);
  };

  const buttons = [
    {
      text: "Emailben egy ismerősömet",
      handler: () => setShowEmailForm(true),
      icon: mailOutline,
    },
    {
      text: "Régebbi esemény résztvevőit",
      handler: () => setShowOldEventsForm(true),
      icon: peopleOutline,
    },
  ];
  if (availableProfiles.length > 0) {
    buttons.push({
      text: "Egy profilomat szeretném hozzáadni",
      handler: () => setShowProfileForm(true),
      icon: personAddOutline,
    });
  }
  return (
    <>
      <IonActionSheet
        isOpen={showAS}
        onDidDismiss={() => setShowAS(false)}
        buttons={buttons}
      ></IonActionSheet>

      <IonModal
        isOpen={showProfileForm}
        onDidDismiss={() => setShowProfileForm(false)}
        backdropDismiss={false}
        children={
          <AddProfileToEvent onDismiss={() => setShowProfileForm(false)} />
        }
      />

      <IonModal
        isOpen={showEmailForm}
        backdropDismiss={false}
        onDidDismiss={() => setShowEmailForm(false)}
        children={
          <EmailInvitationForm onDismiss={() => setShowEmailForm(false)} />
        }
      />

      <IonModal
        isOpen={showOldEventsForm}
        backdropDismiss={false}
        onDidDismiss={() => setShowOldEventsForm(false)}
        children={
          <OldEventsForm onDismiss={() => setShowOldEventsForm(false)} />
        }
      />

      <IonModal
        isOpen={showPending > 0}
        backdropDismiss={false}
        onDidDismiss={() => closeModal()}
        children={
          <PendingModal
            participant={event.participants.find(
              (p: TEventParticipant) => p.id === showPending
            )}
            onDismiss={closeModal}
          />
        }
      />
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            <IonRow className="ion-justify-content-center ion-align-items-center ">
              <IonCol>
                <IonNote color="primary">
                  {event.participants.length} résztvevő (max:{" "}
                  {event.max_participants})
                </IonNote>
              </IonCol>
              {showAddButton && (
                <IonCol className="ion-text-end">
                  <IonButton
                    onClick={() => {
                      if (event.myProfile.is_admin) {
                        setShowAS(true);
                      } else {
                        if (
                          !profile.is_limited &&
                          availableProfiles.length > 0
                        ) {
                          setShowProfileForm(true);
                        }
                      }
                    }}
                  >
                    <IonIcon icon={addSharp} slot="start" />
                    Meghívás
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
            <IonList lines="full">
              {participants.length &&
                participants.map((p: TEventParticipant) => (
                  <IonItem href={`${url}/${p.id}`} key={p.id}>
                    <IonAvatar slot="start">
                      <IonImg src={ENV.CDN_URL + p.profile.image_xs} />
                    </IonAvatar>
                    <IonLabel>{p.profile.name}</IonLabel>
                    <IonBadge color="primary" slot="end">
                      {p.number_of_wishes}
                    </IonBadge>
                  </IonItem>
                ))}
            </IonList>
            {!!pendingParticipants.length && !!event.myProfile.is_admin && (
              <IonList lines="full">
                <IonListHeader>Függőben lévő meghívások</IonListHeader>
                {pendingParticipants.map((p: TEventParticipant) => (
                  <IonItem
                    key={p.id}
                    onClick={() => setShowPending(p.id)}
                    detail
                  >
                    <IonLabel className="ion-text-wrap">
                      {p.user.email}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default withRouter(EventParticipants);

import { IonModal, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { MenuComponent } from "./components/MenuComponent";

import { Switch } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "./components/AuthProvider";
import { ProfileSelectorModal } from "./components/ProfileSelectorModal";
import LoginPage from "./pages/account/LoginPage";
import RegisterPage from "./pages/account/RegisterPage";
import EventCheckoutPage from "./pages/event/EventCheckoutPage";
import EventListPage from "./pages/event/EventListPage";
import EventPage from "./pages/event/EventPage";
import NewEventPage from "./pages/event/NewEventPage";
import HomePage from "./pages/home/HomePage";
import InfoPage from "./pages/home/InfoPage";
import IntroPage from "./pages/home/IntroPage";
import BillingAccountPage from "./pages/settings/BillingAccountPage";
import ProfilePage from "./pages/settings/ProfilePage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import WishListPage from "./pages/wish/WishListPage";
import WishPage from "./pages/wish/WishPage";

interface Props {}

export const AppRouter: React.FC = (props: Props) => {
  const { showProfileChangeModal, user } = useAuthState();
  return (
    <IonReactRouter>
      <MenuComponent />
      <IonModal
        isOpen={showProfileChangeModal}
        backdropDismiss={true}
        children={<ProfileSelectorModal />}
      />

      {!user && (
        <IonRouterOutlet id="main">
          <Route path="/login" exact component={LoginPage} />
          <Route path="/register" component={RegisterPage} exact />
          <Route path="/intro" component={IntroPage} exact />
          <Redirect to="/intro" />
        </IonRouterOutlet>
      )}
      {user && (
        <IonRouterOutlet id="main">
          <Switch>
            <Route path="/home" component={HomePage} exact />
            <Route path="/events" component={EventListPage} exact />
            <Route path="/new-event" component={NewEventPage} />
            <Route
              path="/event/:event_id/checkout"
              component={EventCheckoutPage}
            />
            <Route path="/event/:event_id" component={EventPage} />

            <Route path="/wishes" component={WishListPage} exact />
            <Route path="/wish/:wish_id" exact component={WishPage} />
            <Route path="/settings" component={SettingsPage} exact />
            <Route path="/profiles" component={ProfilePage} exact />
            <Route path="/info" component={InfoPage} exact />
            <Route
              path="/billing-accounts"
              component={BillingAccountPage}
              exact={true}
            />
            <Redirect to="/home" />
          </Switch>
        </IonRouterOutlet>
      )}
      {/* <Route path="/profiles" component={ProfilePage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/wishes" component={WishPage} />
        <Route path="/home" component={HomePage} />
        <Route path="/events" component={EventPage} /> */}
    </IonReactRouter>
  );
};

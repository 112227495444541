import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import Info from "./Info";
import { closeSharp } from "ionicons/icons";

interface Props {
  onDismiss(): void;
}

const InfoModal: React.FC<Props> = ({ onDismiss }) => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Információk</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Info />
      </IonContent>
    </>
  );
};

export default InfoModal;

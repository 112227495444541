import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import React, { useState } from "react";

interface Props {
  element: string;
}

const PasswordReveal: React.FC<Props> = ({ element }) => {
  const [type, setType] = useState("password");
  const handleClick = () => {
    const el = document.getElementsByName(element)[1];
    const t = el.getAttribute("type") === "password" ? "text" : "password";
    el.setAttribute("type", t);
    setType(t);
  };
  return (
    <>
      <IonIcon
        icon={type == "password" ? eyeOutline : eyeOffOutline}
        onClick={() => handleClick()}
        className="passwordReveal"
      />
    </>
  );
};

export default PasswordReveal;

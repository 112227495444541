import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonModal,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import React, { useState } from "react";
import logo from "../../assets/logo.svg";

import christmasParty from "../../assets/christmas-party.png";
import giftBox from "../../assets/gift-box.png";
import giftTag from "../../assets/gift-tag.png";
import giftBoxes from "../../assets/giftboxes.png";
import message from "../../assets/message.png";
import shoppingCart from "../../assets/shopping-cart.png";
import "./IntroPage.scss";
import "swiper/swiper-bundle.min.css";
import "@ionic/react/css/ionic-swiper.css";
import InfoModal from "./InfoModal";
interface Props {}
type SlideData = {
  header: string;
  icon: string;
  text: string;
};
type SlideProps = {
  data: SlideData;
};
const IntroPage: React.FC = (props: Props) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loop: true,
  };
  const slides: Array<SlideData> = [
    {
      header: "Megfelelő kívánságok",
      icon: giftBox,
      text: "Kívánságaidat eseményekbe rendezve megoszthatod családtagjaiddal, ismerőseiddel.",
    },
    {
      header: "A  felesleges ajándék a múlté",
      icon: message,
      text: "Családtagjaid, ismerőseid megoszthatják veled kívánságaikat, ezáltal olyan ajándékot adhatsz, amire igazán vágynak.",
    },
    {
      header: "A megspórolt idő és pénz",
      icon: shoppingCart,
      text: "A megosztott kívánságoknak köszönhetően, az ajándékok beszerzésével időt és pénzt spórolhatsz.",
    },
    {
      header: "Kívánságlista mindenkinek",
      icon: giftTag,
      text: "A megfelelő kívánságokat kérni, a legjobb ajándékokat adni.",
    },
    {
      header: "Mindig van mit ünnepelni",
      icon: christmasParty,
      text: "Töltsd fel kívánságaidat. Oszd meg mindenkivel.",
    },
    {
      header: "Kívánságok egy helyen ",
      icon: giftBoxes,
      text: "Tedd könnyebbé az ünneplést. Add a legjobb ajándékokat.",
    },
  ];

  const handleInfoDismiss = () => {
    setShowInfo(false);
  };

  return (
    <IonPage>
      <IonModal
        isOpen={showInfo}
        children={<InfoModal onDismiss={handleInfoDismiss} />}
      />
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow
            style={{ padding: "20px 0   " }}
            className="ion-justify-content-center"
          >
            <IonCol size="12" size-md="10" size-lg="4" size-xl="4">
              <IonIcon
                style={{ fontSize: "100px", color: "#0040ff" }}
                icon={logo}
              />
              <h1>EZKIVI</h1>
              <IonRow className="ion-padding-top ion-text-center">
                <IonCol>
                  <IonButton color="tertiary" expand="block" href="/register">
                    Regisztráció
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton color="success" expand="block" href="/login">
                    Bejelentkezés
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding-top ion-text-center">
                <IonCol>
                  <IonButton
                    color="light"
                    expand="block"
                    onClick={() => setShowInfo(true)}
                  >
                    Információk
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSlides pager={true} options={slideOpts}>
                    {slides.map((s: SlideData, i: number) => (
                      <Slide data={s} key={i} />
                    ))}
                  </IonSlides>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const Slide: React.FC<SlideProps> = ({ data }) => {
  const { header, icon, text } = data;
  return (
    <IonSlide>
      <IonCol size="12">
        <h2>{header}</h2>
        <IonImg src={icon} className="slider-image" />
        <p className="ion-padding-bottom">{text}</p>
      </IonCol>
    </IonSlide>
  );
};
export default IntroPage;

import { key } from "ionicons/icons";
import { TEventWish } from "./event.type";
import { TProfile } from "./profile.type";

export type TWish = {
  id?: string;
  name: string;
  wish_for?: string;
  description?: string;
  qty?: number;
  url?: string;
  created_by?: string;
  is_active?: boolean;
  is_verified?: boolean;
  image_xs?: string;
  image_lg?: string;
  created_at?: string;
  created?: TProfile;
  selected?: boolean;
  EventWish?: Array<TEventWish>;
  events?: Array<string>;
};

export type TWishReceive = {
  id?: number;
  wish_id?: string;
  event?: string;
  received_from?: string;
  qty?: number;
  remark?: string;
  created_at?: string;
};
export type TFilterParams = {
  name?: string;
  page?: number;
};
interface TQty {
  [key: number]: string;
}
export const QuantityList: TQty = {
  1: "Csak egyet szeretnék",
  0: "Több is jöhet belőle!",
};

import { IonText } from "@ionic/react";
import React from "react";
import { TPlan } from "../types/event.type";
import PlanPrice from "./PlanPrice";

interface Props {
  plan: TPlan;
}

const PlanDetails: React.FC<Props> = ({ plan }) => {
  return (
    <>
      <h4>{plan.name}</h4>
      <p>{plan.description}</p>
      <ul className="plan-properties">
        <li>
          Résztvevők száma: <b>max {plan.max_participants} fő</b>
        </li>
        <li>
          Kívánságok száma: <b>max {plan.max_wishes} </b>{" "}
        </li>
        <li>
          Kommentelési lehetőség:&nbsp;
          {plan.comments_allowed ? (
            <IonText color="success">igen</IonText>
          ) : (
            <IonText color="danger">nem</IonText>
          )}
        </li>
      </ul>
    </>
  );
};

export default PlanDetails;

import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  useIonToast,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonNote,
  IonRadio,
  useIonLoading,
  IonButton,
  IonCol,
  IonModal,
  useIonAlert,
  IonCardContent,
  IonCard,
} from "@ionic/react";
import { useEventState } from "../../components/EventProvider";
import {
  addSharp,
  arrowBackCircleSharp,
  arrowForwardCircleSharp,
  checkmarkSharp,
  closeSharp,
} from "ionicons/icons";
import { useBillingAccountState } from "../../components/BillingAccoutProvider";
import { TPlan } from "../../types/event.type";
import PlanDetails from "../../components/PlanDetails";
import PlanPrice from "../../components/PlanPrice";
import { TBillingAccount } from "../../types/billingAccount.type";
import BillingAccountDetails from "../../components/BillingAccountDetails";
import EditBillingAccountPage from "../settings/EditBillingAccountPage";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../../components/PaymentFormFields.css";
import { StripeCardElementOptions } from "@stripe/stripe-js";

var elementStyles = {
  base: {
    color: "#222222",
    fontWeight: 600,
    fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
    fontSize: "18px",
    fontSmoothing: "antialiased",

    ":focus": {
      color: "#424770",
    },

    "::placeholder": {
      color: "#9BACC8",
    },

    ":focus::placeholder": {
      color: "#CFD7DF",
    },
  },
  invalid: {
    color: "#420000",
    ":focus": {
      color: "#7a1400",
    },
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

const cardElementOpts: StripeCardElementOptions = {
  iconStyle: "solid",
  style: elementStyles,
};

interface Props {
  onDismiss(): void;
}
const PlanUpgradeModal: React.FC<Props> = ({ onDismiss }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { event, upgrade, plans } = useEventState();
  const { billingAccounts, getRecords, createRecord } =
    useBillingAccountState();
  const [editBARecordId, setEditBARecordId] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [selectedBA, setSelectedBA] = useState<string>("");
  const [showBAModal, setShowBAModal] = useState<boolean>(false);
  const [paymentIntent, setPaymentIntent] = useState<any>(false);
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [error, setError] = useState<string>("");
  const [presentToast, dismissToast] = useIonToast();

  const handleBAEdit = (profile_id: string) => {
    setEditBARecordId(profile_id);
    setShowBAModal(true);
  };
  const currentPlan = plans.find((p: TPlan) => p.id === event.plan_id);

  const plans_ = plans.reduce((acc: Array<TPlan>, p: TPlan, i: number) => {
    if (p.id! > event.plan_id) {
      const p_: TPlan = { ...p };
      p_.price = p.price! - currentPlan.price!;
      p_.gross_price = p.gross_price! - currentPlan.gross_price!;
      acc.push(p_);
    }
    return acc;
  }, []);

  const handleDismiss = () => {
    setShowBAModal(false);
  };

  useEffect(() => {
    getRecords().finally((res: any) => {
      // await dismiss();
    });
  }, []);

  const handleNext = async () => {
    if (!selectedPlan) {
      presentAlert({
        message: "Kérlek, válassz egy csomagot!",
        buttons: ["Rendben"],
      });
      return false;
    }
    if (!selectedBA) {
      presentAlert({
        message: "Kérlek, válassz egy számlázási címet!",
        buttons: ["Rendben"],
      });
      return false;
    }
    try {
      await present();
      const result = await upgrade(selectedPlan, selectedBA);
      setPaymentIntent(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      await dismiss();
    }
  };

  const handlePayment = async () => {
    try {
      setError("");
      const CardElem = elements?.getElement(CardNumberElement);
      if (CardElem && elements && stripe && paymentIntent?.client_secret!) {
        await present();
        const paymentResult = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: {
              card: CardElem,
            },
          }
        );
        if (paymentResult.error) {
          setError(paymentResult.error.message!);
        } else {
          presentToast("Sikeres fizetés!", 3000);

          if (onDismiss) {
            onDismiss();
          }
        }
      }
    } catch (err: any) {
      console.log(err);
      setError(err?.error.message);
    } finally {
      await dismiss();
    }
  };

  const renderPaymentForm = () => {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Online Fizetés</IonTitle>
            {onDismiss && (
              <IonButtons slot="end">
                <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid fixed={true}>
            <h4>
              Kérlek, add meg a bankkártya adatok a fizetés befejezéséhez:
            </h4>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <div className="cardWrapper">
                  <div className="cardNumber">
                    <CardNumberElement options={cardElementOpts} />
                  </div>
                </div>
                <div className="cardWrapper">
                  <div className="cardExpiry">
                    <CardExpiryElement options={cardElementOpts} />{" "}
                  </div>
                  <div className="cardCVC">
                    <CardCvcElement options={cardElementOpts} />
                  </div>
                </div>
                {error && (
                  <div className="ion-text-center errorMessage">{error}</div>
                )}
              </IonCol>
            </IonRow>

            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <IonButton
                    onClick={() => onDismiss()}
                    color="danger"
                    fill="clear"
                  >
                    Mégsem
                  </IonButton>
                </IonCol>
                <IonCol className="ion-text-end">
                  <IonButton
                    type="button"
                    className="ion-margin"
                    disabled={!stripe && !elements}
                    onClick={() => handlePayment()}
                  >
                    <IonIcon icon={checkmarkSharp} slot="end" />
                    Fizetés
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonCard
              style={{
                background: "var(--ion-color-primary)",
                color: "var(--ion-color-primary-contrast)",
              }}
            >
              <IonCardContent>
                Fizetési partnerünk:{" "}
                <a
                  href="https://stripe.com"
                  target="_blank"
                  style={{ color: "var(--ion-color-primary-contrast)" }}
                  rel="noreferrer"
                >
                  Stripe.com
                </a>
                <br />
                Az EZKIVI nem tárol vagy kezel bankkártya adatokat!
              </IonCardContent>
            </IonCard>
          </IonGrid>
        </IonContent>
      </>
    );
  };

  const renderPlanSelector = () => {
    return (
      <>
        <IonModal
          isOpen={showBAModal}
          backdropDismiss={false}
          children={
            <EditBillingAccountPage
              editRecordId={editBARecordId}
              onDismiss={handleDismiss}
            />
          }
        />
        <IonHeader>
          <IonToolbar>
            <IonTitle>Válassz egy csomagot</IonTitle>
            <IonButtons slot="end">
              <IonIcon
                icon={closeSharp}
                slot="end"
                onClick={() => onDismiss()}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRadioGroup onIonChange={(e) => setSelectedPlan(e.detail.value)}>
            {plans_.map((p: TPlan) => (
              <IonItem key={p.id?.toString()}>
                <IonLabel className="ion-text-wrap">
                  <PlanDetails plan={p} />
                </IonLabel>
                <IonNote slot="end" color="primary">
                  <PlanPrice price={p.gross_price!} currency="HUF" />
                </IonNote>
                <IonRadio slot="start" value={p.id} />
              </IonItem>
            ))}
          </IonRadioGroup>

          <IonItem>
            <IonLabel>
              <h2>Válassz számlázási címet:</h2>
            </IonLabel>
            <IonButton
              shape="round"
              onClick={() => handleBAEdit("new")}
              color="success"
            >
              Új számlázási cím megadása
              <IonIcon icon={addSharp} slot="start" />
            </IonButton>
          </IonItem>
          {billingAccounts.length > 0 && (
            <>
              <IonRadioGroup onIonChange={(e) => setSelectedBA(e.detail.value)}>
                {billingAccounts.map((b: TBillingAccount) => (
                  <IonItem key={b.id}>
                    <IonLabel className="ion-text-wrap">
                      <BillingAccountDetails billingAccount={b} />
                    </IonLabel>
                    <IonRadio slot="start" value={b.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </>
          )}

          <div className="ion-text-center" style={{ margin: "20px 0px" }}>
            <IonButton
              shape="round"
              color="success"
              onClick={() => handleNext()}
            >
              Tovább az online fizetéshez
              <IonIcon icon={arrowForwardCircleSharp} slot="end" />
            </IonButton>
          </div>
        </IonContent>
      </>
    );
  };
  console.log(paymentIntent);
  return !paymentIntent ? renderPlanSelector() : renderPaymentForm();
};

export default PlanUpgradeModal;

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  useIonLoading,
  useIonToast,
  IonCol,
  IonGrid,
  IonList,
  IonRow,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthState } from "../../components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getErrorMessage } from "../../types/errorMessages";
import YupPassword from "yup-password";
import PasswordReveal from "../../components/PasswordReveal";
YupPassword(yup); // extend yup
interface Props {
  onDismiss(): void;
}

type IFormInputs = {
  old_password: string;
  password: string;
  passwordConfirmation: string;
  [key: string]: any;
};

const schema = yup
  .object({
    old_password: yup
      .string()
      .required("Kérlek, add meg a jelenlegi jelszavadat!"),
    password: yup
      .string()
      .min(8, "Legalább ${min} karakter szükséges")
      .max(72, "Maximum ${min} karakter lehetséges")
      .minLowercase(1, "Legalább 1 kisbetű szükséges")
      .minUppercase(1, "Legalább 1 nagybetű szükséges")
      .minNumbers(1, "Legalább 1 szám szükséges"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "A megadott jelszavak nem egyeznek!"),
  })
  .required();

export const ChangePasswordComponent: React.FC<Props> = ({ onDismiss }) => {
  const { user, update, saveJWT, loadCurrentUser, getProfiles } =
    useAuthState();
  const [present, dismiss] = useIonLoading();
  const [isError, setIserror] = useState<boolean>(false);
  const [presentToast] = useIonToast();
  let {
    control,
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: user,
  });

  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();
      let res;
      res = await update(data);

      await dismiss(); 
      presentToast("Jelszómódosítás rendben!", 3000);
      setTimeout(() => onDismiss(), 300);
    } catch (err: any) {
      console.log(err.response);
      await dismiss(); 
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      }
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Jelszómódosítás</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">Jelenlegi jelszó</IonLabel>
                    <PasswordReveal element="old_password" />
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="password"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="old_password"
                    />
                  </IonItem>
                  {errors.old_password?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.old_password?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel position="stacked">Új jelszó</IonLabel>
                    <PasswordReveal element="password" />
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="password"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="password"
                    />
                  </IonItem>
                  {errors.password?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.password?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel position="stacked">Jelszó ellenörzése</IonLabel>
                    <PasswordReveal element="passwordConfirmation" />
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="password"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="passwordConfirmation"
                    />
                  </IonItem>
                  {errors.passwordConfirmation?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.passwordConfirmation?.message}
                    </div>
                  )}
                </IonList>
                <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-end">
                    <IonButton type="submit" shape="round" color="primary">
                      Mentés
                      <IonIcon icon={checkmarkSharp} slot="end" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

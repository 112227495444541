import { IonButton, IonIcon, IonMenu, IonMenuButton } from "@ionic/react";
import { homeSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useAuthState } from "./AuthProvider";
type TMQuery = {
  matches: boolean;
};
interface Props {}

export const NavigationComponent: React.FC = (props: Props) => {
  const { profile } = useAuthState();
  const [mQuery, setMquery] = useState<any>({
    matches: window.innerWidth > 768 ? true : false,
  });
  let showSettings = true;
  if (profile && profile.is_limited) {
    showSettings = false;
  }
  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", setMquery);
    return () => mediaQuery.removeListener(setMquery);
  }, []);
  return (
    <div>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton color="light" />
      ) : (
        <>
          <IonButton color="light" routerLink="/home">
            <IonIcon icon={homeSharp} />
          </IonButton>
          <IonButton color="light" routerLink="/events">
            Események
          </IonButton>
          <IonButton color="light" routerLink="/wishes">
            Kívánságaim
          </IonButton>
          {showSettings && (
            <IonButton color="light" routerLink="/settings">
              Beállítások
            </IonButton>
          )}
          <IonButton color="light" routerLink="/info">
            Info
          </IonButton>
        </>
      )}
    </div>
  );
};

import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonNote,
  useIonLoading,
  useIonToast,
  IonModal,
} from "@ionic/react";
import {
  checkmarkSharp,
  closeSharp,
  arrowBackCircleSharp,
  arrowForwardCircleSharp,
  addSharp,
} from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import StepCounter from "./StepCounter";
import { useEventState } from "../../components/EventProvider";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { TPlan } from "../../types/event.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBillingAccountState } from "../../components/BillingAccoutProvider";
import { TBillingAccount } from "../../types/billingAccount.type";
import EditBillingAccountPage from "../settings/EditBillingAccountPage";
import PlanDetails from "../../components/PlanDetails";
import PlanPrice from "../../components/PlanPrice";
import BillingAccountDetails from "../../components/BillingAccountDetails";

interface IFormInputs {
  plan_id: number;
  billing_account_id?: string;
}

const schema = yup.object({
  plan_id: yup.number().required("Kérlek, válassz egy csomagot!"),
  billing_account_id: yup.string(),
});
interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

const Step2: React.FC<Props> = ({ setStep }) => {
  const history = useHistory();
  const { plans } = useEventState();
  const { actions, state } = useStateMachine({ updateAction });
  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: state,
  });

  const [showBAModal, setShowBAModal] = useState<boolean>(false);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const [editBARecordId, setEditBARecordId] = useState<string>("");

  const { billingAccounts, getRecords, createRecord } =
    useBillingAccountState();

  const plan_id = watch("plan_id");
  const selectedPlan = plans.find((p: TPlan) => p.id === plan_id);

  const onSubmit = (data: IFormInputs) => {
    if (selectedPlan.price > 0 && !data.billing_account_id!) {
      setError("billing_account_id", {
        type: "custom",
        message: "Kérlek, add meg a számlázási címedet",
      });
      return false;
    }
    actions.updateAction(data);
    setStep(3);
  };

  const handleBAEdit = (profile_id: string) => {
    setEditBARecordId(profile_id);
    setShowBAModal(true);
  };

  const handleDismiss = () => {
    setShowBAModal(false);
  };

  useEffect(() => {
    present().then(() => {
      getRecords().finally((res: any) => {
        dismiss();
      });
    });
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonModal
        isOpen={showBAModal}
        backdropDismiss={false}
        children={
          <EditBillingAccountPage
            editRecordId={editBARecordId}
            onDismiss={handleDismiss}
          />
        }
      />

      <StepCounter step={2} />
      <h4 className="ion-text-center">Válassz csomagot</h4>

      <Controller
        render={({ field }) => (
          <IonRadioGroup {...field} onIonChange={field.onChange}>
            {plans.map((p: TPlan) => (
              <IonItem key={p.id?.toString()}>
                <IonLabel className="ion-text-wrap">
                  <PlanDetails plan={p} />
                </IonLabel>
                <IonNote slot="end" color="primary">
                  <PlanPrice price={p.gross_price!} currency="HUF" />
                </IonNote>
                <IonRadio slot="start" value={p.id} />
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
        control={control}
        name="plan_id"
      />
      {errors.plan_id?.message && (
        <div className="ion-text-start errorMessage">
          {errors.plan_id?.message}
        </div>
      )}
      {selectedPlan?.price > 0 && (
        <>
          {billingAccounts.length > 0 && (
            <>
              <IonItem>
                <IonLabel>
                  <h2>Válassz számlázási címet:</h2>
                </IonLabel>
              </IonItem>
              <Controller
                render={({ field }) => (
                  <IonRadioGroup {...field} onIonChange={field.onChange}>
                    {billingAccounts.map((b: TBillingAccount) => (
                      <IonItem key={b.id}>
                        <IonLabel className="ion-text-wrap">
                          <BillingAccountDetails billingAccount={b} />
                        </IonLabel>
                        <IonRadio slot="start" value={b.id} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                )}
                control={control}
                name="billing_account_id"
              />
            </>
          )}
          {errors.billing_account_id?.message && (
            <div className="ion-text-center errorMessage ion-padding">
              {errors.billing_account_id?.message}
            </div>
          )}
          <div className="ion-text-center">
            <IonButton
              shape="round"
              onClick={() => handleBAEdit("new")}
              color="success"
            >
              Új számlázási cím megadása
              <IonIcon icon={addSharp} slot="start" />
            </IonButton>
          </div>
        </>
      )}
      <IonRow className="ion-align-items-center">
        <IonCol className="ion-text-start">
          <IonButton
            type="button"
            shape="round"
            color="light"
            onClick={() => setStep(1)}
          >
            Vissza
            <IonIcon icon={arrowBackCircleSharp} slot="start" />
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-end">
          <IonButton type="submit" shape="round" color="primary">
            Tovább
            <IonIcon icon={arrowForwardCircleSharp} slot="end" />
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  );
};

export default Step2;

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { TEvent } from "../../types/event.type";
import { ENV } from "../../config";
import Icon from "@mui/material/Icon";
import moment from "moment";
interface Props {
  event: TEvent;
}

const EventComponent: React.FC<Props> = ({ event }) => {
  const history = useHistory();
  const router = useIonRouter();
  return (
    <IonCard onClick={() => router.push(`/event/${event.id}`)}>
      <IonImg src={ENV.CDN_URL + event.image_xs} />
      {event.myProfile?.is_admin! && (
        <Icon className="adminIcon">admin_panel_settings</Icon>
      )}
      <IonCardHeader>
        <IonCardTitle>{event.name}</IonCardTitle>
        <IonCardSubtitle>
          {event.location}{" "}
          <div style={{ float: "right" }}>
            {moment(event.take_place_on).format("YYYY-MM-DD HH:mm")}
          </div>
        </IonCardSubtitle>
      </IonCardHeader>
      {!event.is_paid && <div className="errorMessage">Fizetésre vár!</div>}
      {event.is_past && (
        <div className="errorMessage">Az esemény véget ért!</div>
      )}
      <IonCardContent></IonCardContent>
    </IonCard>
  );
};

export default EventComponent;

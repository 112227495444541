import React from "react";
import { TCurrency, TEventParticipant } from "../../types/event.type";
import { useForm, Controller } from "react-hook-form";
import { useAuthState } from "../../components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useEventState } from "../../components/EventProvider";
import { getErrorMessage } from "../../types/errorMessages";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
interface Props {
  eventParticipant: TEventParticipant;
  onDismiss(): void;
}
const CURRENCIES = [
  {
    code: "HUF",
    name: "Forint",
    symbol: "Ft",
    rate: 1,
    symbol_position: "r",
  },
];
const schema = yup
  .object({
    price: yup.number().nullable().notRequired(),
    currency: yup.string().nullable().notRequired(),
  })
  .required();

type IFormInputs = {
  planned_budget: number;
  planned_budget_currency: string;
  [key: string]: any;
};

const BudgetModal: React.FC<Props> = ({ eventParticipant, onDismiss }) => {
  const [present, dismiss] = useIonLoading();
  const { event, updateParticipant } = useEventState();
  const { profile } = useAuthState();
  const [presentToast] = useIonToast();

  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: eventParticipant,
  });

  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();

      const res = await updateParticipant(eventParticipant.id, data);
      await dismiss();
      onDismiss();
    } catch (err: any) {
      await dismiss();
      presentToast(getErrorMessage(err.response.data.error), 2000);
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Büdzsé beállítása</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonNote>
                  A költségeid nyomonkövetéséhez itt megadhatod, hogy mennyi
                  pénz áll rendelkezésre az esemény kívánságainak
                  teljesítéséhez.
                </IonNote>
                <IonList>
                  <IonItem>
                    <IonLabel>Tervezett büdzsé</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          className="ion-text-right"
                          {...field}
                          type="number"
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="planned_budget"
                    />
                    {errors.planned_budget?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.planned_budget?.message}
                      </div>
                    )}
                  </IonItem>

                  <IonItem>
                    <IonLabel>Pénznem</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonSelect
                          interface="alert"
                          {...field}
                          onIonChange={field.onChange}
                          placeholder="Válassz egyet"
                        >
                          {CURRENCIES.map((k: TCurrency) => (
                            <IonSelectOption key={k.code} value={k.code}>
                              {k.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                      control={control}
                      name="planned_budget_currency"
                    />
                    {errors.planned_budget_currency?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.planned_budget_currency?.message}
                      </div>
                    )}
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol className="ion-text-end">
                <IonButton type="submit" shape="round" color="primary">
                  Mentés
                  <IonIcon icon={checkmarkSharp} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export default BudgetModal;

export enum TwoFaMethod {
  "NO",
  "PHONE",
  "EMAIL",
}

export const TwoFaMethodMap: { [key: string]: string } = {
  NO: "Nincs",
  // PHONE: "Kód küldése telefonra",
  EMAIL: "kód küldése emailben",
};

export enum AuthenticationMethod {
  "EMAIL",
  "PASSWORD",
}

export const AuthenticationMethodMap: { [key: string]: string } = {
  EMAIL: "Jelszómentes bejelentkezés",
  PASSWORD: "Jelszavas bejelentkezés",
};

export type TUser = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  validated_on?: string;
  is_deleted?: boolean;
  deleted_on?: string;
  deleted_why?: string;
  banned_on?: string;
  banned_why?: string;
  data?: any;
  invited_by?: string;
  is_invited?: boolean;
  authentication_method?: AuthenticationMethod;
  twofa_method?: TwoFaMethod;
  twofa_phone?: string;
  created_at?: string;
  updated_at?: string;
  pin?: string;
  discount_rate?: number;
  comission_rate?: number;
  is_admin?: boolean;
  email_notifications_allowed?: boolean;
  email_notifications_schedule?: string;
};

export type TLoginAnswer = {
  token?: string;
  error?: string;
  message?: string;
  authentication_method?: string;
};

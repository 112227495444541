import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonModal,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  useIonLoading,
} from "@ionic/react";

import { useAuthState } from "../../components/AuthProvider";
import { RefresherEventDetail } from "@ionic/core";
import HeaderComponent from "../../components/HeaderComponent";
import { EditProfilePage } from "../settings/EditProfilePage";
import PendingInvitations from "./PendingInvitations";
import { useEventState } from "../../components/EventProvider";
import moment from "moment";
import { TEvent } from "../../types/event.type";
import EventComponent from "../event/EventComponent";
import { addSharp } from "ionicons/icons";
import { ForgottenPasswordComponent } from "../settings/ForgottenPasswordComponent";

const HomePage: React.FC = () => {
  const { user, profile, profiles, showForgottenPassword } = useAuthState();
  const { events, getCurrentEvents } = useEventState();
  useEventState();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [editProfileId, setEditProfileId] = useState<string>("");

  useEffect(() => {
    if (showForgottenPassword) {
      setShowPasswordModal(true);
    } else if (profiles.length == 0) {
      setShowEditModal(true);
      setEditProfileId("new");
    } else {
      setShowEditModal(false);
    }
  }, [showForgottenPassword, profiles]);
  /**
   * Close modal
   */
  const handleDismiss = () => {
    setShowEditModal(false);
  };

  const loadRecord = async (e?: CustomEvent<RefresherEventDetail>) => {
    try {
      await getCurrentEvents();
    } catch (err) {
      console.log(err);
    } finally {
      if (e) {
        e.detail.complete();
      }
    }
  };
  useEffect(() => {
    loadRecord();
  }, []);

  const getCurrentEventList = () => {
    console.log({ events });
    if (events && events.length > 0) {
      const currentEvents = events
        .sort((a: TEvent, b: TEvent) => a?.take_place_on! > b?.take_place_on!)
        .filter((e: TEvent) => {
          console.log(e.open_on);
          return e?.open_on! <= moment().format("YYYY-MM-DD");
        });

      if (currentEvents.length > 0) {
        return (
          <>
            <h3>Közelgő esemény(ek):</h3>
            <IonRow className="ion-justify-content-start">
              {currentEvents.map((e: TEvent) => (
                <IonCol
                  key={e.id}
                  size="12"
                  size-md="10"
                  size-lg="6"
                  size-xl="6"
                >
                  <EventComponent event={e} />
                </IonCol>
              ))}
            </IonRow>
            <hr />
          </>
        );
      } else {
        return null;
      }
    }
  };
  return (
    <>
      <IonModal
        isOpen={showEditModal}
        backdropDismiss={true}
        children={
          <EditProfilePage
            editProfileId={editProfileId}
            onDismiss={handleDismiss}
          />
        }
      />

      <IonModal
        isOpen={showPasswordModal}
        backdropDismiss={true}
        children={
          <ForgottenPasswordComponent
            onDismiss={() => setShowPasswordModal(false)}
          />
        }
      />
      {!profile && (
        <IonPage>
          <HeaderComponent title="Helló" />
          <IonContent>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
                <IonRow>
                  <IonCol
                    className="ion-text-center"
                    style={{ margin: "25px 0 100px 0" }}
                  >
                    <h2>Üdv!</h2>
                    <br />
                    <br />
                    <p>Kezdésként hozz létre egy profilt!</p>
                    <IonButton
                      shape="round"
                      onClick={() => setShowEditModal(true)}
                      color="success"
                    >
                      Új Profil
                      <IonIcon icon={addSharp} slot="start" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonPage>
      )}
      {profile && (
        <IonPage>
          <HeaderComponent title="Helló" />
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={loadRecord}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
                {profile && <h2>Üdv, {profile.name}!</h2>}
                <PendingInvitations />
                {getCurrentEventList()}
                <IonRow>
                  <IonCol
                    className="ion-text-center"
                    style={{ margin: "25px 0 100px 0" }}
                  >
                    {!profile.is_limited && (
                      <>
                        <div className="ion-text-left">
                          <h2 text-align="left">Karácsony, húsvét, esküvő?</h2>
                          <p>
                            Hozd létre eseményt, hívd meg ismerőseidet és
                            könnyítsd meg az ajándékozást!{" "}
                          </p>
                        </div>
                        <IonButton
                          shape="round"
                          routerLink="/new-event"
                          color="success"
                        >
                          Új Esemény
                          <IonIcon icon={addSharp} slot="start" />
                        </IonButton>
                      </>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default HomePage;

import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonAlert,
  useIonToast,
} from "@ionic/react";
import { checkmarkSharp, closeOutline, closeSharp, stop } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventState } from "../../components/EventProvider";
import { STYLES, TStyle } from "../../types/style";
import EditEventModal from "./EditEventModal";
import EditTemplateModal from "./EditTemplateModal";
import EventLog from "./EventLog";
import PlanUpgradeModal from "./PlanUpgradeModal";
import { ENV } from "../../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(ENV.STRIPE_PK, { locale: "hu" });

interface Props {}

const EventSetting: React.FC = (props: Props) => {
  const history = useHistory();
  const { event, settings, plans, deleteEvent } = useEventState();
  const [showLogModal, setShowLogModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] =
    useState<boolean>(false);

  const [presentToast, dismissToast] = useIonToast();

  const handleDismiss = () => {
    setShowEditModal(false);
    setShowTemplateModal(false);
    setShowLogModal(false);
    setShowPlanUpgradeModal(false);
  };
  const style: TStyle = STYLES[event.template_id];

  const handleDelete = async () => {
    try {
      await deleteEvent(event.id);
      presentToast("Esemény törölve!", 5000);
      setTimeout(() => history.replace("/events"), 300);
    } catch (error) {
      console.log(error);
    }
  };

  const plan = plans.find((p: any) => p.id === event.plan_id);
  return (
    <>
      <IonModal
        isOpen={showLogModal}
        backdropDismiss={false}
        children={<EventLog onDismiss={handleDismiss} />}
      />
      <IonModal
        isOpen={showEditModal}
        backdropDismiss={false}
        children={<EditEventModal onDismiss={handleDismiss} />}
      />

      <IonModal
        isOpen={showPlanUpgradeModal}
        backdropDismiss={false}
        children={
          <Elements stripe={stripePromise}>
            <PlanUpgradeModal onDismiss={handleDismiss} />
          </Elements>
        }
      />
      <IonModal
        isOpen={showTemplateModal}
        backdropDismiss={false}
        children={<EditTemplateModal onDismiss={handleDismiss} />}
      />

      <IonAlert
        isOpen={showDeleteConfirm}
        onDidDismiss={() => setShowDeleteConfirm(false)}
        header="Esemény törlése"
        message={
          "Biztos, hogy törölni szeretnéd? A művelet nem visszavonható!" +
          (event.price ? " A befizetett díj nem kerül visszatérítésre!" : "")
        }
        buttons={[
          "Mégsem",
          {
            text: "Igen, töröljük!",
            handler: (d) => {
              handleDelete();
            },
          },
        ]}
      />
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="8" size-lg="6" size-xl="4">
            <IonList>
              <IonItem
                button
                onClick={() => setShowLogModal(true)}
                lines="full"
              >
                <IonLabel>Tevékenységi napló</IonLabel>
              </IonItem>
            </IonList>

            {event.myProfile.is_admin && (
              <>
                <IonList lines="full">
                  {!event.is_past && (
                    <IonItem button onClick={() => setShowEditModal(true)}>
                      <IonLabel> Esemény módosítása</IonLabel>
                    </IonItem>
                  )}

                  {!event.is_past && (
                    <IonItem
                      button
                      onClick={() => setShowTemplateModal(true)}
                      lines="full"
                    >
                      <IonLabel> Sablon módosítása</IonLabel>
                      <IonIcon
                        style={{ color: style.color }}
                        icon={stop}
                        slot="end"
                      />
                    </IonItem>
                  )}
                  <IonItem lines="full">
                    <IonLabel>Csomag: </IonLabel>
                    <IonLabel slot="end">{plan.name}</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Max résztvevő: </IonLabel>
                    <IonLabel slot="end">{event.max_participants} fő</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Max kivánság/fő: </IonLabel>
                    <IonLabel slot="end">{event.max_wishes} kívánság</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Kommentek: </IonLabel>
                    <IonLabel slot="end">
                      {event.comments_allowed ? (
                        <IonIcon icon={checkmarkSharp} color="success" />
                      ) : (
                        <IonIcon icon={closeSharp} color="danger" />
                      )}
                    </IonLabel>
                  </IonItem>
                  {false && (
                    <IonItem
                      button
                      color="success"
                      lines="full"
                      onClick={() => setShowPlanUpgradeModal(true)}
                    >
                      <IonLabel>Váltás nagyobb csomagra</IonLabel>
                    </IonItem>
                  )}
                </IonList>

                <div className="ion-text-center ion-padding ">
                  <IonButton
                    shape="round"
                    color="danger"
                    expand="block"
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    Esemény törlése
                    <IonIcon icon={closeOutline} slot="end" />
                  </IonButton>
                </div>
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default EventSetting;

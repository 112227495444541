import {
  IonContent,
  IonButton,
  IonCol,
  IonIcon,
  IonModal,
  IonRow,
  IonList,
  useIonLoading,
  useIonToast,
  IonAlert,
  IonPage,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  checkmarkOutline,
  chevronBack,
  createOutline,
  trashOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useWishState } from "../../components/WishProvider";
import { TWish, TWishReceive } from "../../types/wish.type";
import { EditWishComponent } from "./EditWishComponent";
import { WishComponent } from "./WishComponent";
import "./style.scss";
import { DetailWishComponent } from "./DetailWishComponent";
import HeaderComponent from "../../components/HeaderComponent";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { TEventWish } from "../../types/event.type";
import ReceiveWishComponent from "./ReceiveWishComponent";

type TWishPageParam = { wish_id: string };

interface Props {}

const WishPage: React.FC = (props: Props) => {
  const history = useHistory();
  let { path, url } = useRouteMatch();
  console.log({ history }, url, path);
  const { wish_id } = useParams<TWishPageParam>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showReceiveConfirm, setShowReceiveConfirm] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showReceiveModal, setShowReceiveModal] = useState<boolean>(false);
  const [currentWish, setCurrentWish] = useState<TWish | null>(null);
  const [editWish, setEditWish] = useState<TWish | null>();
  const [editWishReceive, setEditWishReceive] = useState<TWishReceive | null>();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const {
    wishes,
    wishCount,
    deleteWish,
    getWishes,
    getWish,
    deleteWishReceive,
  } = useWishState();
  const loadWish = () => {
    getWish(wish_id).then((res: any) => setCurrentWish(res.data));
  };
  useEffect(() => {
    loadWish();
  }, [wish_id]);

  const handleDismiss = async () => {
    setShowEditModal(false);
    setShowReceiveModal(false);
    loadWish();
  };

  const handleEdit = (wish: TWish) => {
    setEditWish(wish);
    setShowEditModal(true);
  };

  const handleReceive = (wishReceive: TWishReceive) => {
    setEditWishReceive(wishReceive);
    setShowReceiveModal(true);
  };

  const handleDeleteConfirm = () => {
    setShowConfirm(true);
  };

  const handleDeleteReceive = async (wishReceive: TWishReceive) => {
    setEditWishReceive(wishReceive);
    setShowReceiveConfirm(true);
  };

  const handleDelete = async () => {
    try {
      await present();
      await deleteWish(currentWish?.id);
      setCurrentWish(null);
      setShowConfirm(false);
      await getWishes();
      await dismiss();
      presentToast("Törlés rendben!", 2000);
      history.goBack();
    } catch (e) {
      await dismiss();
      console.log(e);
    }
  };

  return (
    <IonPage>
      <HeaderComponent title="Kívánságok" />
      <IonContent fullscreen={true}>
        <IonButton fill="clear" size="small" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBack} slot="start" />
          Vissza
        </IonButton>

        <IonAlert
          isOpen={showConfirm}
          onDidDismiss={() => setShowConfirm(false)}
          header="Kívánság törlése"
          message="Biztos, hogy törölni szerentéd ezt a kívánságot? A műveletet nem lehet visszavonni!"
          buttons={[
            "Mégsem",
            {
              text: "Igen, törlöm.",
              handler: (d) => handleDelete(),
            },
          ]}
        />
        <IonAlert
          isOpen={showReceiveConfirm}
          onDidDismiss={() => setShowReceiveConfirm(false)}
          header="Ajándékozás törlése"
          message="Biztos, hogy törölni szerentéd, hogy megkaptad ezt? A műveletet nem lehet visszavonni!"
          buttons={[
            "Mégsem",
            {
              text: "Igen, törlöm.",
              handler: async (d) => {
                try {
                  await deleteWishReceive(
                    editWishReceive?.wish_id,
                    editWishReceive?.id!
                  );
                  presentToast("Törlés rendben!", 2000);
                  loadWish();
                } catch (err: any) {}
              },
            },
          ]}
        />
        <IonModal
          isOpen={showEditModal}
          backdropDismiss={false}
          children={
            <EditWishComponent wish={currentWish} onDismiss={handleDismiss} />
          }
        />

        <IonModal
          isOpen={showReceiveModal}
          backdropDismiss={false}
          children={
            <ReceiveWishComponent
              wishReceive={editWishReceive!}
              onDismiss={handleDismiss}
            />
          }
        />
        <IonRow className="ion-justify-content-center">
          <IonCol
            size="12"
            size-md="6"
            size-lg="6"
            size-xl="6"
            className="ion-text-center"
          >
            {currentWish && (
              <DetailWishComponent
                wish={currentWish}
                receives={true}
                editHandler={handleReceive}
                deleteHandler={handleDeleteReceive}
              >
                <>
                  {!!currentWish.EventWish &&
                    !!currentWish.EventWish.length && (
                      <div className="ion-text-start">
                        {currentWish.EventWish.length == 1 ? (
                          <h6>Esemény, melynél látható:</h6>
                        ) : (
                          <h6>Események,melynél látható:</h6>
                        )}
                        <IonList style={{ marginBottom: "20px" }}>
                          {currentWish.EventWish.map((e: TEventWish) => (
                            <IonItem
                              lines="full"
                              routerLink={`/event/${e.event_id}`}
                              key={e.id}
                            >
                              {e.Event?.name}
                            </IonItem>
                          ))}
                        </IonList>
                      </div>
                    )}
                  {!currentWish.EventWish?.length && (
                    <h4>Ez a kívánság egy eseményhez sincs hozzárendelve!</h4>
                  )}
                  <IonList>
                    <IonItem
                      lines="full"
                      detail={false}
                      button
                      color="success"
                      onClick={() =>
                        handleReceive({
                          wish_id: currentWish.id,
                          event: currentWish?.EventWish?.length
                            ? currentWish.EventWish[0].Event?.name
                            : "",
                        })
                      }
                    >
                      <IonLabel>Megkaptam</IonLabel>
                      <IonIcon icon={checkmarkOutline} slot="end" />
                    </IonItem>
                    <IonItem
                      lines="full"
                      detail={false}
                      button
                      color="warning"
                      onClick={() => handleEdit(currentWish)}
                    >
                      <IonLabel>Módosítás</IonLabel>
                      <IonIcon icon={createOutline} slot="end" />
                    </IonItem>
                    <IonItem
                      lines="full"
                      detail={false}
                      button
                      color="danger"
                      onClick={() => handleDeleteConfirm()}
                    >
                      <IonLabel>Törlés</IonLabel>
                      <IonIcon icon={trashOutline} slot="end" />
                    </IonItem>
                    <IonItem
                      lines="full"
                      detail={false}
                      button
                      color="light"
                      onClick={() => history.goBack()}
                    >
                      <IonLabel>Vissza</IonLabel>
                      <IonIcon icon={chevronBack} slot="end" />
                    </IonItem>
                  </IonList>
                </>
              </DetailWishComponent>
            )}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default WishPage;

export const ERROR_MESSAGES: { [key: string]: string } = {
  DATE_MUST_BE_BEFORE: "Az esemény nem lehet később, mint PARAM",
  EVENT_EXISTS: "Ez az esemény már létezik!",
  THIS_EMAIL_ALREADY_IN_USE: "Ez az email cím már regisztrált!",
  WEAK_PASSWORD: "Túl gyenge jelszó!",
  MIN_CHARACTERS: "Legalább PARAM karakter szükséges",
  MAX_CHARACTERS: "Maximum PARAM karakter lehetséges",
  FIELD_REQUIRED: "Kérlek, töltsd ki ezt a mezőt!",
  INVALID_EMAIL_FORMAT: "Nem megfelelő email cím formátum!",
  INVALID_PHONE_NUMBER: "Ez a telefonszám nem létezik!",
  PROFILE_EXISTS: "Ilyen profilod már van!",
  INCORRECT_PASSWORD: "Hibás jelszó!",
  NEW_PASSWORD_MUST_BE_DIFFERENT_THAN_OLD_ONE:
    "Az új jelszó nem lehet ugyanaz, mint a régi!",
  BILLING_ACCOUNT_EXISTS: "Ez a számlázási cím már létezik!",
  USER_ALREADY_INVITED: "Ő már résztvevője az eseménynek!",
  MAX_PARTICIPANT_REACHED: "A résztvevők száma elérte a maximális létszámot!",
  NOT_ALLOWED: "A művelet nem engedélyezett!",
  PROFILE_ALREADY_ADDED: "A profil már hozzá van adva az eseményhez!",
  UNKNOWN_PROFILE: "Ismeretlen profil!",
  ENGAGE_NOT_POSSIBLE: "Sajnos ezt a kívánságot nem tudtad lefoglalni!",
  WISH_ALREADY_ENGAGED_BY_YOU: "Ezt a kívánságot már lefoglaltad!",
  WISH_EXISTS: "Ezt a kívánságodat már feltöltötted!",
};

export const getErrorMessage = (ERROR_CODE: string): string => {
  const [ERROR_C, PARAM] = ERROR_CODE.split(":");
  if (ERROR_MESSAGES.hasOwnProperty(ERROR_C)) {
    let m = ERROR_MESSAGES[ERROR_C];
    if (PARAM) {
      m = m.replace("PARAM", PARAM);
    }
    return m;
  } else {
    return ERROR_CODE;
  }
};

import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import {
  TEventParticipant,
  TEventWish,
  TEventWishEngagement,
} from "../../types/event.type";
import ProfileLink from "./ProfileLink";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import {
  alertCircleOutline,
  arrowUpOutline,
  cartOutline,
  checkmarkOutline,
  chevronBack,
  closeOutline,
  giftOutline,
} from "ionicons/icons";
import ImageViewer from "../../components/ImageViewer";
import { useAuthState } from "../../components/AuthProvider";
import moment from "moment";
import PurchaseModal from "./PurchaseModal";
import { getErrorMessage } from "../../types/errorMessages";
import EventWishComment from "./EventWishComment";
import { EditWishComponent } from "../wish/EditWishComponent";
import { QuantityList, TWish } from "../../types/wish.type";

type TEventWishPageParam = { wish_id: string };
type Props = RouteComponentProps & TEventWishPageParam;

const EventWishPage: React.FC<Props> = ({ history }) => {
  const { wish_id } = useParams<TEventWishPageParam>();
  const { event, getWish, engageWish, disengageWish, updateEngagement } =
    useEventState();
  const [showImg, setShowImg] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const { profile } = useAuthState();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const [eventWish, setEventWish] = useState<TEventWish | null>(null);

  const [showEngageConfirm, setShowEngageConfirm] = useState<boolean>(false);
  const [showDisengageConfirm, setShowDisengageConfirm] =
    useState<boolean>(false);
  const [showDispurchaseConfirm, setShowDispurchaseConfirm] =
    useState<boolean>(false);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const myEventWishEngagement = eventWish?.Engagements?.find(
    (e: TEventWishEngagement) => e.engaged_by == profile.id
  );

  const getEngagements = () => {
    if (eventWish?.Engagements && eventWish.Engagements.length) {
      const { Engagements } = eventWish;
      const isPast = event.is_past;
      return Engagements.map((e: TEventWishEngagement, i: number) => {
        if (e.event_id === event.id) {
          const engagedParticipant = event.participants.find(
            (p: TEventParticipant) => p.profile_id == e?.engaged_by
          );
          // saját eseményben lefoglaltál
          if (e.engaged_by === profile.id) {
            // saját foglalás
            if (e.is_purchased) {
              return (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <div
                    style={{ color: "var(--ion-color-danger)" }}
                    className="flexGrowOne"
                  >
                    Beszereztem {moment(e?.purchased_on).format("MMMM D")}-n
                  </div>
                  {!isPast && (
                    <IonButton
                      className="flexGrowZero"
                      color="danger"
                      size="small"
                      fill="clear"
                      onClick={() => setShowDispurchaseConfirm(true)}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  )}
                </div>
              );
            } else {
              return (
                <div key={i}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        color: "var(--ion-color-warning)",
                        fontWeight: 600,
                      }}
                      className="flexGrowOne"
                    >
                      Lefoglaltam {moment(e?.engaged_on).format("MMMM D")}-n
                    </span>
                    {!isPast && (
                      <IonButton
                        className="flexGrowZero"
                        color="danger"
                        size="small"
                        fill="clear"
                        onClick={() => setShowDisengageConfirm(true)}
                      >
                        <IonIcon icon={closeOutline} />
                      </IonButton>
                    )}
                  </div>
                  <div className="ion-text-center ion-padding-top">
                    {!isPast && (
                      <IonButton
                        onClick={() => setShowPurchaseModal(true)}
                        color="primary"
                      >
                        Beszerzés rendben
                        <IonIcon icon={checkmarkOutline} />
                      </IonButton>
                    )}
                  </div>
                </div>
              );
            }
          } else {
            //ha más foglalta le
            if (e.is_purchased) {
              return (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <ProfileLink ep={engagedParticipant} />
                  <span style={{ color: "var(--ion-color-danger)" }}>
                    beszerezte
                  </span>
                </div>
              );
            } else {
              return (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <ProfileLink ep={engagedParticipant} />{" "}
                  <span style={{ color: "var(--ion-color-warning)" }}>
                    lefoglalta {moment(e?.engaged_on).format("MMMM D")}-n
                  </span>
                </div>
              );
            }
          }
        } else {
          if (e.engaged_by === profile.id) {
            return (
              <IonItem key={i}>
                <IonLabel className="ion-text-wrap">
                  Egy másik eseménynél már{" "}
                  {!e.is_purchased ? "lefoglaltad" : "beszerezted"}!
                </IonLabel>
                <IonIcon slot="start" icon={alertCircleOutline} />
              </IonItem>
            );
          } else {
            //más eseményben lefoglalták
            if (e.is_purchased) {
              return (
                <IonItem key={i}>
                  <IonLabel>Valaki már beszerezte.</IonLabel>
                  <IonIcon slot="start" icon={alertCircleOutline} />
                </IonItem>
              );
            } else {
              return (
                <IonItem key={i}>
                  <IonLabel>Valaki már lefoglalta.</IonLabel>
                  <IonIcon slot="start" icon={alertCircleOutline} />
                </IonItem>
              );
            }
          }
        }
      });
    }
  };

  const getEngagementButton = () => {
    if (event.is_past) return null;
    const wish = eventWish?.Wish;
    let label = "Lefoglalom teljesítésre!";
    let title = "";
    let show = true;
    if (eventWish?.Engagements && eventWish.Engagements.length) {
      const eventParticipant = event.participants.find(
        (p: TEventParticipant) => p.profile_id == wish?.created_by
      );

      label = "Én is teljesítem";

      title =
        eventWish.Engagements.length > 1
          ? `Bár a kívánságot már többen teljesítik, `
          : `Bár a kívánságot már teljesíti valaki, `;
      title += ` ${eventParticipant.profile.name} többet is szeretne ebből a kívánságából. Te is akarod teljesíteni?`;
      const myEngagement = eventWish.Engagements.find(
        (e: TEventWishEngagement) => e.engaged_by === profile.id
      );
      if (myEngagement) show = false;
      else {
        if (wish?.qty !== 0) {
          show = false;
        }
      }
    }
    if (show) {
      return (
        <div className="ion-text-center">
          <div className="ion-padding ion-text-left">{title}</div>
          <IonButton color="success" onClick={() => setShowEngageConfirm(true)}>
            {label}
          </IonButton>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleDispurchase = async () => {
    try {
      await present();
      const res = await updateEngagement(myEventWishEngagement?.id, {
        purchased: false,
      });
    } catch (err: any) {
      console.log(err.response);
      presentToast({
        message: getErrorMessage(err.response.data.error),
        duration: 3000,
        color: "danger",
      });
    }
    await dismiss();
    await loadEventWish();
  };

  const handleEngage = async (engage: boolean) => {
    try {
      await present();
      let res;
      if (engage) {
        res = await engageWish(eventWish?.wish_id);
      } else {
        res = await disengageWish(myEventWishEngagement?.id);
      }

      if (res.data.is_engaged) {
        presentToast(
          "Rendben, sikeresen lefoglaltad a kívánságot. Sikeres beszerzést!",
          3000
        );
      } else {
        presentToast("Rendben, levettük a foglalást!", 3000);
      }
      await loadEventWish();
    } catch (err: any) {
      console.log(err.response);
      presentToast({
        message: getErrorMessage(err.response.data.error),
        duration: 3000,
        color: "danger",
      });
      await loadEventWish();
    }
    await dismiss();
  };

  const loadEventWish = async () => {
    try {
      const res = await getWish(wish_id);
      setEventWish(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (wish_id: string) => {
    setShowEditModal(true);
  };

  const handleEditDismiss = () => {
    setShowEditModal(false);
    loadEventWish();
  };

  useEffect(() => {
    loadEventWish();
  }, [wish_id]);

  if (eventWish) {
    const wish = eventWish?.Wish;
    const eventParticipant = event.participants.find(
      (p: TEventParticipant) => p.profile_id == wish?.created_by
    );
    const { myProfile } = event;
    const showStatus =
      !profile.is_limited &&
      myProfile.can_engage_wish &&
      eventParticipant.profile_id !== profile.id;
    const showEditBtn =
      !event.is_past && eventParticipant.profile_id === profile.id;
    const myEngagement = eventWish?.Engagements?.find(
      (e: TEventWishEngagement) => e.engaged_by == profile.id
    );
    return (
      <>
        <IonModal
          isOpen={showEditModal}
          backdropDismiss={false}
          children={
            <EditWishComponent
              wish={eventWish?.Wish || ({ id: "" } as TWish)}
              onDismiss={handleEditDismiss}
            />
          }
        />
        <IonModal
          isOpen={showImg}
          onDidDismiss={() => setShowImg(false)}
          children={
            <ImageViewer
              onDismiss={() => setShowImg(false)}
              imageUrl={ENV.CDN_URL + wish?.image_lg}
            />
          }
        />
        {myEngagement && (
          <IonModal
            isOpen={showPurchaseModal}
            onDidDismiss={() => setShowPurchaseModal(false)}
            children={
              <PurchaseModal
                onDismiss={() => {
                  setShowPurchaseModal(false);
                  loadEventWish();
                }}
                eventWishEngagement={myEngagement}
              />
            }
          />
        )}

        <IonAlert
          isOpen={showEngageConfirm}
          onDidDismiss={() => setShowEngageConfirm(false)}
          header="Kivánság teljesítésének lefoglalása"
          message={`Biztos, hogy szeretnéd teljesíteni ${eventParticipant.profile.name} kívánságát?`}
          buttons={[
            "Bocs, véletlen kattintottam",
            {
              text: "Igen",
              handler: (d) => handleEngage(true),
            },
          ]}
        />

        <IonAlert
          isOpen={showDisengageConfirm}
          onDidDismiss={() => setShowDisengageConfirm(false)}
          header="Foglalás törlése"
          message={`Biztos, hogy törölni szeretnéd a foglalást, így más teljesítheti ${eventParticipant.profile.name} kívánságát?`}
          buttons={[
            "Bocs, véletlen kattintottam",
            {
              text: "Igen, töröljük",
              handler: (d) => handleEngage(false),
            },
          ]}
        />

        <IonAlert
          isOpen={showDispurchaseConfirm}
          onDidDismiss={() => setShowDispurchaseConfirm(false)}
          header="Beszerzés törlése"
          message={`Biztos, hogy nem tudtad beszerezni?`}
          buttons={[
            "Bocs, véletlen kattintottam",
            {
              text: "Igen, töröljük",
              handler: (d) => handleDispurchase(),
            },
          ]}
        />

        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
              <h3 className="ion-padding">{wish?.name}</h3>
              <IonButton
                fill="clear"
                size="small"
                onClick={() => history.goBack()}
              >
                <IonIcon icon={chevronBack} slot="start" />
                Vissza
              </IonButton>
              <IonRow>
                <IonCol size-xs="12" size-md="6" size-lg="6" size-xl="6">
                  <IonImg
                    src={ENV.CDN_URL + wish?.image_lg!}
                    onClick={() => setShowImg(true)}
                  />
                </IonCol>
                <IonCol size="12" size-md="6" size-lg="6" size-xl="6">
                  {eventParticipant.profile_id === profile.id && (
                    <>Saját kívánságom:</>
                  )}
                  {eventParticipant.profile_id !== profile.id && (
                    <>
                      <ProfileLink ep={eventParticipant} /> kívánsága:
                    </>
                  )}

                  <div className="wishDescription">
                    {wish?.description}
                    <br />
                    <br />
                    <hr />
                    {QuantityList[wish?.qty!]}
                  </div>
                  <div className="ion-margin-top">
                    {wish?.url?.startsWith("https://") ||
                    wish?.url?.startsWith("http://") ? (
                      <>
                        Webhely:{" "}
                        <a
                          className="wishUrl"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            InAppBrowser.create(wish.url!, "_blank");
                            return false;
                          }}
                        >
                          {wish.url}
                        </a>
                      </>
                    ) : (
                      <>
                        Lelőhely: <span className="wishUrl">{wish?.url}</span>
                      </>
                    )}
                  </div>
                  <hr />
                  {showStatus && (
                    <>
                      <IonList lines="full">{getEngagements()}</IonList>

                      {getEngagementButton()}
                    </>
                  )}
                  {showEditBtn && (
                    <IonButton
                      size="small"
                      color="warning"
                      onClick={() => setShowEditModal(true)}
                    >
                      Módosítás
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
              <EventWishComment eventWish={eventWish} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ marginBottom: "50px" }}></div>
      </>
    );
  } else {
    return <></>;
  }
};

export default withRouter(EventWishPage);

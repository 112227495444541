import React, { useEffect, useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import { getErrorMessage } from "../../types/errorMessages";
import { TwoFaMethodMap, AuthenticationMethodMap } from "../../types/user.type";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";

interface Props {
  onDismiss(): void;
}

type IFormInputs = {
  email: string;
  first_name: string;
  last_name: string;
  authentication_method: string;
  twofa_method: string;
  twofa_phone: string;
  [key: string]: any;
};

const schema = yup
  .object({
    email: yup
      .string()
      .email("Nem megfelelő email cím formátum!")
      .required("Kérlek, add meg az email címed!"),
    first_name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    last_name: yup
      .string()
      .min(2, "Legalább 2 betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
  })
  .required();

export const EditAccountComponent: React.FC<Props> = ({ onDismiss }) => {
  const { user, update, saveJWT, loadCurrentUser, getProfiles } =
    useAuthState();
  const [present, dismiss] = useIonLoading();
  const [isError, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageHeader, setmessageHeader] = useState<string>("");
  const [presentToast] = useIonToast();
  let {
    control,
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: user,
  });
  const authenticationMethod = watch("authentication_method");
  const twofaMethod = watch("twofa_method");
  const onSubmit = async (data: IFormInputs) => {
    if (Object.keys(errors).length) {
      return;
    }
    try {
      await present();
      const result = await update(data);
      await dismiss(); 
      if (result.status == 200) {
        await saveJWT(result.data?.token);
        await loadCurrentUser();
        await getProfiles();
        presentToast("Adatmentés rendben", 2000);
        onDismiss();
      }
      if (result.data?.error) {
        console.log(result.data?.error);
      }
    } catch (err: any) {
      await dismiss(); 
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "USER_EXISTS":
            message = "Ez az email cím már regisztrálva van!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setmessageHeader("Oppsz!");
        setMessage(message as string);
        setIserror(true);
      }
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Fiókbeállítások</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">E-mail cím</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="email"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="email"
                    />
                    {errors.email?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.email?.message}
                      </div>
                    )}
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Vezetéknév</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput {...field} onIonChange={field.onChange} />
                      )}
                      control={control}
                      name="last_name"
                    />
                    {errors.last_name?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.last_name?.message}
                      </div>
                    )}
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Keresztnév</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput {...field} onIonChange={field.onChange} />
                      )}
                      control={control}
                      name="first_name"
                    />
                    {errors.first_name?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.first_name?.message}
                      </div>
                    )}
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">Bejelentkezés módja</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonSelect
                          okText="Rendben"
                          cancelText="Mégsem"
                          {...field}
                          onIonChange={field.onChange}
                          placeholder="Válassz egyet"
                        >
                          {Object.keys(AuthenticationMethodMap).map(
                            (k: string) => (
                              <IonSelectOption key={k} value={k}>
                                {AuthenticationMethodMap[k]}
                              </IonSelectOption>
                            )
                          )}
                        </IonSelect>
                      )}
                      control={control}
                      name="authentication_method"
                    />
                  </IonItem>
                  {authenticationMethod == "PASSWORD" && (
                    <>
                      <IonItem>
                        <IonLabel position="stacked">
                          Kétlépéses bejelentkezés
                        </IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonSelect
                              okText="Rendben"
                              cancelText="Mégsem"
                              {...field}
                              onIonChange={field.onChange}
                              placeholder="Válassz egyet"
                            >
                              {Object.keys(TwoFaMethodMap).map((k: string) => (
                                <IonSelectOption key={k} value={k}>
                                  {TwoFaMethodMap[k]}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                          control={control}
                          name="twofa_method"
                        />
                      </IonItem>
                      {twofaMethod == "PHONE" && (
                        <IonItem>
                          <IonLabel position="stacked">Telefonszám</IonLabel>
                          <Controller
                            render={({ field }) => (
                              <IonInput
                                {...field}
                                placeholder="pl.: +36301234567"
                                onIonChange={field.onChange}
                              />
                            )}
                            control={control}
                            name="twofa_phone"
                          />
                          {errors.twofa_phone?.message && (
                            <div className="ion-text-start errorMessage">
                              {errors.twofa_phone?.message}
                            </div>
                          )}
                        </IonItem>
                      )}
                    </>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-text-end">
                <IonButton type="submit" shape="round" color="primary">
                  Mentés
                  <IonIcon icon={checkmarkSharp} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export enum GENDERS {
  "male",
  "female",
  "not_specified",
}
export const GenderMap: { [key: string]: any } = {
  male: "Férfi",
  female: "Nő",
  //not_specified: "Egyéb",
};

export type TProfile = {
  id?: string;
  account_id?: string;
  name: string;
  date_of_birth?: string;
  age?: string;
  date_of_nameday?: string;
  gender?: string;
  image_xs?: string;
  image_lg?: string;
  is_limited?: boolean;
  created_at?: string;
  updated_at?: string;
};

import axios, { AxiosResponse } from "axios";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ENV } from "../config";
import {
  TEvent,
  TPlan,
  TEventSettings,
  TEventWishFilterParam,
  TEventWish,
  TEventWishComment,
  TEventParticipant,
  TEventWishEngagementFilterParam,
  TEventWishEngagement,
  TEventLog,
  TEventLogFilterParams,
} from "../types/event.type";
import { useAuthState } from "./AuthProvider";

type TEventParam = {
  name?: string;
  description?: string;
  location?: string;
  is_admin?: boolean;
  is_active?: boolean;
  before?: string;
  after?: string;
  open_after?: string;
  open_before?: string;
};

type TInvite = {
  profile_id?: string;
  invited_profile_id?: string;
  email?: string;
  can_post_wish: boolean;
  can_engage_wish: boolean;
  is_admin: boolean;
};

type TWishCommentParam = {
  order?: string;
  remark_type?: string;
  answer_to?: number;
};
interface IEventContext {
  events: Array<TEvent>;
  event: TEvent | null | undefined;
  settings: TEventSettings;
  plans: Array<TPlan>;
  getPlans(): Promise<any>;
  getSettings(): Promise<any>;
  getCurrentEvents(): Promise<any>;
  getEvents(params: TEventParam): Promise<any>;
  getEvent(event_id: string): Promise<TEvent>;
  getEventLog(event_id: string, params: TEventLogFilterParams): Promise<TEvent>;
  updateEvent(event_id: string, data: TEvent): Promise<any>;
  createEvent(data: TEvent): Promise<any>;
  deleteEvent(event_id: string): Promise<any>;
  invite(params: TInvite): Promise<any>;
  addProfile(params: TInvite): Promise<any>;
  updateParticipant(ep_id: string, params: TEventParticipant): Promise<any>;
  resendInvite(invitation_id: number): Promise<any>;
  getPendingInvitations(): Promise<any>;
  invitations: Array<TEventParticipant>;
  denyInvitation(participant_id: number): Promise<any>;
  acceptInvitation(event_id: string): Promise<any>;
  deleteParticipant(participant_id: number): Promise<any>;
  getWishes(params: TEventWishFilterParam): Promise<any>;
  getWish(event_wish_id: number): Promise<any>;
  delegateWish(wish_id: string): Promise<any>;
  deleteWish(event_wish_id: string): Promise<any>;
  updateWish(event_wish_id: string, params: TEventWish): Promise<any>;
  getEventWishEngagements(
    params: TEventWishEngagementFilterParam
  ): Promise<any>;
  getEventWishEngagement(event_wish_engagement_id: number): Promise<any>;
  engageWish(wish_id: string): Promise<any>;
  disengageWish(event_wish_engagement_id: number): Promise<any>;
  updateEngagement(
    event_wish_engagement_id: number,
    params: TEventWishEngagement
  ): Promise<any>;
  addComment(event_wish_id: string, params: TEventWishComment): Promise<any>;
  getComments(event_wish_id: string, params: TWishCommentParam): Promise<any>;
  upgrade(plan_id: number, billing_account_id: string): Promise<any>;
  initCheckout(): Promise<any>;
  checkoutStatus(session_id: string): Promise<any>;
}

const EventContext = createContext<IEventContext | any>(null);

export const useEventState = () => {
  const context = useContext(EventContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};

export const EventProvider: React.FC = ({ children }) => {
  const { jwt, user, profile } = useAuthState();
  const [events, setEvents] = useState<Array<TEvent>>([]);
  const [event, setEvent] = useState<TEvent | null>();
  const [settings, setSettings] = useState<TEventSettings>({});
  const [plans, setPlans] = useState<Array<TPlan>>([]);
  const [isSettingsLoaded, setIsSettingsLoaded] = useState<boolean>(false);
  const [isPlanLoaded, setIsPlanLoaded] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<Array<TEventParticipant>>([]);

  const header = { headers: { Authorization: `Bearer ${jwt}` } };

  const createEvent = (data: TEvent): Promise<any> => {
    return axios.post(ENV.API_URL + `/${profile.id}/event`, data, header);
  };

  const deleteEvent = (event_id: string): Promise<any> => {
    return axios.delete(
      ENV.API_URL + `/${profile.id}/event/${event_id}`,
      header
    );
  };

  const updateEvent = (event_id: string, data: TEvent): Promise<any> => {
    return axios.put(
      ENV.API_URL + `/${profile.id}/event/${event_id}`,
      data,
      header
    );
  };

  const getCurrentEvents = (): Promise<any> => {
    const params = { after: moment().format("YYYY-MM-DD") };
    return new Promise(async (resolve, reject) => {
      try {
        if (!jwt) return reject(false);
        let res;
        if (!profile) {
          res = {
            data: [],
          };
        } else {
          res = await axios.get<any>(ENV.API_URL + `/${profile.id}/event/`, {
            params: { ...params },
            ...header,
          });
        }
        setEvents(res.data.rows);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getEvents = (
    params: TEventParam = {},
    updateEvent = false
  ): Promise<Array<TEvent>> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!jwt) return reject(false);
        let res;
        if (!profile) {
          res = {
            data: [],
          };
        } else {
          res = await axios.get<Array<TEvent>>(
            ENV.API_URL + `/${profile.id}/event/`,
            { params: { ...params }, ...header }
          );
        }
        if (updateEvent) setEvents(res.data);
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getEvent = (event_id: string): Promise<TEvent> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<TEvent>(
          ENV.API_URL + `/${profile.id}/event/${event_id}`,
          header
        );
        setEvent(res?.data! || null);
        return resolve(res?.data!);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getEventLog = (
    event_id: string,
    filterParam: TEventLogFilterParams
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const p = filterParam?.page!;
        const params = { ...header, params: filterParam };
        const res = await axios.get<{ count: number; rows: Array<TEventLog> }>(
          ENV.API_URL + `/${profile.id}/event/${event_id}/logs`,
          params
        );
        return resolve(res?.data!);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getPlans = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<any>(ENV.API_URL + `/plan`, header);
        setPlans(res.data?.rows!);
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const getSettings = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<TEventSettings>(
          ENV.API_URL + `/settings`,
          header
        );
        setSettings(res.data);
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const invite = (params: TInvite): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/participant`,
      params,
      header
    );
  };

  const addProfile = (params: TInvite): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/add_profile`,
      params,
      header
    );
  };
  const updateParticipant = (
    ep_id: string,
    params: TEventParticipant
  ): Promise<any> => {
    return axios.put(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/participant/${ep_id}`,
      params,
      header
    );
  };
  const getPendingInvitations = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get<any>(
          ENV.API_URL + "/pendinginvitations",
          header
        );
        console.log(res);
        const d: TEventParticipant[] = res.data?.filter(
          (o: TEventParticipant) => !o.profile_id || o.profile_id === profile.id
        );
        setInvitations(d);
        return resolve(res.data);
      } catch (err) {
        return reject(err);
      }
    });
  };
  const acceptInvitation = (event_id: string): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event_id}/participant/accept`,
      {},
      header
    );
  };

  const denyInvitation = (participant_id: number): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/denyinvitation/${participant_id}`,
      {},
      header
    );
  };

  const resendInvite = (invitation_id: number): Promise<any> => {
    return axios.post(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/participant/${invitation_id}/resend`,
      {},
      header
    );
  };
  const deleteParticipant = (participant_id: number): Promise<any> => {
    return axios.delete(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/participant/${participant_id}`,
      header
    );
  };

  const getEventWishEngagement = (
    event_wish_engagement_id: number
  ): Promise<any> => {
    return axios.get(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/wish_engagement/${event_wish_engagement_id}`,
      header
    );
  };

  const getEventWishEngagements = (
    params: TEventWishEngagementFilterParam
  ): Promise<any> => {
    const p = { ...params, ipp: 100 };
    return axios.get(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish_engagement`,
      {
        params: p,
        ...header,
      }
    );
  };

  const engageWish = (wish_id: string): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish_engagement/`,
      { wish_id },
      header
    );
  };

  const disengageWish = (event_wish_engagement_id: number): Promise<any> => {
    return axios.delete(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/wish_engagement/${event_wish_engagement_id}`,
      header
    );
  };
  const updateEngagement = (
    event_wish_engagement_id: number,
    params: TEventWishEngagement
  ): Promise<any> => {
    return axios.put(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/wish_engagement/${event_wish_engagement_id}`,
      params,
      header
    );
  };

  const getWishes = (params: TEventWishFilterParam): Promise<any> => {
    const p = { ...params, ipp: 100 };
    return axios.get(ENV.API_URL + `/${profile.id}/event/${event?.id}/wish`, {
      params: p,
      ...header,
    });
  };
  const getWish = (event_wish_id: number): Promise<any> => {
    return axios.get(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish/${event_wish_id}`,
      header
    );
  };
  const delegateWish = (wish_id: string): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish`,
      { wish_id },
      header
    );
  };
  const deleteWish = (event_wish_id: string): Promise<any> => {
    return axios.delete(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish/${event_wish_id}`,
      header
    );
  };
  const updateWish = (
    event_wish_id: string,
    params: TEventWish
  ): Promise<any> => {
    return axios.put(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/wish/${event_wish_id}`,
      params,
      header
    );
  };

  const addComment = (
    event_wish_id: string,
    params: TEventWishComment
  ): Promise<any> => {
    return axios.post(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/wish/${event_wish_id}/comment`,
      params,
      header
    );
  };

  const getComments = (
    event_wish_id: string,
    params: TWishCommentParam
  ): Promise<any> => {
    return axios.get(
      ENV.API_URL +
        `/${profile.id}/event/${event?.id}/wish/${event_wish_id}/comment`,
      {
        params,
        ...header,
      }
    );
  };

  const upgrade = (
    plan_id: number,
    billing_account_id: string
  ): Promise<any> => {
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/upgrade/`,
      {
        plan_id,
        billing_account_id,
      },
      header
    );
  };

  const checkoutStatus = (session_id: string): Promise<any> => {
    console.log("event", { event });
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/checkout-status`,
      {
        session_id,
      },
      header
    );
  };

  const initCheckout = (): Promise<any> => {
    console.log("event", { event });
    return axios.post(
      ENV.API_URL + `/${profile.id}/event/${event?.id}/init-checkout/`,
      {},
      header
    );
  };

  useEffect(() => {
    if (profile && jwt) {
      getSettings().then(() => setIsSettingsLoaded(true));
      getPlans().then(() => setIsPlanLoaded(true));
      getCurrentEvents() //just the new event to load
        .then()
        .catch((err: any) => console.log(err));
    } else {
      setEvents([]);
      setEvent(null);
    }
  }, [profile]);
  const value: IEventContext = {
    events,
    event,
    settings,
    plans,
    getPlans,
    getSettings,
    updateEvent,
    createEvent,
    deleteEvent,
    invite,
    addProfile,
    getEvents,
    getCurrentEvents,
    getEvent,
    resendInvite,
    deleteParticipant,
    updateParticipant,
    invitations,
    getPendingInvitations,
    acceptInvitation,
    denyInvitation,
    getWishes,
    getWish,
    deleteWish,
    delegateWish,
    updateWish,
    addComment,
    getComments,
    getEventWishEngagement,
    getEventWishEngagements,
    engageWish,
    disengageWish,
    updateEngagement,
    getEventLog,
    upgrade,
    checkoutStatus,
    initCheckout,
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCol,
  IonIcon,
  IonRow,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from "@ionic/react";
import {
  arrowBackCircleSharp,
  arrowForwardCircleSharp,
  closeOutline,
} from "ionicons/icons";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

import { Controller, useForm } from "react-hook-form";
import countries from "../../types/countries.json";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventState } from "../../components/EventProvider";
import StepCounter from "./StepCounter";

import { monthNames } from "../../types/date";

export type IFormInputs = {
  name: string;
  template_id: string;
  plan_id: string;
  billing_account_id: string;
  take_place_on: string;
  take_place_on_date: string;
  take_place_on_time: string;
  open_on: string;
  description: string;
  location: string;
  address_1: string;
  address_2: string;
  showAddress: boolean;
  zip: string;
  city: string;
  country: string;
  [key: string]: any;
};
const schema = yup.object({
  name: yup
    .string()
    .min(2, "Legalább ${min} betű szükséges")
    .max(50, "Maximum ${max} betű lehetséges")
    .required("Kérlek, töltsd ki ezt a mezőt!"),
  location: yup
    .string()
    .max(50, "Maximum ${max} betű lehetséges")
    .required("Kérlek, töltsd ki ezt a mezőt!"),
  take_place_on_date: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
  take_place_on_time: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
  open_on: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
  address_1: yup.string().max(50, "Maximum ${max} betű lehetséges"),
  address_2: yup.string().max(50, "Maximum ${max} betű lehetséges"),
  city: yup.string().max(50, "Maximum ${max} betű lehetséges"),
  zip: yup.string().max(8, "Maximum ${max} betű lehetséges"),
});

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

const Step1: React.FC<Props> = ({ setStep }) => {
  const { settings } = useEventState();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateAction });
  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: state,
  });

  const showAddress = watch("showAddress");

  const onSubmit = (data: IFormInputs) => {
    console.log("here");
    actions.updateAction(data);
    setStep(2);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepCounter step={1} />
      <h4 className="ion-text-center">Esemény adatai</h4>
      <IonItem>
        <IonLabel position="stacked">Esemény neve</IonLabel>
        <Controller
          render={({ field }) => (
            <IonInput {...field} onIonChange={field.onChange} />
          )}
          control={control}
          name="name"
        />
        {errors.name?.message && (
          <div className="ion-text-start errorMessage">
            {errors.name?.message}
          </div>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Helyszín</IonLabel>
        <Controller
          render={({ field }) => (
            <IonInput {...field} onIonChange={field.onChange} />
          )}
          control={control}
          name="location"
        />
        {errors.location?.message && (
          <div className="ion-text-start errorMessage">
            {errors.location?.message}
          </div>
        )}
      </IonItem>
      <IonItem>
        <IonLabel>Helyszín címének megadása</IonLabel>
        <Controller
          render={({ field }) => (
            <IonToggle
              checked={field.value}
              onIonChange={(e) => field.onChange(e.detail.checked)}
            ></IonToggle>
          )}
          control={control}
          name="showAddress"
        />
      </IonItem>
      {showAddress && (
        <>
          <IonItem>
            <IonLabel position="stacked">Ország</IonLabel>
            <Controller
              render={({ field }) => (
                <IonSelect
                  okText="Rendben"
                  cancelText="Mégsem"
                  {...field}
                  onIonChange={field.onChange}
                  placeholder="Válassz egyet"
                >
                  {countries.map((k: any) => (
                    <IonSelectOption key={k.alpha2} value={k.alpha2}>
                      {k.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
              control={control}
              name="country"
            />
            {errors.country?.message && (
              <div className="ion-text-start errorMessage">
                {errors.country?.message}
              </div>
            )}
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Irányítószám</IonLabel>
            <Controller
              render={({ field }) => (
                <IonInput {...field} onIonChange={field.onChange} />
              )}
              control={control}
              name="zip"
            />
            {errors.zip?.message && (
              <div className="ion-text-start errorMessage">
                {errors.zip?.message}
              </div>
            )}
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Település</IonLabel>
            <Controller
              render={({ field }) => (
                <IonInput {...field} onIonChange={field.onChange} />
              )}
              control={control}
              name="city"
            />
            {errors.city?.message && (
              <div className="ion-text-start errorMessage">
                {errors.city?.message}
              </div>
            )}
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Utca, házszám</IonLabel>
            <Controller
              render={({ field }) => (
                <IonInput {...field} onIonChange={field.onChange} />
              )}
              control={control}
              name="address_1"
            />
            {errors.address_1?.message && (
              <div className="ion-text-start errorMessage">
                {errors.address_1?.message}
              </div>
            )}
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Emelet, ajtó</IonLabel>
            <Controller
              render={({ field }) => (
                <IonInput {...field} onIonChange={field.onChange} />
              )}
              control={control}
              name="address_2"
            />
            {errors.address_2?.message && (
              <div className="ion-text-start errorMessage">
                {errors.address_2?.message}
              </div>
            )}
          </IonItem>
        </>
      )}
      <IonItem>
        <IonLabel>Dátum</IonLabel>
        <Controller
          render={({ field }) => (
            <IonDatetime
              max={moment()
                .add(settings.MAX_MONTHS, "months")
                .format("YYYY-MM-DD")}
              monthNames={monthNames}
              min={moment().format("YYYY-MM-DD")}
              doneText="Rendben"
              cancelText="Mégsem"
              displayFormat="YYYY-MM-DD"
              {...field}
              onIonChange={field.onChange}
            />
          )}
          control={control}
          name="take_place_on_date"
        />
        {errors.take_place_on_date?.message && (
          <div className="ion-text-start errorMessage">
            {errors.take_place_on_date?.message}
          </div>
        )}
      </IonItem>
      <IonItem>
        <IonLabel>Időpont</IonLabel>
        <Controller
          render={({ field }) => (
            <IonDatetime
              doneText="Rendben"
              cancelText="Mégsem"
              displayFormat="H:mm"
              {...field}
              onIonChange={field.onChange}
            />
          )}
          control={control}
          name="take_place_on_time"
        />
        {errors.take_place_on_time?.message && (
          <div className="ion-text-start errorMessage">
            {errors.take_place_on_time?.message}
          </div>
        )}
      </IonItem>

      <IonItem>
        <IonLabel>Mikortól legyen elérhető?</IonLabel>
        <Controller
          render={({ field }) => (
            <IonDatetime
              max={moment()
                .add(settings.MAX_MONTHS, "months")
                .format("YYYY-MM-DD")}
              monthNames={monthNames}
              min={moment().format("YYYY-MM-DD")}
              doneText="Rendben"
              cancelText="Mégsem"
              displayFormat="YYYY-MM-DD"
              {...field}
              onIonChange={field.onChange}
            />
          )}
          control={control}
          name="open_on"
        />
        {errors.open_on?.message && (
          <div className="ion-text-start errorMessage">
            {errors.open_on?.message}
          </div>
        )}
      </IonItem>

      <IonRow className="ion-align-items-center">
        <IonCol className="ion-text-start">
          <IonButton
            type="button"
            shape="round"
            color="light"
            routerLink="/events"
            routerDirection="back"
          >
            Mégsem
            <IonIcon icon={closeOutline} slot="start" />
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-end">
          <IonButton type="submit" shape="round" color="primary">
            Tovább
            <IonIcon icon={arrowForwardCircleSharp} slot="end" />
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  );
};

export default Step1;

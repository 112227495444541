// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/empty.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ion-card,\n.card-ios,\n.card-md {\n  margin: 0px; }\n\n.imagePlaceholder {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center center; }\n\n.table-header {\n  font-weight: bold; }\n", "",{"version":3,"sources":["webpack://src/pages/wish/style.scss"],"names":[],"mappings":"AAAA;;;EAGE,WAAW,EAAA;;AAGb;EACE,WAAW;EACX,YAAY;EACZ,yDAA6C;EAC7C,sBAAsB;EACtB,kCAAkC,EAAA;;AAEpC;EACE,iBAAiB,EAAA","sourcesContent":["ion-card,\n.card-ios,\n.card-md {\n  margin: 0px;\n}\n\n.imagePlaceholder {\n  width: 100%;\n  height: 100%;\n  background-image: url(../../assets/empty.png);\n  background-size: cover;\n  background-position: center center;\n}\n.table-header{\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

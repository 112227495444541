import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ENV } from "../../config";
import { useEventState } from "../../components/EventProvider";
import { Controller, useForm } from "react-hook-form";
import { convertDate } from "../../util";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonLoading,
  IonDatetime,
  IonImg,
  IonRow,
  IonCol,
  IonButton,
  useIonToast,
} from "@ionic/react";
import { getErrorMessage } from "../../types/errorMessages";
import { cameraSharp, checkmarkSharp, closeSharp } from "ionicons/icons";
import { monthNames } from "../../types/date";
import countries from "../../types/countries.json";
import moment from "moment";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

interface Props {
  onDismiss(): void;
}

const schema = yup
  .object({
    name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    location: yup
      .string()
      .required("Kérlek, töltsd ki ezt a mezőt!")
      .max(255, "Maximum ${max} betű lehetséges"),
    description: yup
      .string()
      .nullable()
      .max(5000, "Maximum ${max} betű lehetséges"),
    take_place_on_date: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
    take_place_on_time: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
    open_on: yup.string().nullable().required("Kérlek, töltsd ki ezt a mezőt!"),
    address_1: yup
      .string()
      .nullable()
      .max(50, "Maximum ${max} betű lehetséges"),
    address_2: yup
      .string()
      .nullable()
      .max(50, "Maximum ${max} betű lehetséges"),
    city: yup.string().nullable().max(50, "Maximum ${max} betű lehetséges"),
    zip: yup.string().nullable().max(8, "Maximum ${max} betű lehetséges"),
    imageData: yup.string(),
  })
  .required();

type IFormInputs = {
  name: string;
  description: string;
  qty: number;
  url: string;
  [key: string]: any;
};

const EditEventModal: React.FC<Props> = ({ onDismiss }) => {
  const { event, settings, updateEvent, getEvent } = useEventState();
  const [present, dismiss] = useIonLoading();
  const [presentToast, dismissToast] = useIonToast();

  const take_place_on = event.take_place_on;
  event.take_place_on = moment.utc(take_place_on).format("YYYY-MM-DD HH:mm");

  event.take_place_on_date = moment
    .utc(take_place_on)
    .local()
    .format("YYYY-MM-DD");
  event.take_place_on_time = moment.utc(take_place_on).local().format("HH:mm");
  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: event,
  });
  const [imageData, setImageData] = useState<string>("");

  const showAddress = watch("showAddress");

  const takePhoto = async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        quality: 100,
        source: CameraSource.Prompt,
      });

      setValue("imageData", cameraPhoto.dataUrl);
      setImageData(cameraPhoto?.dataUrl!);
    } catch (e: any) {
      console.log(e.response);
    }
  };

  const onSubmit = async (data: IFormInputs) => {
    data.take_place_on = convertDate(
      data.take_place_on_date,
      data.take_place_on_time
    );

    try {
      await present();
      //TODO: this should be added
      //data.category_id = 1;
      //data.template_id = 1;
      const res = await updateEvent(event.id, data);
      //TODO: implement error handling
      await dismiss();
      await getEvent(event.id);
      presentToast("Adatmentés rendben", 3000);
      await dismiss();
      setTimeout(() => onDismiss(), 200);
    } catch (err: any) {
      console.log(err);
      await dismiss();
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      }
    }
  };

  return (
    <>
      {event && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Esemény módosítása</IonTitle>
              <IonButtons slot="end">
                <IonIcon
                  icon={closeSharp}
                  slot="end"
                  onClick={() => onDismiss()}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonItem>
                <IonLabel position="stacked">Esemény neve</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonInput {...field} onIonChange={field.onChange} />
                  )}
                  control={control}
                  name="name"
                />
                {errors.name?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.name?.message}
                  </div>
                )}
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Leírás</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonTextarea
                      auto-grow={true}
                      {...field}
                      onIonChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="description"
                />
                {errors.description?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.description?.message}
                  </div>
                )}
              </IonItem>

              <IonItem>
                <IonLabel>Helyszín</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonInput
                      className="ion-text-right"
                      slot="end"
                      {...field}
                      onIonChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="location"
                />
              </IonItem>
              {errors.location?.message && (
                <div className="ion-text-start errorMessage">
                  {errors.location?.message}
                </div>
              )}

              <IonItem>
                <IonLabel>Helyszín címének megadása</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonToggle
                      checked={field.value}
                      onIonChange={(e) => field.onChange(e.detail.checked)}
                    ></IonToggle>
                  )}
                  control={control}
                  name="showAddress"
                />
              </IonItem>
              {showAddress && (
                <>
                  <IonItem>
                    <IonLabel>Ország</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonSelect
                          interface="alert"
                          okText="Rendben"
                          cancelText="Mégsem"
                          slot="end"
                          {...field}
                          onIonChange={field.onChange}
                          placeholder="Válassz egyet"
                        >
                          {countries.map((k: any) => (
                            <IonSelectOption key={k.alpha2} value={k.alpha2}>
                              {k.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                      control={control}
                      name="country"
                    />
                  </IonItem>
                  {errors.country?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.country?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel>Irányítószám</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          className="ion-text-right"
                          slot="end"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="zip"
                    />
                  </IonItem>
                  {errors.zip?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.zip?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel>Település</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          className="ion-text-right"
                          slot="end"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="city"
                    />
                  </IonItem>
                  {errors.city?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.city?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel>Utca, házszám</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          className="ion-text-right"
                          slot="end"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="address_1"
                    />
                  </IonItem>
                  {errors.address_1?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.address_1?.message}
                    </div>
                  )}

                  <IonItem>
                    <IonLabel>Emelet, ajtó</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          className="ion-text-right"
                          slot="end"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="address_2"
                    />
                  </IonItem>
                  {errors.address_2?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.address_2?.message}
                    </div>
                  )}
                </>
              )}

              <IonItem>
                <IonLabel>Dátum</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonDatetime
                      max={moment()
                        .add(settings.MAX_MONTHS, "months")
                        .format("YYYY-MM-DD")}
                      monthNames={monthNames}
                      min={moment().format("YYYY-MM-DD")}
                      doneText="Rendben"
                      cancelText="Mégsem"
                      displayFormat="YYYY-MM-DD"
                      {...field}
                      onIonChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="take_place_on_date"
                />
                {errors.take_place_on_date?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.take_place_on_date?.message}
                  </div>
                )}
              </IonItem>
              <IonItem>
                <IonLabel>Időpont</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonDatetime
                      doneText="Rendben"
                      cancelText="Mégsem"
                      displayFormat="H:mm"
                      {...field}
                      onIonChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="take_place_on_time"
                />
                {errors.take_place_on_time?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.take_place_on_time?.message}
                  </div>
                )}
              </IonItem>

              <IonItem>
                <IonLabel>Mikortól legyen elérhető?</IonLabel>
                <Controller
                  render={({ field }) => (
                    <IonDatetime
                      max={moment()
                        .add(settings.MAX_MONTHS, "months")
                        .format("YYYY-MM-DD")}
                      monthNames={monthNames}
                      min={moment().format("YYYY-MM-DD")}
                      doneText="Rendben"
                      cancelText="Mégsem"
                      displayFormat="YYYY-MM-DD"
                      {...field}
                      onIonChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="open_on"
                />
                {errors.open_on?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.open_on?.message}
                  </div>
                )}
              </IonItem>

              <IonItem
                onClick={async () => {
                  await takePhoto();
                }}
              >
                <IonLabel>Fotó </IonLabel>
                <IonIcon icon={cameraSharp} slot="end" />
              </IonItem>
              {imageData && (
                <IonItem className="ion-text-center">
                  <IonImg
                    src={imageData}
                    style={{
                      margin: "0px auto",
                      width: "50%",
                      display: "block",
                    }}
                  />
                </IonItem>
              )}
              {!imageData && event?.image_xs! && (
                <IonItem className="ion-text-center">
                  <IonImg
                    src={ENV.CDN_URL + event?.image_xs!}
                    style={{
                      margin: "0px auto",
                      width: "50%",
                      display: "block",
                    }}
                  />
                </IonItem>
              )}

              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-end">
                  <IonButton type="submit" shape="round" color="primary">
                    Mentés
                    <IonIcon icon={checkmarkSharp} slot="end" />
                  </IonButton>
                </IonCol>
              </IonRow>
            </form>
          </IonContent>
        </>
      )}
    </>
  );
};

export default EditEventModal;

import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  useIonLoading,
  useIonToast,
  IonRouterOutlet,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import "./event.scss";
import {
  cartSharp,
  closeOutline,
  homeOutline,
  peopleOutline,
  settingsOutline,
  sparklesOutline,
} from "ionicons/icons";
import EventHome from "./EventHome";
import EventParticipants from "./EventParticipants";
import EventSetting from "./EventSetting";
import MyWishPage from "./MyWishPage";
import { useAuthState } from "../../components/AuthProvider";
import EventParticipant from "./EventParticipant";
import HeaderComponent from "../../components/HeaderComponent";
import EventWishPage from "./EventWishPage";
import ShoppingList from "./ShoppingList";
import { TPlan } from "../../types/event.type";
import PlanPriceDetail from "../../components/PlanPriceDetail";

type TEventPageParam = { event_id: string };
interface Props {}

const EventPage: React.FC<Props> = (props: Props) => {
  const { event, getEvent, plans, deleteEvent } = useEventState();
  const { profile } = useAuthState();
  const location = useLocation();
  const event_id = location.pathname.split("/")[2];
  const selectedPlan = plans.find((p: TPlan) => p.id === event?.plan_id);
  const defaultTab = location.pathname.split("/")[3] || "home";
  const [tab, setTab] = useState(defaultTab);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const [presentToast, dismissToast] = useIonToast();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  console.log({ history }, url, path);
  /**
   * Load event record
   */
  const loadRecord = async (e?: CustomEvent<RefresherEventDetail>) => {
    try {
      await getEvent(event_id);
    } catch (err) {
      console.log(err);
      history.push("/events");
    } finally {
      if (e) {
        e.detail.complete();
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEvent(event.id);
      presentToast("Esemény törölve!", 5000);
      setTimeout(() => history.replace("/events"), 300);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Load record at startup
   */
  useEffect(() => {
    if (event_id && profile) {
      loadRecord();
    } else {
      // history.replace("/home");
    }
  }, [event_id, profile]);

  useEffect(() => {
    var r = document.getElementsByTagName("body")[0].classList;
    for (let i = 0; i < r.length; i++) {
      if (r.item(i)?.startsWith("style")) {
        let s = r.item(i);
        if (s) {
          r.remove(s);
        }
      }
    }
    if (event) {
      r?.add("style" + event.template_id);
    }
  }, [event]);

  const renderEvent = () => {
    return (
      <>
        <HeaderComponent title={event.name} />

        <IonContent fullscreen={true} scrollEvents={true}>
          <IonRefresher slot="fixed" onIonRefresh={loadRecord}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <div
            style={{ backgroundImage: `url(${ENV.CDN_URL}${event.image_lg})` }}
            className="eventHeader"
          >
            <div className="eventName">{event.name}</div>
          </div>

          <IonSegment
            value={tab}
            color="primary"
            onIonChange={(e) => {
              setTab(e.detail.value!);
            }}
          >
            <IonSegmentButton
              value="home"
              onClick={(e: any) => history.push(url + "/home")}
            >
              <IonIcon icon={homeOutline} />
              <IonLabel className="ion-hide-sm-down">Kezdőlap</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="participants"
              onClick={(e: any) => history.push(url + "/participants")}
            >
              <IonLabel className="ion-hide-sm-down">Résztvevők</IonLabel>
              <IonIcon icon={peopleOutline} />
            </IonSegmentButton>
            {event.myProfile.can_post_wish && (
              <IonSegmentButton
                value="mywish"
                onClick={(e: any) => history.push(url + "/mywish")}
              >
                <IonLabel className="ion-hide-sm-down">Kívánságaim</IonLabel>
                <IonIcon icon={sparklesOutline} />
              </IonSegmentButton>
            )}
            {event.myProfile.can_engage_wish && (
              <IonSegmentButton
                value="shoppinglist"
                onClick={(e: any) => history.push(url + "/shoppinglist")}
              >
                <IonLabel className="ion-hide-sm-down">Ajándéklista</IonLabel>
                <IonIcon icon={cartSharp} />
              </IonSegmentButton>
            )}
            {!event.myProfile.is_limited && (
              <IonSegmentButton
                value="settings"
                onClick={(e: any) => history.push(url + "/settings")}
              >
                <IonLabel className="ion-hide-sm-down">Beállítások</IonLabel>
                <IonIcon icon={settingsOutline} />
              </IonSegmentButton>
            )}
          </IonSegment>

          <Route exact path={`${path}/mywish`} component={MyWishPage} />
          <Route exact path={`${path}/shoppinglist`} component={ShoppingList} />
          <Route
            exact
            path={`${path}/wish/:wish_id`}
            component={EventWishPage}
          />
          <Route exact path={`${path}/settings`} component={EventSetting} />
          <Route
            path={`${path}/participants`}
            exact
            component={EventParticipants}
          />
          <Route
            path={`${path}/participants/:event_participant_id`}
            exact
            component={EventParticipant}
          />

          <Route exact path={`${path}/home`} component={EventHome} />
          <Route exact path={`${path}`} component={EventHome} />
        </IonContent>
      </>
    );
  };

  const renderNonPaid = () => {
    return (
      <>
        <HeaderComponent title={event.name} />

        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => console.log(e.detail.scrollTop)}
        >
          <IonAlert
            isOpen={showDeleteConfirm}
            onDidDismiss={() => setShowDeleteConfirm(false)}
            header="Esemény törlése"
            message={
              "Biztos, hogy törölni szeretnéd? A művelet nem visszavonható!" +
              (event.price
                ? " A befizetett díj nem kerül visszatérítésre!"
                : "")
            }
            buttons={[
              "Mégsem",
              {
                text: "Igen, töröljük!",
                handler: (d) => {
                  handleDelete();
                },
              },
            ]}
          />
          <IonRefresher slot="fixed" onIonRefresh={loadRecord}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <div
            style={{ backgroundImage: `url(${ENV.CDN_URL}${event.image_lg})` }}
            className="eventHeader"
          >
            <div className="eventName">{event.name}</div>
          </div>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" size-md="8" size-lg="6" size-xl="4">
                <div
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: "20px",
                  }}
                >
                  Ez az esemény fizetésre vár. <br /> <br />
                  {selectedPlan && <PlanPriceDetail plan={selectedPlan} />}
                  <br />
                  <IonButton href={`/event/${event.id}/checkout`}>
                    Online Fizetés Indítása
                  </IonButton>
                  <br />
                  <br />
                  <hr />
                  <br />
                  <IonButton
                    color="danger"
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    <IonIcon icon={closeOutline} slot="end" />
                    Esemény törlése
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </>
    );
  };
  return (
    <IonPage>
      {event?.id! && (
        <>
          {!event.is_paid && renderNonPaid()}
          {event.is_paid && renderEvent()}
        </>
      )}
    </IonPage>
  );
};

export default EventPage;

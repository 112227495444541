import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonCard,
  IonImg,
  IonCardHeader,
  IonCardSubtitle,
  IonButton,
} from "@ionic/react";
import { calendarOutline, chevronBack, navigateOutline } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import { TEventParticipant, TEventWish } from "../../types/event.type";
import { getCountryName } from "../../util";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ProfileLink from "./ProfileLink";
import { useAuthState } from "../../components/AuthProvider";

type Props = RouteComponentProps & {
  title: string;
};

const EventHome: React.FC<Props> = ({ history }) => {
  const { profile } = useAuthState();
  const { event, getWishes } = useEventState();
  const [wishes, setWishes] = useState<Array<TEventWish>>([]);

  const loadWishes = async () => {
    try {
      const res = await getWishes({ order: 3, exclude_user: profile.id });
      setWishes(res.data.rows);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadWishes();
  }, [event]);

  return (
    <>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            <IonButton fill="clear" size="small" href={"/events"}>
              <IonIcon icon={chevronBack} slot="start" />
              Vissza
            </IonButton>
            <div className="eventDescription">
              {event.description &&
                event.description
                  .split("\n")
                  .map(function (item: string, idx: number) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
            </div>
            <IonRow className="ion-justify-content-center ion-padding-top">
              {event.is_past && (
                <IonCol size="12">
                  <div className="eventDate absion-text-center ion-padding-bottom">
                    Ez az esemény már véget ért!
                  </div>
                </IonCol>
              )}
              <IonCol size="6">
                <div className="  eventDate ion-padding-bottom">
                  <IonIcon icon={calendarOutline} /> Ideje:
                  <div className="ion-text-center ion-padding-top">
                    {moment
                      .utc(event.take_place_on)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                    <br />
                    {!event.is_past && (
                      <small>
                        (még{" "}
                        {Math.abs(
                          moment().diff(moment(event.take_place_on), "days")
                        )}{" "}
                        nap)
                      </small>
                    )}
                  </div>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="eventLocation ion-padding-bottom">
                  <IonIcon icon={navigateOutline} /> Helye:
                  <div className="ion-text-center ion-padding-top">
                    {event.location}
                    <br />
                    {event.zip && (
                      <>
                        {`${event.zip} ${event.city}, ${event.address_1} ${event.address_2} `}
                        <br />
                        {getCountryName(event.country)}
                      </>
                    )}
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <hr />
            {wishes.length > 0 && (
              <>
                <IonRow>
                  <IonCol>
                    <h4>Legfrissebb kívánságok:</h4>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-start">
                  {wishes.map((w: TEventWish, i: number) => {
                    const eventParticipant = event.participants.find(
                      (p: TEventParticipant) =>
                        p.profile_id == w.Wish?.created_by
                    );
                    if (!eventParticipant) {
                      return null;
                    }
                    return (
                      <IonCol
                        size="12"
                        size-md="6"
                        size-lg="4"
                        size-xl="4"
                        key={i}
                      >
                        <ProfileLink ep={eventParticipant} /> kívánsága:
                        <IonCard href={`/event/${event.id}/wish/${w.id}`}>
                          <IonImg src={ENV.CDN_URL + w.Wish?.image_lg!} />
                          <IonCardHeader>
                            <IonCardSubtitle>{w.Wish?.name}</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default withRouter(EventHome);

import React from "react";
import { useAuthState } from "../../components/AuthProvider";
import { Controller, useForm } from "react-hook-form";
import { useEventState } from "../../components/EventProvider";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { closeSharp, addSharp } from "ionicons/icons";

import { getErrorMessage } from "../../types/errorMessages";

export type IFormInputs = {
  profile_id: string;
  can_post_wish: boolean;
  can_engage_wish: boolean;
  is_admin: boolean;
  [key: string]: any;
};

const schema = yup
  .object({
    email: yup
      .string()
      .email("Nem megfelelő email cím formátum!")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    can_post_wish: yup.boolean(),
    can_engage_wish: yup.boolean(),
    is_admin: yup.boolean(),
  })
  .required();

interface Props {
  onDismiss(): void;
}

const EmailInvitationForm: React.FC<Props> = ({ onDismiss }) => {
  const { event, invite, getEvent } = useEventState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const { profile, profiles } = useAuthState();
  let {
    control,
    register,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      can_engage_wish: false,
      can_post_wish: false,
      is_admin: false,
    },
  });

  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();
      const res = await invite(data);
      console.log(res);
      await getEvent(event.id);
      await dismiss(); 
      presentToast({
        message: "Meghívót elküldtük!",
        duration: 3000,
        position: "top",
        color: "dark",
      });
      setValue("email", "");
      setTimeout(() => clearErrors(), 100);
    } catch (err: any) {
      await dismiss(); 

      const { error } = err.response.data;
      if (error) {
        setError("email", { type: "manual", message: getErrorMessage(error) });
      }
    }
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ismerős meghívása</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center ion-align-items-end ">
              <IonCol size="12">
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">E-mail cím</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="email"
                          {...field}
                          onIonChange={field.onChange}
                        />
                      )}
                      control={control}
                      name="email"
                    />
                  </IonItem>
                  {errors.email?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.email?.message}
                    </div>
                  )}

                  <IonItem>
                    <IonLabel>Hozzáadhat kívánságot</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="can_post_wish"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Teljesíthet kívánságot</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="can_engage_wish"
                    />
                  </IonItem>

                  <IonItem>
                    <IonLabel>Adminisztrátor</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="is_admin"
                    />
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-text-end">
                <IonButton type="submit" shape="round" color="primary">
                  <IonIcon icon={addSharp} slot="start" />
                  Meghívó küldése
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export default EmailInvitationForm;

import React from "react";
import NumberFormat from "react-number-format";
import { TCurrency, CURRENCIES } from "../types/event.type";

interface Props {
  price: number;
  currency: string;
}

const PlanPrice: React.FC<Props> = ({ price, currency }) => {
  const currency_: TCurrency =
    CURRENCIES.find((c: TCurrency) => c.code === currency) || CURRENCIES[0];
  return (
    <>
      {price != 0 ? (
        <NumberFormat
          value={Math.round(price)}
          thousandSeparator="&nbsp;"
          decimalSeparator=","
          prefix={currency_.symbol_position === "l" ? currency_.symbol : ""}
          suffix={
            currency_.symbol_position === "r" ? " " + currency_.symbol : ""
          }
          displayType="text"
        />
      ) : (
        "Ingyenes!"
      )}
    </>
  );
};

export default PlanPrice;

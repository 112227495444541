import React, { useEffect, useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import { useEventState } from "../../components/EventProvider";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  useIonLoading,
  useIonToast,
  IonNote,
} from "@ionic/react";
import { closeSharp, addSharp } from "ionicons/icons";

import { TEvent, TEventParticipant } from "../../types/event.type";
import RecordNotFound from "../../components/RecordNotFound";

interface Props {
  onDismiss(): void;
}

const OldEventsForm: React.FC<Props> = ({ onDismiss }) => {
  const { invite, getEvents } = useEventState();
  const [selectedEvent, setSelectedEvent] = useState<TEvent>();
  const [selectedParticipants, setSelectedParticipants] = useState<
    Array<TEventParticipant>
  >([]);
  const [events, setEvents] = useState<Array<TEvent>>([]);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const loadEvents = async () => {
    try {
      const res = await getEvents({ before: moment().format("YYYY-MM-DD") });
      console.log(res);
      setEvents(res.rows);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const submit = async () => {
    try {
      if (selectedParticipants.length) {
        await present();
        for (let p of selectedParticipants) {
          try {
            const res = await invite({
              invited_profile_id: p.profile_id,
              can_engage_wish: p.can_engage_wish,
              can_post_wish: p.can_post_wish,
              is_admin: p.is_admin,
            });
          } catch (error) {}
        }
        await dismiss();
        presentToast({
          message: "Meghívókat elküldtük!",
          duration: 3000,
          position: "top",
          color: "dark",
        });
        onDismiss();
      }
    } catch (err: any) {
      await dismiss();

      const { error } = err.response.data;
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Régebbi esemény résztvevőinek hozzáadása</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed={true}>
          <IonRow className="ion-justify-content-center ion-align-items-end ">
            <IonCol size="12">
              <IonList>
                {!selectedEvent &&
                  events.length &&
                  events.map((e: TEvent) => {
                    let participants: TEventParticipant[] = [];
                    if (e.participants) {
                      participants = e.participants.map((p) => p);
                    }
                    return (
                      <IonItem
                        key={e.id}
                        button
                        onClick={() => {
                          setSelectedEvent(e);
                          setSelectedParticipants(participants);
                        }}
                      >
                        <IonLabel>{e.name}</IonLabel>
                        <IonNote slot="end" color="dark">
                          {e.participants?.length}
                        </IonNote>
                      </IonItem>
                    );
                  })}
                {selectedEvent &&
                  selectedEvent.participants?.map((participant) => (
                    <IonItem key={participant.id}>
                      <IonLabel>
                        <b>{participant.profile?.name!}</b>
                        <br />
                        {participant.user.email}
                      </IonLabel>
                      <IonCheckbox
                        checked={
                          !!selectedParticipants?.find(
                            (p) => p.id === participant.id
                          )
                        }
                        onIonChange={(e) => {
                          if (e.detail.checked) {
                            setSelectedParticipants([
                              ...selectedParticipants,
                              participant,
                            ]);
                          } else {
                            let i = selectedParticipants.findIndex(
                              (p) => p.id === participant.id
                            );
                            selectedParticipants.splice(i, 1);
                            setSelectedParticipants(selectedParticipants);
                          }
                        }}
                      />
                    </IonItem>
                  ))}
              </IonList>

              {events.length === 0 && (
                <RecordNotFound>Nincs megjeleníthető esemény!</RecordNotFound>
              )}
            </IonCol>
          </IonRow>
          {selectedEvent && (
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-text-end">
                <IonButton
                  type="submit"
                  shape="round"
                  color="primary"
                  onClick={() => submit()}
                >
                  <IonIcon icon={addSharp} slot="start" />
                  Meghívó küldése
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </>
  );
};

export default OldEventsForm;

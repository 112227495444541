import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { profile } from "console";
import { chevronBack, addSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useBillingAccountState } from "../../components/BillingAccoutProvider";
import HeaderComponent from "../../components/HeaderComponent";
import { TBillingAccount } from "../../types/billingAccount.type";
import EditBillingAccountPage from "./EditBillingAccountPage";
import { getCountryName } from "../../util";
interface Props {}

const BillingAccountPage: React.FC = (props: Props) => {
  const { billingAccounts, getRecords, deleteRecord } =
    useBillingAccountState();
  const [showConfirm, setshowConfirm] = useState<boolean>(false);
  const [deleteRecordId, setdeleteRecordId] = useState<string>("");
  const [editRecordId, seteditRecordId] = useState<string>("");
  const [presentToast] = useIonToast();
  const [showEditModal, setshowEditModal] = useState<boolean>(false);
  const [present, dismiss] = useIonLoading();
  const history = useHistory();
  const loadRecords = async () => {
    try {
      await present();
      const res = await getRecords();
      await dismiss();
    } catch (e: any) {
      console.log(e);
      await dismiss();
    }
  };

  const handleDelete = async () => {
    if (deleteRecordId) {
      try {
        await present();
        await deleteRecord(deleteRecordId);
        await dismiss();
        presentToast("Rekord törlése rendben", 1000);
        setdeleteRecordId("");
        await loadRecords();
      } catch (err) {
        await dismiss();
      }
    }
  };

  /**
   * Open edit modal
   * @param profile_id
   */
  const handleEdit = (profile_id: string) => {
    seteditRecordId(profile_id);
    setshowEditModal(true);
  };

  const handleDismiss = () => {
    setshowEditModal(false);
  };

  useEffect(() => {
    loadRecords();
  }, []);
  return (
    <IonPage>
      <HeaderComponent title="Számlázási címek" />
      <IonContent fullscreen={true}>
        <IonButton fill="clear" size="small" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBack} slot="start" />
          Vissza
        </IonButton>

        <IonAlert
          isOpen={showConfirm}
          onDidDismiss={() => setshowConfirm(false)}
          header="Rekord törlése"
          message="Biztos, hogy törölni szerentéd ezt a számlázáso címet? A műveletet nem lehet visszavonni!"
          buttons={[
            "Mégsem",
            {
              text: "Igen, törlöm a rekordot.",
              handler: (d) => handleDelete(),
            },
          ]}
        />
        <IonModal
          isOpen={showEditModal}
          backdropDismiss={false}
          children={
            <EditBillingAccountPage
              editRecordId={editRecordId}
              onDismiss={handleDismiss}
            />
          }
        />
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            <IonRow className="ion-align-items-center">
              <IonCol>
                <div className="ion-text-end">
                  <IonButton
                    shape="round"
                    onClick={() => handleEdit("new")}
                    color="success"
                  >
                    Új cím
                    <IonIcon icon={addSharp} slot="start" />
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
            <IonList>
              {billingAccounts.map((b: TBillingAccount) => (
                <IonItem key={b.id} onClick={() => handleEdit(b.id)}>
                  <IonLabel>
                    <IonText>{b.billing_name}</IonText>
                    <p>
                      {b.zip} {b.city}, {b.address_1} {b.address_2},
                      {getCountryName(b.country!)}
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default BillingAccountPage;

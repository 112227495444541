import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react";
import HeaderComponent from "../../components/HeaderComponent";
import Info from "./Info";

const InfoPage = () => {
  return (
    <>
      <IonPage>
        <HeaderComponent title="Információk" />
        <IonContent fullscreen={true}>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                className="ion-text-center ion-margin-top "
                size="12"
                size-md="8"
                size-lg="6"
                size-xl="3"
              >
                <Info />{" "}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default InfoPage;

import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonLoading,
  IonModal,
} from "@ionic/react";
import { arrowBackCircleSharp, checkmarkCircleSharp } from "ionicons/icons";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Redirect, useHistory } from "react-router-dom";
import StepCounter from "./StepCounter";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { convertDate, getCountryName } from "../../util";
import { useEventState } from "../../components/EventProvider";
import { TPlan } from "../../types/event.type";
import PlanDetails from "../../components/PlanDetails";
import { useBillingAccountState } from "../../components/BillingAccoutProvider";
import { TBillingAccount } from "../../types/billingAccount.type";
import BillingAccountDetails from "../../components/BillingAccountDetails";
import { setTimeout } from "timers";
import moment from "moment";
import { getErrorMessage } from "../../types/errorMessages";
import { useForm } from "react-hook-form";

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

const Step3: React.FC<Props> = ({ setStep }) => {
  const history = useHistory();
  const { plans, createEvent, getEvents } = useEventState();
  const { billingAccounts } = useBillingAccountState();
  const [present, dismiss] = useIonLoading();
  const { actions, state } = useStateMachine({ updateAction });
  const selectedPlan = plans.find((p: TPlan) => p.id === state.plan_id);
  const selectedBillingAccount = billingAccounts.find(
    (b: TBillingAccount) => b.id === state.billing_account_id
  );
  let {
    setError,
    formState: { errors },
  } = useForm<any>({});

  const handleSubmit = async () => {
    try {
      await present();
      state.take_place_on = convertDate(
        state.take_place_on_date,
        state.take_place_on_time
      );
      //TODO: this should be added
      state.category_id = 1;
      state.template_id = 0;
      Object.keys(state).forEach((k) => state[k] === "" && delete state[k]);
      const res = await createEvent(state);
      //TODO: implement error handling
      await dismiss();

      await getEvents({}, true);
      const empty: any = {};
      for (let i in state) {
        empty[i] = "";
      }
      actions.updateAction(empty);
      history.replace("/event/" + res?.data?.event?.id!);
    } catch (err: any) {
      await dismiss();
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else if (err.response.data.errors) {
        setError("plan_id", {
          type: "manual",
          message: getErrorMessage(err.response.data.errors),
        });
      }
    }
  };

  if (state.name) {
    return (
      <>
        <StepCounter step={3} />
        <h2>Összegzés</h2>
        <IonList>
          <IonItem lines="full">
            <IonLabel slot="start">Esemény</IonLabel>
            <IonLabel slot="end" className="ion-text-wrap ion-text-right">
              {state.name!}
            </IonLabel>
          </IonItem>
          {errors.name?.message && (
            <div className="ion-text-start errorMessage">
              {errors.name?.message}
            </div>
          )}
          <IonItem lines="full">
            <IonLabel slot="start">Helyszín</IonLabel>
            <IonLabel slot="end">{state.location!}</IonLabel>
          </IonItem>
          {state.showAddress! && (
            <>
              <IonItem lines="full">
                <IonLabel slot="start">Cím</IonLabel>
                <IonLabel slot="end" className="ion-text-wrap ion-text-right">
                  {state.zip} {state.city}, {state.address_1} {state.address_2},{" "}
                  {getCountryName(state.country!)}
                </IonLabel>
              </IonItem>
              {errors.zip?.message && (
                <div className="ion-text-start errorMessage">
                  {errors.zip?.message}
                </div>
              )}
              {errors.city?.message && (
                <div className="ion-text-start errorMessage">
                  {errors.city?.message}
                </div>
              )}
              {errors.address_1?.message && (
                <div className="ion-text-start errorMessage">
                  {errors.address_1?.message}
                </div>
              )}
              {errors.address_2?.message && (
                <div className="ion-text-start errorMessage">
                  {errors.address_2?.message}
                </div>
              )}
            </>
          )}
          <IonItem lines="full">
            <IonLabel slot="start">Időpont</IonLabel>
            <IonLabel slot="end" className="ion-text-wrap ion-text-right">
              {convertDate(state.take_place_on_date, state.take_place_on_time)}
            </IonLabel>
          </IonItem>
          {errors.take_place_on?.message && (
            <div className="ion-text-start errorMessage">
              {errors.take_place_on?.message}
            </div>
          )}
          <IonItem lines="full">
            <IonLabel slot="start" className="ion-text-wrap">
              Nyitás
              <br />
              <small>
                Ettől kezdve jelenik meg az esemény a többiek számára
              </small>
            </IonLabel>
            <IonLabel slot="end" className="ion-text-wrap ion-text-right">
              {moment(state.open_on).format("YYYY-MM-DD")}
            </IonLabel>
          </IonItem>
          {errors.open_on?.message && (
            <div className="ion-text-start errorMessage">
              {errors.open_on?.message}
            </div>
          )}
          <IonItem lines="full">
            <IonLabel>Csomag</IonLabel>
            <div className="ion-text-wrap ion-text-center">
              <PlanDetails plan={selectedPlan} />
            </div>
          </IonItem>
          {selectedBillingAccount && (
            <IonItem lines="full">
              <IonLabel>Számlázási cím</IonLabel>
              <IonLabel className="ion-text-wrap ion-text-right">
                <BillingAccountDetails
                  billingAccount={selectedBillingAccount}
                />
              </IonLabel>
            </IonItem>
          )}
        </IonList>

        <IonRow className="ion-align-items-center">
          <IonCol className="ion-text-start">
            <IonButton
              type="button"
              shape="round"
              color="light"
              onClick={() => setStep(2)}
            >
              Vissza
              <IonIcon icon={arrowBackCircleSharp} slot="start" />
            </IonButton>
          </IonCol>
          <IonCol className="ion-text-end">
            <IonButton
              type="button"
              shape="round"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Befejezés
              <IonIcon icon={checkmarkCircleSharp} slot="end" />
            </IonButton>
          </IonCol>
        </IonRow>
      </>
    );
  } else {
    //TODOreturn <Redirect to="/new-event" />;
    //
    return <></>;
  }
};

export default Step3;

import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonModal,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  logOutSharp,
  lockClosedSharp,
  peopleOutline,
  keyOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAuthState } from "../../components/AuthProvider";
import HeaderComponent from "../../components/HeaderComponent";
import { ChangePasswordComponent } from "./ChangePasswordComponent";
import { EditAccountComponent } from "./EditAccountComponent";
import { EditPincodePage } from "./EditPincodePage";
import Icon from "@mui/material/Icon";
import EditNotificationPage from "./EditNotificationPage";

export const SettingsPage: React.FC = () => {
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [showEditPincodeModal, setShowEditPincodeModal] =
    useState<boolean>(false);

  const {
    user,
    logout,
    profile,
    setLoading,
    getAccount,
    saveJWT,
    loadCurrentUser,
  } = useAuthState();

  const handlePasswordDismiss = () => {
    setShowPasswordModal(false);
  };

  const handleAccountDismiss = () => {
    setShowAccountModal(false);
  };

  const handlePincodeDismiss = () => {
    setShowEditPincodeModal(false);
  };
  const handleNotificationDismiss = () => {
    setShowNotificationModal(false);
  };

  const loadUser = async () => {
    try {
      const result = await getAccount();
      if (result.status == 200) {
        await saveJWT(result.data?.token);
        await loadCurrentUser();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  if (!user) {
    return <>Loading...</>;
  }
  if (profile && profile.is_limited) {
    return <Redirect to="/home" />;
  } else {
    return (
      <>
        <IonPage>
          <IonModal
            isOpen={showPasswordModal}
            backdropDismiss={false}
            children={
              <ChangePasswordComponent onDismiss={handlePasswordDismiss} />
            }
          />

          <IonModal
            isOpen={showAccountModal}
            backdropDismiss={false}
            children={<EditAccountComponent onDismiss={handleAccountDismiss} />}
          />

          <IonModal
            isOpen={showEditPincodeModal}
            backdropDismiss={false}
            children={<EditPincodePage onDismiss={handlePincodeDismiss} />}
          />
          <IonModal
            isOpen={showNotificationModal}
            backdropDismiss={false}
            children={
              <EditNotificationPage onDismiss={handleNotificationDismiss} />
            }
          />

          <HeaderComponent title="Beállítások" />
          <IonContent fullscreen={true}>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol
                  className="ion-text-center ion-margin-top "
                  size="12"
                  size-md="8"
                  size-lg="6"
                  size-xl="3"
                >
                  <IonList>
                    <IonItem button routerLink="/profiles" lines="full">
                      <IonIcon slot="start" icon={peopleOutline} />
                      <IonLabel>Profil beállítások</IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      onClick={() => setShowAccountModal(true)}
                      lines="full"
                    >
                      <IonIcon slot="start" icon={keyOutline} />
                      <IonLabel>Fiókbeállítás</IonLabel>
                    </IonItem>

                    <IonItem
                      button
                      onClick={() => setShowNotificationModal(true)}
                      lines="full"
                    >
                      <Icon slot="start">mark_email_read</Icon>
                      <IonLabel>Email értesítések </IonLabel>
                    </IonItem>

                    {user.authentication_method === "PASSWORD" && (
                      <IonItem
                        button
                        onClick={() => setShowPasswordModal(true)}
                        lines="full"
                      >
                        <Icon slot="start">password</Icon>
                        <IonLabel>Jelszómódosítás</IonLabel>
                      </IonItem>
                    )}
                    <IonItem button routerLink="/billing-accounts" lines="full">
                      <Icon slot="start">receipt</Icon>
                      <IonLabel>Számlázási címek</IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      onClick={() => setShowEditPincodeModal(true)}
                      lines="full"
                    >
                      <IonIcon icon={lockClosedSharp} slot="start" />
                      <IonLabel>Pin kód módosítása</IonLabel>
                    </IonItem>
                  </IonList>
                  <br />
                  <IonButton
                    shape="round"
                    color="danger"
                    expand="block"
                    onClick={async () => {
                      try {
                        setLoading(true);
                        await logout();
                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                      }
                    }}
                  >
                    Kijelentkezés
                    <IonIcon icon={logOutSharp} slot="end" />
                  </IonButton>
                  <hr />
                  <br />
                  App verzió: 1.0.5
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { ENV } from "../../config";
import { RefresherEventDetail } from "@ionic/core";
import querystring from "querystring";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useEventState } from "../../components/EventProvider";
import { useHistory, useParams } from "react-router-dom";
import StripeStatus from "../../components/StripeStatus";

const stripePromise = loadStripe(ENV.STRIPE_PK, { locale: "hu" });

type TEventPageParam = { event_id: string };
interface Props {}

const EventCheckoutPage: React.FC<Props> = (props: Props) => {
  const { event, getEvent, initCheckout, checkoutStatus } = useEventState();
  const query: any = querystring.parse(window.location.search.substr(1));
  const [status, setStatus] = useState<string>("");
  let { event_id } = useParams<TEventPageParam>();
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState("");

  const loadRecord = async (e?: CustomEvent<RefresherEventDetail>) => {
    try {
      await getEvent(event_id);
    } catch (err) {
      history.push("/events");
    } finally {
      if (e) {
        e.detail.complete();
      }
    }
  };

  useEffect(() => {
    if (event) {
      if (query.session_id) {
        checkoutStatus(query.session_id)
          .then((data: any) => {
            console.log(data);
            setStatus(data.data.session?.status!);
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        initCheckout()
          //.then((res: any) => res.json())
          .then((data: any) => {
            setClientSecret(data.data.clientSecret);
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [event]);

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    loadRecord();
  }, []);

  return (
    <>
      {status && <StripeStatus status={status} event={event} />}
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </>
  );
};

export default EventCheckoutPage;

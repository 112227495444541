import React, { useState } from "react";
import { TWishReceive } from "../../types/wish.type";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useWishState } from "../../components/WishProvider";
import { getErrorMessage } from "../../types/errorMessages";

import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";

interface Props {
  wishReceive: TWishReceive;
  onDismiss(wish_id?: string): void;
}

const schema = yup
  .object({
    event: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(50, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    received_from: yup
      .string()
      .required("Kérlek, töltsd ki ezt a mezőt!")
      .min(2, "Legalább ${min} betű szükséges")
      .max(50, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    remark: yup.string().max(500, "Maximum ${max} betű lehetséges"),
    qty: yup
      .number()
      .typeError("Kérlek, adj meg egy számot!")
      .min(0.001, "Kérlek, nullánál nagyobb számot adj meg.")
      .max(100000000000, "Kérlek, 100000000000-nál kisebb számot adj meg.")
      .nullable(true),
  })
  .required();

type IFormInputs = {
  event: string;
  received_from: string;
  qty: number;
  remark: string;
  [key: string]: any;
};

const ReceiveWishComponent: React.FC<Props> = ({ wishReceive, onDismiss }) => {
  const { updateWishReceive, createWishReceive } = useWishState();
  const [isError, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageHeader, setmessageHeader] = useState<string>("");
  const [present, dismiss] = useIonLoading();
  const [presentToast, dismissToast] = useIonToast();
  const title = !wishReceive.id
    ? "Ajándékozás rögzítése"
    : "Ajándékozás módosítása";
  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: wishReceive,
  });

  const onSubmit = async (data: IFormInputs) => {
    console.log({ wishReceive, data });
    try {
      await present(); 
      let res;
      if (wishReceive.id) {
        res = await updateWishReceive(
          wishReceive.wish_id,
          wishReceive.id!,
          data
        );
      } else {
        res = await createWishReceive(wishReceive.wish_id!, data);
      }
      onDismiss();
    } catch (err: any) {
      console.log(err);
      await dismiss(); 
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "MAX_WISH_NUMBER_REACHED":
            message =
              "Nem lehet új kívánságot létrehozni, mert elérted a maximális kívánságok számát!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setmessageHeader("Oppsz!");
        setMessage(message as string);
        setIserror(true);
      }
    } finally {
      await dismiss(); 
    }
  };

  return (
    <>
      <IonAlert
        isOpen={isError}
        onDidDismiss={() => setIserror(false)}
        cssClass={"error"}
        header={messageHeader}
        message={message}
        buttons={["Bezár"]}
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={() => onDismiss()} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">
                      Esemény, ahol megkaptad
                    </IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          onIonChange={field.onChange}
                          autocapitalize="on"
                        />
                      )}
                      control={control}
                      name="event"
                    />
                    {errors.event?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.event?.message}
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Kitől kaptad meg?</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          onIonChange={field.onChange}
                          autocapitalize="on"
                        />
                      )}
                      control={control}
                      name="received_from"
                    />
                    {errors.received_from?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.received_from?.message}
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      Mennyit kaptál belőle?
                    </IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          type="number"
                          {...field}
                          onIonChange={field.onChange}
                          autocapitalize="on"
                        />
                      )}
                      control={control}
                      name="qty"
                    />
                    {errors.qty?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.qty?.message}
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Megjegyzés</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          onIonChange={field.onChange}
                          autocapitalize="on"
                          multiple={true}
                        />
                      )}
                      control={control}
                      name="remark"
                    />
                    {errors.remark?.message && (
                      <div className="ion-text-start errorMessage">
                        {errors.remark?.message}
                      </div>
                    )}
                  </IonItem>
                </IonList>
                <IonRow className="ion-align-items-center">
                  <IonCol className="ion-text-end">
                    <IonButton type="submit" shape="round" color="primary">
                      Mentés
                      <IonIcon icon={checkmarkSharp} slot="end" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export default ReceiveWishComponent;

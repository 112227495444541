import React from "react";
import { TEvent } from "../types/event.type";
import HeaderComponent from "./HeaderComponent";
import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import { ENV } from "../config";

interface Props {
  status: string;
  event: TEvent;
}
const StripeStatus: React.FC<Props> = ({ event, status }) => {
  return (
    <>
      {event && (
        <>
          <HeaderComponent title={event?.name!} />
          <IonContent
            fullscreen={true}
            scrollEvents={true}
            onIonScroll={(e) => console.log(e.detail.scrollTop)}
          >
            <div
              style={{
                backgroundImage: `url(${ENV.CDN_URL}${event.image_lg})`,
              }}
              className="eventHeader"
            >
              <div className="eventName">{event.name}</div>
            </div>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" size-md="8" size-lg="6" size-xl="4">
                  {status === "complete" && (
                    <div
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "20px",
                        color: "green",
                      }}
                    >
                      Az esemény fizetése sikeresen megtörtént. Hívd meg
                      ismerőseidet!
                      <br /> <br />
                      <IonButton color="success" href={`/event/${event.id}`}>
                        Tovább
                      </IonButton>
                    </div>
                  )}
                  {status === "open" && (
                    <div
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "20px",
                        color: "red",
                      }}
                    >
                      AZ esemény fizetése sikertelen volt!
                      <br /> <br />
                      <IonButton
                        color="success"
                        href={`/event/${event.id}/participants`}
                      >
                        Tovább
                      </IonButton>
                    </div>
                  )}

                  <br />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </>
      )}
    </>
  );
};

export default StripeStatus;

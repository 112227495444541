import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonButton,
  IonCol,
  IonIcon,
  IonModal,
  IonRow,
  IonList,
  IonSearchbar,
  IonNote,
  useIonLoading,
  useIonToast,
  IonAlert,
  IonPage,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonImg,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useWishState } from "../../components/WishProvider";
import { TWish } from "../../types/wish.type";
import HeaderComponent from "../../components/HeaderComponent";
import { addSharp } from "ionicons/icons";
import { EditWishComponent } from "./EditWishComponent";
import { ENV } from "../../config";
import { useHistory, useRouteMatch } from "react-router-dom";

interface Props {}

const WishListPage: React.FC = (props: Props) => {
  const history = useHistory();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [editWish, setEditWish] = useState<TWish | null>(null);
  const [deleteWishId, setDeleteWishId] = useState<string>("");
  const [page, setPage] = useState(0);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const { wishes, wishCount, deleteWish, getWishes } = useWishState();
  let { path, url } = useRouteMatch();
  console.log({ history }, url, path);
  const handleDismiss = () => {
    setPage(0);
    handleSearch(searchText);
    setShowEditModal(false);
  };

  const handleEdit = (wish: TWish) => {
    setEditWish(wish);
    setShowEditModal(true);
  };

  const handleDelete = async () => {
    try {
      await present();
      await deleteWish(deleteWishId);
      setDeleteWishId("");
      setShowConfirm(false);
      await dismiss();
      presentToast("Törlés rendben!", 2000);
      setPage(0);
      // setTimeout(() => handleSearch(searchText), 500);
    } catch (e) {
      await dismiss();
      console.log(e);
    }
  };

  const loadWishes = async (ev: any) => {
    try {
      setPage(page + 1);
      const wishes = await getWishes({ name: searchText, page: page + 1 });
      ev.target.complete();
      if (!wishes.length) {
        setInfiniteDisabled(true);
      }
    } catch (error) {
      console.log(error);
      ev.target.complete();
    }
  };

  const handleSearch = async (term: string) => {
    setSearchText(term);
    setInfiniteDisabled(false);
    setPage(0);
    await getWishes({ name: term, page: 0 });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showConfirm}
        onDidDismiss={() => setShowConfirm(false)}
        header="Kívánság törlése"
        message="Biztos, hogy törölni szerentéd ezt a kívánságot? A műveletet nem lehet visszavonni!"
        buttons={[
          "Mégsem",
          {
            text: "Igen, törlöm.",
            handler: (d) => handleDelete(),
          },
        ]}
      />
      <IonModal
        isOpen={showEditModal}
        backdropDismiss={false}
        children={
          <EditWishComponent wish={editWish} onDismiss={handleDismiss} />
        }
      />

      <HeaderComponent title="Kívánságok" />
      <IonContent>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            <IonButton
              style={{ float: "right" }}
              shape="round"
              onClick={() => handleEdit({ id: "" } as TWish)}
              color="success"
            >
              Új kívánság
              <IonIcon icon={addSharp} slot="start" />
            </IonButton>
            <IonSearchbar
              animated
              placeholder="Keresés"
              value={searchText}
              onIonChange={(e) => {
                handleSearch(e.detail.value!);
              }}
              debounce={1000}
            ></IonSearchbar>
            <div className="hint">
              Ez itt a kívánságaid listája. A kívánságaidat hozzáadhatod
              eseményhez, hogy azok résztvevői lássák azt. A jobb oldalon
              láthatod, hogy a kívánságod hány eseményhez van hozzárendelve.
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol
            size="12"
            size-md="10"
            size-lg="8"
            size-xl="6"
            className="ion-text-center"
          >
            <IonNote color="tertiary">
              Összesen {wishCount} kívánságod van.
            </IonNote>
            <IonList>
              {wishes.map((w: TWish) => (
                <IonItem
                  detail
                  onClick={() => history.push(`/wish/${w.id}`)}
                  key={w.id}
                >
                  <IonThumbnail slot="start">
                    <IonImg src={ENV.CDN_URL + w.image_xs} />
                  </IonThumbnail>
                  <IonLabel>{w.name}</IonLabel>
                  <IonBadge>{w.EventWish ? w.EventWish.length : 0}</IonBadge>
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
        <IonInfiniteScroll
          onIonInfinite={loadWishes}
          threshold="50px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Adatok letöltése..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default WishListPage;

import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "./AuthProvider";
import { ENV } from "../config";
import { IonBadge, IonButton, IonChip, IonIcon, IonModal } from "@ionic/react";
import { notificationsOutline } from "ionicons/icons";
import useWebSocket, { ReadyState } from "react-use-websocket";
import ShowMessage from "./ShowMessage";
import { TEvent, TWSMessage } from "../types/event.type";
import { useEventState } from "./EventProvider";
import { useSocketState } from "./SocketProvider";

interface Props {}

export const NotificationComponent: React.FC = (props: Props) => {
  const { profile } = useAuthState();
  const { notifications, clearNotifications } = useSocketState();
  const [showModal, setShowModal] = useState(false);

  if (!profile) {
    return <></>;
  } else {
    return (
      <>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          backdropDismiss={false}
          children={
            <ShowMessage
              messages={notifications}
              deleteMessages={() => clearNotifications()}
              onDismiss={() => {
                setShowModal(false);
              }}
            />
          }
        />
        {!profile.is_limited && !!notifications.length && (
          <IonChip onClick={() => setShowModal(true)}>
            <IonIcon icon={notificationsOutline} />
            <IonBadge color="primary">
              {notifications.length > 9 ? "9+" : notifications.length}
            </IonBadge>
          </IonChip>
        )}
      </>
    );
  }
};

import { TProfile } from "./profile.type";
import { TUser } from "./user.type";
import { TWish } from "./wish.type";

export type TEventParticipant = {
  accepted_on?: string;
  account_id: string;
  can_engage_wish: boolean;
  can_post_wish: boolean;
  created_at: string;
  event_id: string;
  id: number;
  invitation_resent_at: string | null;
  is_accepted: boolean;
  is_admin: boolean;
  notification_on_comments: boolean;
  notification_on_engagements: boolean;
  notification_on_wishes: boolean;
  number_of_resend: number;
  profile: TProfile;
  profile_id: string;
  updated_at: string;
  user: TUser;
  number_of_wishes?: number;
  planned_budget_currency?: string;
  planned_budget?: number;
  Event?: TEvent;
  [key: string]: any;
};

export type TEvent = {
  id?: string;
  category_id?: number;
  template_id?: number;
  plan_id?: number;
  price?: number;
  currency?: string;
  is_paid?: boolean;
  stripe_invoice_id?: string;
  max_participants?: number;
  max_wishes?: number;
  comments_allowed?: boolean;
  name?: string;
  description?: string;
  open_on?: string;
  take_place_on?: string;
  billing_account_id?: string;
  is_active?: boolean;
  location?: string;
  address_1?: string;
  address_2?: string;
  zip?: string;
  city?: string;
  country?: string;
  image_xs?: string;
  image_lg?: string;
  created_at?: string;
  myProfile?: TEventParticipant;
  participants?: Array<TEventParticipant>;
  is_past?: boolean;
};

export type TPlan = {
  id?: number;
  name?: string;
  description?: string;
  price?: number;
  gross_price?: number;
  currency?: string;
  currency_obj?: {
    symbol: string;
    code: string;
    position: string;
  };
  max_participants?: number;
  max_wishes?: number;
  comments_allowed?: boolean;
};

export type TTemplate = {
  id?: number;
  name?: string;
  className?: string;
};

export type TEventSettings = {
  MAX_MONTHS?: number;
  VAT?: number;
  MAX_FREE_OPEN_EVENTS?: number;
  templates?: Array<TTemplate>;
  [key: string]: any;
};

export type TEventWishFilterParam = {
  exclude_user?: boolean;
  engaged_by?: string;
  keyword?: string;
  is_purchased?: boolean;
  profile_id?: string;
  page?: number;
};
export type TEventWishEngagementFilterParam = {
  engaged_by?: string;
  is_purchased?: boolean;
  page?: number;
};
export type TEventWishEngagement = {
  id?: number;
  event_id?: string;
  wish_id?: string;
  event_wish_id?: number;
  Wish?: TWish;
  engaged_by?: string;
  engaged_on?: string;
  is_purchased?: boolean;
  purchased_on?: string;
  price: number;
  currency?: string;
  created_at?: string;
  updated_at?: string;
  EngagedBy?: TProfile;
};
export type TEventWish = {
  id?: number;
  event_id?: string;
  wish_id?: string;
  created_at?: string;
  updated_at?: string;
  Wish?: TWish;
  Event?: TEvent;
  Engagements?: Array<TEventWishEngagement>;
};
export type TEventWishComment = {
  id?: number;
  text?: string;
  created_by?: string;
  created?: TProfile;
  remark_type: string;
  answer_to?: number;
  event_wish_id?: number;
  created_at: string;
  updated_at: string;
  is_deleted?: boolean;
};
export type TCurrency = {
  code: string;
  name: string;
  symbol: string;
  rate: number;
  symbol_position: string;
};
export const CURRENCIES: Array<TCurrency> = [
  {
    code: "HUF",
    name: "Forint",
    symbol: "Ft",
    rate: 1,
    symbol_position: "r",
  },
  {
    code: "EUR",
    name: "Euro",
    symbol: "€",
    rate: 350,
    symbol_position: "r",
  },
  {
    code: "USD",
    name: "USA dollár",
    symbol: "$",
    rate: 300,
    symbol_position: "l",
  },
  {
    code: "GBP",
    name: "Angol font",
    symbol: "£",
    rate: 425,
    symbol_position: "l",
  },
];

export type TEventLog = {
  id?: number;
  event_id: string;
  entry: string;
  url: string;
  profile_id: string;
  no_notification_user: string;
  created_at?: string;
  updated_at?: string;
};

export type TEventLogFilterParams = {
  page?: number;
};

export type TWSMessage = {
  event_id: string;
  entry: string;
  url: string;
  profile_id: string;
  no_notification_user: string;
};

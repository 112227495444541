import { IonIcon, IonImg } from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import React from "react";
import { ENV } from "../config";

interface Props {
  onDismiss(): void;
  imageUrl: string;
}
const ImageViewer: React.FC<Props> = ({ onDismiss, imageUrl }) => {
  return (
    <div className="imageViewer">
      <div className="close">
        <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
      </div>
      <div className="imgWarpper">
        <IonImg src={imageUrl} />
      </div>
    </div>
  );
};

export default ImageViewer;

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { ECDH } from "crypto";
import { closeSharp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import { TEventLog, TWSMessage } from "../../types/event.type";

type Props = RouteComponentProps & {
  onDismiss(): void;
};

const EventLog: React.FC<Props> = ({ onDismiss, history }) => {
  const { event, getEventLog } = useEventState();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [messages, setMessages] = useState<Array<TEventLog>>([]);
  const [page, setPage] = useState(0);
  const handleDismiss = () => {
    setPage(0);
    onDismiss();
  };

  const loadLog = async (ev: any) => {
    try {
      setPage(page + 1);
      const res = await getEventLog(event.id, { page: page + 1 });
      ev.target.complete();
      console.log(res);
      if (res.rows.length == 0) {
        setInfiniteDisabled(true);
      } else {
        setMessages((oldValue: Array<TEventLog>) => [...oldValue, ...res.rows]);
      }
    } catch (error) {
      console.log(error);
      ev.target.complete();
    }
  };

  useEffect(() => {
    getEventLog(event.id).then((res: any) => {
      setMessages(res.rows);
    });
  }, []);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Esemény legutóbbi tevékenységei</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={handleDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center">
        <IonList>
          {!!messages.length &&
            messages.map((message: TEventLog, i: number) => (
              <IonItem
                key={i}
                onClick={() => {
                  history.replace(message.url.replace(ENV.APP_URL, ""));
                  onDismiss();
                }}
              >
                <IonLabel className="ion-text-wrap">
                  <b>{moment(message.created_at).format("MMM D, HH:mm")}</b>:{" "}
                  {message.entry!}
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={loadLog}
          threshold="50px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Adatok letöltése..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </>
  );
};

export default withRouter(EventLog);

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  useIonToast,
  useIonLoading,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import React, { useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import OtpInput from "react-otp-input-rc-17";

interface Props {
  onDismiss(): void;
}

export const EditPincodePage: React.FC<Props> = ({ onDismiss }) => {
  const { user, update, saveJWT, loadCurrentUser, getProfiles } =
    useAuthState();
  const [allowEdit, setAllowEdit] = useState<boolean>(
    user?.pin?.length! ? false : true
  );
  const [present, dismiss] = useIonLoading();

  const [error, setError] = useState<string>("");
  const [pin1, setPin1] = useState<string>("");
  const [pin2, setPin2] = useState<string>("");
  const [currentPin, seCurrentPin] = useState<string>("");
  const [presentToast] = useIonToast();

  const requestCurrentPincode = () => (
    <>
      <h2>Kérlek, add meg a jelenlegi PIN kódot</h2>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <OtpInput
            containerStyle="pinInput-container"
            inputStyle="pinInput"
            numInputs={4}
            value={currentPin}
            isInputSecure={true}
            onChange={(code: string) => {
              seCurrentPin(code);
              setError("");
              if (code.length == 4) {
                if (code == user.pin) {
                  setAllowEdit(true);
                } else {
                  setError("Hibás PIN!");
                }
              }
            }}
          />{" "}
        </IonRow>
      </IonGrid>
      <div className="errorMessage"> {error}</div>
    </>
  );
  const enterNewPincode = () => (
    <>
      <h2>Kérlek, add meg a kívánt PIN kódot</h2>
      <p>
        Ha van korlátozott profilod (pl. gyermek részére) akkor csak a PIN kód
        megadása után lehetséges profilt váltani
      </p>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <OtpInput
            containerStyle="pinInput-container"
            inputStyle="pinInput"
            numInputs={4}
            value={pin1}
            isInputSecure={true}
            onChange={(code: string) => {
              setPin1(code);
              setError("");
            }}
          />
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <h3>Most a biztonság kedvéért ismételd meg:</h3>
          <OtpInput
            containerStyle="pinInput-container"
            inputStyle="pinInput"
            numInputs={4}
            value={pin2}
            isInputSecure={true}
            onChange={async (code: string) => {
              setPin2(code);
              setError("");
              if (pin1.length == 4 && code.length == 4 && pin1 === code) {
                try {
                  await present();
                  const result = await update({ pin: pin1 });
                  await dismiss(); 
                  if (result.status == 200) {
                    setPin1("");
                    setPin2("");
                    await saveJWT(result.data?.token);
                    await loadCurrentUser();
                    await getProfiles();
                    presentToast("PIN kód beállítva!", 2000);
                    onDismiss();
                  }
                } catch (e) {}
              } else {
                setError("A megadott PIN kódok nem egyeznek!");
              }
            }}
          />
        </IonRow>
      </IonGrid>
      <div className="errorMessage"> {error}</div>
    </>
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>PIN Kód megadása</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center">
        {!allowEdit ? requestCurrentPincode() : enterNewPincode()}
      </IonContent>
    </>
  );
};

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import NumberFormat from "react-number-format";
import { TPlan } from "../types/event.type";
import { useEventState } from "./EventProvider";

interface Props {
  plan: TPlan;
}

const PlanPriceDetail: React.FC<Props> = ({ plan }) => {
  const { settings } = useEventState();
  return (
    <IonGrid>
      {/* <IonRow>
        <IonCol className="ion-text-start">Nettó ár:</IonCol>
        <IonCol className="ion-text-end">
          <NumberFormat
            value={Math.round(plan.price!)}
            thousandSeparator="&nbsp;"
            decimalSeparator=","
            suffix={" " + plan.currency}
            displayType="text"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-start">Áfa ({settings.VAT * 100}%):</IonCol>
        <IonCol className="ion-text-end">
          <NumberFormat
            value={Math.round(plan.price! * settings.VAT)}
            thousandSeparator="&nbsp;"
            decimalSeparator=","
            suffix={" " + plan.currency}
            displayType="text"
          />
        </IonCol>
      </IonRow> */}
      <IonRow>
        <IonCol className="ion-text-end">
          <b> Fizetendő:</b>
        </IonCol>
        <IonCol className="ion-text-start">
          <b>
            <NumberFormat
              value={Math.round(plan.price! * (1 + settings.VAT))}
              thousandSeparator="&nbsp;"
              decimalSeparator=","
              suffix={" " + plan.currency}
              displayType="text"
            />
          </b>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PlanPriceDetail;

import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonLabel,
  IonActionSheet,
  useIonToast,
} from "@ionic/react";
import {
  createSharp,
  lockClosedSharp,
  trashSharp,
  cameraSharp,
  flashSharp,
  closeSharp,
} from "ionicons/icons";
import React, { useState } from "react";
import { ENV } from "../../config";
import { TProfile, GenderMap } from "../../types/profile.type";
import { Icon } from "@mui/material";
import { useAuthState } from "../../components/AuthProvider";
interface Props {
  profile: TProfile;
  isActive?: Boolean;
  onEditClick(profile_id: String): void;
  onDeleteClick(profile_id: String): void;
  onPhotoClick(profile_id: String): void;
}

export const ProfileComponent: React.FC<Props> = ({
  profile,
  isActive = true,
  onEditClick,
  onDeleteClick,
  onPhotoClick,
}) => {
  const [present, dismiss] = useIonToast();
  const [showAS, setShowAS] = useState<boolean>(false);
  const image = <img src={ENV.CDN_URL + profile.image_xs} />;
  const buttons = [];

  buttons.push({
    text: "Módosít",
    icon: createSharp,
    handler: () => onEditClick(profile?.id!),
  });
  buttons.push({
    text: "Profilkép feltöltése",
    icon: cameraSharp,
    handler: () => onPhotoClick(profile?.id!),
  });
  if (!isActive)
    buttons.push({
      text: "Törlés",

      icon: trashSharp,
      cssClass: "danger",
      handler: () => {
        onDeleteClick(profile?.id!);
      },
    });
  buttons.push({ text: "Mégsem", role: "cancel", icon: closeSharp });

  return (
    <>
      <IonActionSheet
        isOpen={showAS}
        onDidDismiss={() => setShowAS(false)}
        buttons={buttons}
      ></IonActionSheet>

      <IonItem onClick={() => setShowAS(true)}>
        <IonAvatar slot="start">{image}</IonAvatar>

        <IonLabel>
          <h2>{profile.name}</h2>
          <p>
            {GenderMap[profile?.gender!]} | Életkor: {profile.age}{" "}
            {profile.is_limited && (
              <>
                | <Icon slot="end">child_care</Icon> Korlátozott profil
              </>
            )}
          </p>
        </IonLabel>
      </IonItem>
    </>
  );
};

import {
  IonRow,
  IonCol,
  useIonLoading,
  IonAlert,
  IonGrid,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonDatetime,
  IonList,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonContent,
} from "@ionic/react";
import React, { useState } from "react";
import { TProfile, GenderMap } from "../../types/profile.type";
import { useForm, Controller } from "react-hook-form";
import { useAuthState } from "../../components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import { getErrorMessage } from "../../types/errorMessages";
import { monthNames } from "../../types/date";

const schema = yup
  .object({
    name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    date_of_birth: yup.string().nullable().notRequired(),
    date_of_nameday: yup.string().nullable().notRequired(),
    gender: yup.string().nullable().notRequired(),
    is_limited: yup.boolean(),
  })
  .required();

type IFormInputs = {
  name: string;
  date_of_birth: string;
  date_of_nameday: string;
  gender: string;
  is_limited: boolean;
  [key: string]: any;
};

interface Props {
  editProfileId: string;
  onDismiss(): void;
}

export const EditProfilePage: React.FC<Props> = ({
  editProfileId,
  onDismiss,
}) => {
  const { user, profiles, getProfiles, createProfile, updateProfile } =
    useAuthState();
  const [present, dismiss] = useIonLoading();
  const [isError, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageHeader, setmessageHeader] = useState<string>("");

  const profile: TProfile =
    editProfileId === "new"
      ? {
          name: profiles.length ? "" : user.first_name,
        }
      : profiles.find((e: TProfile): boolean => e.id === editProfileId);
  // if (!profile) {
  //   onDismiss();
  // }

  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: profile,
  });
  const showCloseButton = true;
  const title =
    editProfileId === "new" ? "Új profil létrehozása" : "Profil módosítása";
  const subtitle = profiles.length
    ? ""
    : "Üdvözlünk az EZKIVI-n. Pár adatot adj meg madadról:";
  const hint = profiles.length
    ? ""
    : "Tipp: több profilt is létrehozhatsz, például a gyerekeid számára a Beállítások menüpontban";

  const onSubmit = async (data: IFormInputs) => {
    try {
      //await present();

      let res;
      if (editProfileId === "new") {
        res = await createProfile(data);
      } else {
        res = await updateProfile(editProfileId, data);
      }
      await getProfiles();
      onDismiss();
    } catch (err: any) {
      console.log(err.response);
      //await dismiss();
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "USER_EXISTS":
            message = "Ez az email cím már regisztrálva van!";
            break;
          case "MAX_PROFILE_NUMBER_REACHED":
            message = "Nem hozhatsz létre több profilt!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setError("name", {
          type: "manual",
          message: message,
        });
      }
    }
  };
  return (
    <>
      {profile && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              {showCloseButton && (
                <IonButtons slot="end">
                  <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
                </IonButtons>
              )}
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonGrid fixed={true}>
                <IonRow className="ion-justify-content-center">
                  <IonCol>
                    <h5 className="ion-text-center">{subtitle}</h5>
                    <IonList>
                      <IonItem>
                        <IonLabel position="stacked">Profil név</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput
                              placeholder="Becenév"
                              {...field}
                              onIonChange={field.onChange}
                            />
                          )}
                          control={control}
                          name="name"
                        />
                        {errors.name?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.name?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>Születési dátum</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonDatetime
                              doneText="Rendben"
                              cancelText="Mégsem"
                              displayFormat="YYYY-MM-DD"
                              {...field}
                              onIonChange={field.onChange}
                            />
                          )}
                          control={control}
                          name="date_of_birth"
                        />
                        {errors.date_of_birth?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.date_of_birth?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>Névnap</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonDatetime
                              doneText="Rendben"
                              cancelText="Mégsem"
                              displayFormat="MMMM DD"
                              monthNames={monthNames}
                              {...field}
                              onIonChange={field.onChange}
                            />
                          )}
                          control={control}
                          name="date_of_nameday"
                        />
                        {errors.date_of_nameday?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.date_of_nameday?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>Nem</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonSelect
                              interface="alert"
                              {...field}
                              onIonChange={field.onChange}
                              placeholder="Válassz egyet"
                            >
                              {Object.keys(GenderMap).map((k: string) => (
                                <IonSelectOption key={k} value={k}>
                                  {GenderMap[k]}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                          control={control}
                          name="gender"
                        />
                        {errors.gender?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.gender?.message}
                          </div>
                        )}
                      </IonItem>
                      {profiles.length > 0 && (
                        <IonItem>
                          <IonLabel>Korlátozott profil gyerekeknek</IonLabel>
                          <Controller
                            render={({ field }) => (
                              <IonToggle
                                checked={field.value}
                                onIonChange={(e) =>
                                  field.onChange(e.detail.checked)
                                }
                              ></IonToggle>
                            )}
                            control={control}
                            name="is_limited"
                          />
                        </IonItem>
                      )}
                    </IonList>

                    <IonRow className="ion-align-items-center">
                      <IonCol className="ion-text-end">
                        <IonButton type="submit" shape="round" color="primary">
                          Mentés
                          <IonIcon icon={checkmarkSharp} slot="end" />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <div className="ion-text-center ion-padding">{hint}</div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonContent>
        </>
      )}
      {!profile && <>lofasz</>}
    </>
  );
};

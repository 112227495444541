import { AppRouter } from "./AppRouter";
import { IonApp } from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/settings.scss";

import { AuthProvider } from "./components/AuthProvider";
import { WishProvider } from "./components/WishProvider";
import { BillingAccountProvider } from "./components/BillingAccoutProvider";
import { EventProvider } from "./components/EventProvider";
import { SocketProvider } from "./components/SocketProvider";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <WishProvider>
        <BillingAccountProvider>
          <EventProvider>
            <SocketProvider>
              <IonApp>
                <AppRouter />
              </IonApp>
            </SocketProvider>
          </EventProvider>
        </BillingAccountProvider>
      </WishProvider>
    </AuthProvider>
  );
};

export default App;

import React from "react";
import { TBillingAccount } from "../types/billingAccount.type";
import { getCountryName } from "../util";

interface Props {
  billingAccount: TBillingAccount;
}

const BillingAccountDetails: React.FC<Props> = ({ billingAccount }) => {
  return (
    <>
      <h4>{billingAccount.billing_name}</h4>
      <p>
        {billingAccount.zip} {billingAccount.city}, {billingAccount.address_1}{" "}
        {billingAccount.address_2}, {getCountryName(billingAccount.country!)}
        {billingAccount.tax_number ? (
          <>
            <br />
            Adószám: {billingAccount.tax_number}
          </>
        ) : (
          ""
        )}
      </p>
    </>
  );
};

export default BillingAccountDetails;

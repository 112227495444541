import moment from "moment";
import countries from "./types/countries.json";

export const getCountryName = (alpha2: string): string => {
  const obj = countries.find((r: any) => r.alpha2 === alpha2);
  if (obj) {
    return obj.name;
  } else {
    return alpha2;
  }
};

export const convertDate = (date: string, time: string): string => {
  if (date && time) {
    const d = date.split("T")[0];
    let t;
    if (time.indexOf("T") > -1) {
      t = time.split("T")[1].substring(0, 5);
    } else {
      t = time;
    }
    return moment(d + " " + t)
      .utc()
      .format("YYYY-MM-DD HH:mm");
  } else {
    return "";
  }
};

import {
  IonRow,
  IonCol,
  useIonLoading,
  IonGrid,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonList,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonContent,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";
import { TBillingAccount } from "../../types/billingAccount.type";
import { useForm, Controller } from "react-hook-form";
import { useAuthState } from "../../components/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import { getErrorMessage } from "../../types/errorMessages";
import { useBillingAccountState } from "../../components/BillingAccoutProvider";
import countries from "../../types/countries.json";

const schema = yup
  .object({
    billing_name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    phone: yup.string().nullable().notRequired(),
    email: yup
      .string()
      .email("Nem megfelelő email cím formátum!")
      .required("Kérlek, add meg az email címed!"),
    tax_number: yup.string().nullable().notRequired(),
    address_1: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    address_2: yup.string().nullable().notRequired(),
    zip: yup
      .string()
      .min(4, "Legalább ${min} betű szükséges")
      .max(4, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    city: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    country: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(2, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    is_limited: yup.boolean(),
  })
  .required();

type IFormInputs = {
  billing_name: string;
  phone: string;
  email: string;
  tax_number: string;
  address_1: string;
  address_2: string;
  zip: string;
  city: string;
  country: string;
  [key: string]: any;
};

interface Props {
  editRecordId: string;
  onDismiss(): void;
}

const EditBillingAccountPage: React.FC<Props> = ({
  editRecordId,
  onDismiss,
}) => {
  const [present, dismiss] = useIonLoading();
  const { user } = useAuthState();
  const { updateRecord, createRecord, billingAccounts, getRecords } =
    useBillingAccountState();

  const record: TBillingAccount | undefined =
    editRecordId === "new"
      ? {
          billing_name: user.last_name + " " + user.first_name,
          email: user.email,
        }
      : billingAccounts.find((e: TBillingAccount) => e.id === editRecordId);
  const title =
    editRecordId === "new" ? "Új számlázási cím" : "Számlázási cím módosítása";

  let {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: record,
  });

  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();
      let res;
      if (editRecordId === "new") {
        res = await createRecord(data);
      } else {
        res = await updateRecord(editRecordId, data);
      }
      await dismiss(); 
      await getRecords();
      onDismiss();
    } catch (err: any) {
      await dismiss(); 
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "USER_EXISTS":
            message = "Ez az email cím már regisztrálva van!";
            break;
          case "MAX_PROFILE_NUMBER_REACHED":
            message = "Nem hozhatsz létre több profilt!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setError("name", {
          type: "manual",
          message: message,
        });
      }
    }
  };

  return (
    <>
      {record && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="end">
                <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonGrid fixed={true}>
                <IonRow className="ion-justify-content-center">
                  <IonCol>
                    <IonList>
                      <IonItem>
                        <IonLabel position="stacked">Számlázási név</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="billing_name"
                        />
                        {errors.billing_name?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.billing_name?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Telefonszám</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="phone"
                        />
                        {errors.phone?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.phone?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">E-mail cím</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput
                              type="email"
                              {...field}
                              onIonChange={field.onChange}
                            />
                          )}
                          control={control}
                          name="email"
                        />
                        {errors.email?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.email?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Adószám</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="tax_number"
                        />
                        {errors.tax_number?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.tax_number?.message}
                          </div>
                        )}
                      </IonItem>
                      <IonItemDivider>
                        <IonLabel>Számlázási cím</IonLabel>
                      </IonItemDivider>
                      <IonItem>
                        <IonLabel position="stacked">Ország</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonSelect
                              okText="Rendben"
                              cancelText="Mégsem"
                              {...field}
                              onIonChange={field.onChange}
                              placeholder="Válassz egyet"
                            >
                              {countries.map((k: any) => (
                                <IonSelectOption
                                  key={k.alpha2}
                                  value={k.alpha2}
                                >
                                  {k.name}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          )}
                          control={control}
                          name="country"
                        />
                        {errors.country?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.country?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Irányítószám</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="zip"
                        />
                        {errors.zip?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.zip?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Település</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="city"
                        />
                        {errors.city?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.city?.message}
                          </div>
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Utca, házszám</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="address_1"
                        />
                        {errors.address_1?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.address_1?.message}
                          </div>
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">Emelet, ajtó</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonInput {...field} onIonChange={field.onChange} />
                          )}
                          control={control}
                          name="address_2"
                        />
                        {errors.address_2?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.address_2?.message}
                          </div>
                        )}
                      </IonItem>
                    </IonList>
                    <IonRow className="ion-align-items-center">
                      <IonCol className="ion-text-end">
                        <IonButton type="submit" shape="round" color="primary">
                          Mentés
                          <IonIcon icon={checkmarkSharp} slot="end" />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonContent>
        </>
      )}
    </>
  );
};

export default EditBillingAccountPage;

import { present } from "@ionic/core/dist/types/utils/overlays";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonThumbnail,
  useIonLoading,
  useIonToast,
  IonModal,
} from "@ionic/react";
import { addSharp, closeOutline, constructOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import { ENV } from "../../config";
import { TEventWish } from "../../types/event.type";
import { TWish } from "../../types/wish.type";
import { EditWishComponent } from "../wish/EditWishComponent";
import WishPicker from "./WishPicker";

interface Props {}

const MyWishPage: React.FC = (props: Props) => {
  const { getWishes, deleteWish, delegateWish, getEvent, event } =
    useEventState();
  const { profile } = useAuthState();
  const [eventWishes, setEventWishes] = useState<Array<TEventWish>>([]);
  const [eventWishCount, setEventWishCount] = useState(0);
  const [showWishPicker, setShowWishPicker] = useState(false);
  const [showNewWishPicker, setShowNewWishPicker] = useState(false);
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const loadWishes = async () => {
    try {
      const res = await getWishes({ profile_id: profile.id });
      setEventWishes(res.data.rows);
      setEventWishCount(res.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDismiss = (wish_id: string) => {
    setShowNewWishPicker(false);
    loadWishes();
    //delegateWish(wish_id).then(() => loadWishes());
  };

  const handleDelete = async (event_wish_id: number) => {
    try {
      const res = await deleteWish(event_wish_id);
      await loadWishes();
      await getEvent(event.id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadWishes();
  }, [profile]);

  return (
    <>
      <IonModal
        isOpen={showWishPicker}
        backdropDismiss={false}
        children={
          <WishPicker
            eventWishes={eventWishes}
            onDismiss={(openNewWish: boolean) => {
              setShowWishPicker(false);
              if (openNewWish) {
                setShowNewWishPicker(true);
              }
              loadWishes();
            }}
          />
        }
      />
      <IonModal
        isOpen={showNewWishPicker}
        backdropDismiss={false}
        children={
          <EditWishComponent
            wish={{ id: "" } as TWish}
            onDismiss={handleDismiss}
            assignToEvent={event.id}
          />
        }
      />
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            {!event.is_past && (
              <IonButton
                style={{ float: "right" }}
                shape="round"
                color="success"
                onClick={() => setShowWishPicker(true)}
              >
                Hozzáadás
                <IonIcon icon={addSharp} slot="start" />
              </IonButton>
            )}
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol
            size="12"
            size-md="10"
            size-lg="8"
            size-xl="6"
            className="ion-text-center"
          >
            <IonNote color="tertiary">
              Összesen {eventWishCount} kívánságod van.
            </IonNote>
            <IonList>
              {eventWishes.map((w: TEventWish) => (
                <IonItem key={w.id}>
                  <IonThumbnail slot="start">
                    <IonImg src={ENV.CDN_URL + w.Wish?.image_xs!} />
                  </IonThumbnail>
                  <IonLabel>{w.Wish?.name}</IonLabel>
                  {!event.is_past && (
                    <IonButton
                      fill="clear"
                      color="danger"
                      slot="end"
                      onClick={() => handleDelete(w?.id!)}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  )}
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default MyWishPage;

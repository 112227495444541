import { key } from "ionicons/icons";

export type TStyle = {
  className: string;
  color: string;
};

export const STYLES: Array<TStyle> = [
  {
    className: "style0",
    color: "#006ba6",
  },
  {
    className: "style1",
    color: "#cb997e",
  },
  {
    className: "style2",
    color: "#001219",
  },
  {
    className: "style3",
    color: "#fec5bb",
  },
  {
    className: "style4",
    color: "#f72585",
  },
  {
    className: "style5",
    color: "#264653",
  },
  {
    className: "style6",
    color: "#a1cca5",
  },
  {
    className: "style7",
    color: "#ff6d00",
  },
  {
    className: "style8",
    color: "#d90429",
  },
];

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonButtons,
  IonIcon,
  IonGrid,
  IonRow,
  useIonToast,
  useIonLoading,
  IonNote,
  IonButton,
  IonModal,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { TProfile } from "../types/profile.type";
import { useAuthState } from "./AuthProvider";
import { ENV } from "../config";
import { closeSharp } from "ionicons/icons";
import OtpInput from "react-otp-input-rc-17";
import { Icon } from "@mui/material";
import { EditPincodePage } from "../pages/settings/EditPincodePage";

interface Props {}

export const ProfileSelectorModal: React.FC = (props: Props) => {
  const {
    profiles,
    setShowProfileChangeModal,
    setCurrentProfile,
    profile,
    user,
  } = useAuthState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  let showSettings = true;
  if (profile && profile.is_limited && user.pin?.length) {
    showSettings = false;
  }
  const [canChangeProfile, setCanChangeProfile] =
    useState<boolean>(showSettings);
  const [pin, setPin] = useState<string>("");
  const [error, setError] = useState<string>("");
  if (user.pin?.length === 0) {
    setCanChangeProfile(true);
  }

  const [showPinChange, setShowPinChange] = useState<boolean>(false);

  const ShowPinPad = () => (
    <div className="ion-padding">
      <h2>Kérlek, add meg a PIN kódot</h2>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <OtpInput
            containerStyle="pinInput-container"
            inputStyle="pinInput"
            numInputs={4}
            value={pin}
            isInputSecure={true}
            onChange={(code: string) => {
              setPin(code);
              setError("");
              if (code.length == 4) {
                if (code == user.pin) {
                  setCanChangeProfile(true);
                } else {
                  setError("Hibás PIN!");
                }
              }
            }}
          />{" "}
        </IonRow>
      </IonGrid>
      <div className="errorMessage ion-text-center"> {error}</div>
    </div>
  );

  useEffect(() => {
    console.log("user changed");
  }, [user]);

  const ShowPinNotification = () => {
    const hasLimited = profiles.some((p: TProfile) => p.is_limited);
    if (hasLimited && !profile.is_limited && !user.pin) {
      return (
        <div className="ion-padding ion-text-center">
          <IonNote color="danger">
            Úgy néz ki, hogy van egy vagy több korlátozott profilod. Javasolt
            PIN kód beállítása!
          </IonNote>
          <IonButton
            size="small"
            fill="clear"
            onClick={() => {
              setShowPinChange(true);
            }}
          >
            PIN beállítása
            <Icon slot="end">chevron_right</Icon>
          </IonButton>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const ProfileSelector = () => (
    <>
      <IonModal
        isOpen={showPinChange}
        onDidDismiss={() => setShowPinChange(false)}
        backdropDismiss={false}
        children={<EditPincodePage onDismiss={() => setShowPinChange(false)} />}
      />
      {ShowPinNotification()}
      <IonList>
        {profiles.map((p: TProfile) => {
          let image = <img src={ENV.CDN_URL + p.image_xs} />;

          return (
            <IonItem
              key={p.id}
              onClick={async () => {
                try {
                  await present();
                  await setCurrentProfile(p.id);
                  // await getRecords();
                  // await getWishes();
                  await dismiss(); 
                  presentToast("Profilváltás rendben", 3000);
                  setShowProfileChangeModal(false);
                } catch (err: any) {
                  console.log(err);
                  presentToast("Hiba történt!", 3000);
                  await dismiss(); 
                }
              }}
            >
              <IonAvatar slot="start">{image}</IonAvatar>
              {p.is_limited && <Icon slot="end">child_care</Icon>}
              <IonLabel className="ion-padding">{p.name}</IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Válassz egy profilt</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={closeSharp}
              slot="end"
              onClick={() => setShowProfileChangeModal(false)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {canChangeProfile ? ProfileSelector() : ShowPinPad()}
      </IonContent>
    </>
  );
};

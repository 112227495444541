import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import { TEventParticipant } from "../../types/event.type";

interface Props {}

const PendingInvitations: React.FC = (props: Props) => {

  const {
    events,
    getPendingInvitations,
    acceptInvitation,
    denyInvitation,
    getCurrentEvents,
    invitations,
  } = useEventState();

  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const [deleteParticipantId, setDeleteParticipantId] = useState(0);

  const handleAccept = async (event_id: string) => {
    try {
      await present();
      await acceptInvitation(event_id);
      await dismiss();
      await getPendingInvitations();
      await getCurrentEvents();
      presentToast({
        message: "Rendben, mostantól tagja vagy az eseménynek!",
        duration: 4000,
        color: "dark",
      });
    } catch (error) {
      console.log(error);
      await dismiss();
      presentToast({
        message: "Hiba történt!",
        duration: 4000,
        color: "danger",
      });
    }
  };
  const handleDeny = async () => {
    try {
      await present();
      const res = await denyInvitation(deleteParticipantId);
      await getPendingInvitations();
      await dismiss();
      presentToast({
        message: "Rendben, töröltük a meghívást.",
        duration: 4000,
        color: "dark",
      });
      setDeleteParticipantId(0);
    } catch (error) {
      console.log(error);
      await dismiss();
      presentToast({
        message: "Hiba történt!",
        duration: 4000,
        color: "danger",
      });
    }
  };
  useEffect(() => {
    getPendingInvitations().then((data: any) => console.log(data));
  }, []);
  return (
    <>
      {!!invitations.length && (
        <IonList>
          <IonAlert
            isOpen={!!deleteParticipantId}
            onDidDismiss={() => setDeleteParticipantId(0)}
            header="Megerősítás"
            message="Biztos, hogy törölni szerentéd a meghívást? Lehet, hogy aki meghívott szomorú lesz!"
            buttons={[
              "Mégsem",
              {
                text: "Igen, törlöm a profilt.",
                handler: (d) => handleDeny(),
              },
            ]}
          />

          <IonListHeader>
            <IonLabel>Függőben lévő meghívások:</IonLabel>
          </IonListHeader>
          {invitations.map((i: TEventParticipant) => (
            <IonCard key={i.id}>
              <IonCardContent>
                <IonRow>
                  <IonCol style={{ flexGrow: 3 }}>
                    <h1>{i?.Event?.name!}</h1>
                    Helye: {i?.Event?.location}
                    <br />
                    Ideje:{" "}
                    {moment(i?.Event?.take_place_on).format("YYYY-MM-DD HH:mm")}
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color="success"
                      expand="block"
                      onClick={() => handleAccept(i.event_id)}
                    >
                      Elfogadom
                      <IonIcon icon={checkmarkSharp} slot="end" />
                    </IonButton>
                    <IonButton
                      color="dark"
                      expand="block"
                      onClick={() => setDeleteParticipantId(i.id)}
                    >
                      Köszi, nem
                      <IonIcon icon={closeSharp} slot="end" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          ))}
        </IonList>
      )}
    </>
  );
};

export default PendingInvitations;

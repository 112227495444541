import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonLabel,
  IonAvatar,
  IonImg,
  IonItem,
  IonIcon,
  IonNote,
  IonModal,
  IonButton,
  IonProgressBar,
  useIonLoading,
  useIonToast,
  IonAlert,
} from "@ionic/react";
import {
  alertCircleOutline,
  checkmarkOutline,
  closeOutline,
  ellipsisVerticalOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import { getErrorMessage } from "../../types/errorMessages";
import {
  CURRENCIES,
  TCurrency,
  TEventParticipant,
  TEventWish,
  TEventWishEngagement,
} from "../../types/event.type";
import BudgetModal from "./BudgetModal";
import ProfileLink from "./ProfileLink";
import PurchaseModal from "./PurchaseModal";
import ShoppingItem from "./ShoppingItem";

interface Props {}

const ShoppingList: React.FC = (props: Props) => {
  const { profile } = useAuthState();
  const {
    event,
    getEvent,
    getEventWishEngagements,
    updateEngagement,
    disengageWish,
    engageWish,
  } = useEventState();
  const participants = event.participants.filter(
    (e: TEventParticipant) =>
      e.can_post_wish && e.profile_id && e.profile_id !== profile.id
  );

  const [wishes, setWishes] = useState<Array<TEventWishEngagement>>([]);
  const [cost, setCost] = useState(0);
  const [budgetModal, setBudgetModal] = useState(false);

  /**
   * Load event Wish engagemenets
   */
  const loadWish = async () => {
    try {
      const engagements = await getEventWishEngagements({
        engaged_by: profile.id,
      });
      const c: number = engagements.data.rows.reduce(
        (acc: any, ew: TEventWishEngagement) => {
          if (ew.currency && ew.price && ew.is_purchased) {
            let price = +ew?.price!;
            if (ew.currency !== "HUF") {
              const curr = CURRENCIES.find(
                (c: TCurrency) => c.code === ew.currency
              );
              price = ew.price * curr?.rate!;
            }
            acc += price;
          }
          return acc;
        },
        0
      );
      setCost(c);
      setWishes(engagements.data.rows);
    } catch (error) {}
  };
  const getCostColor = (): string => {
    return cost > event.myProfile.planned_budget
      ? "danger"
      : cost > event.myProfile.planned_budget * 0.8
      ? "warning"
      : "success";
  };

  useEffect(() => {
    loadWish();
  }, [profile, event]);

  if (wishes) {
    return (
      <>
        <IonModal
          isOpen={budgetModal}
          onDidDismiss={() => setBudgetModal(false)}
          children={
            <BudgetModal
              onDismiss={() => {
                setBudgetModal(false);
                getEvent(event.id);
              }}
              eventParticipant={event.myProfile}
            />
          }
        />

        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
              <h2>Pénzügyek</h2>
              <IonList>
                <IonItem lines="full" onClick={() => setBudgetModal(true)}>
                  <IonLabel slot="start">Büdzsé:</IonLabel>
                  {event.myProfile.planned_budget > 0 && (
                    <IonLabel slot="end">
                      <NumberFormat
                        value={Math.round(event.myProfile.planned_budget!)}
                        thousandSeparator="&nbsp;"
                        decimalSeparator=","
                        suffix={" " + event.myProfile.planned_budget_currency}
                        displayType="text"
                      />
                    </IonLabel>
                  )}
                </IonItem>
                <IonItem lines="full">
                  <IonLabel slot="start">Eddig elköltött összeg:</IonLabel>
                  <IonLabel slot="end" color={getCostColor()}>
                    <NumberFormat
                      value={Math.round(cost)}
                      thousandSeparator="&nbsp;"
                      decimalSeparator=","
                      suffix={" " + "HUF"}
                      displayType="text"
                    />
                  </IonLabel>
                </IonItem>
                <IonItem lines="full">
                  <IonProgressBar
                    color={getCostColor()}
                    value={cost / event.myProfile.planned_budget}
                  ></IonProgressBar>
                </IonItem>
              </IonList>
              <h2>Ajándéklista</h2>
              {participants.map((participant: TEventParticipant) => {
                const pwish: Array<TEventWishEngagement> = wishes.filter(
                  (wish: TEventWishEngagement) =>
                    wish.Wish?.created_by == participant.profile_id
                );
                return (
                  <IonList key={participant.id}>
                    <IonListHeader>
                      <IonLabel>
                        <ProfileLink ep={participant} /> részére:
                      </IonLabel>
                    </IonListHeader>
                    {!pwish.length && (
                      <IonItem lines="full">
                        <IonIcon
                          icon={alertCircleOutline}
                          color="danger"
                          slot="start"
                        />
                        <IonLabel color="danger">
                          Még nincs kiválasztva ajándék!{" "}
                        </IonLabel>
                      </IonItem>
                    )}
                    {!!pwish.length &&
                      pwish.map((ew: TEventWishEngagement) => (
                        <ShoppingItem ew={ew} loadWish={loadWish} key={ew.id} />
                      ))}
                  </IonList>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  } else {
    return <></>;
  }
};

export default ShoppingList;

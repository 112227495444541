import React from "react";
interface Props {
  message: string;
}
const RecordNotFound: React.FC = ({ children }) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "13px",
        color: "#666666",
        width: "100%",
        margin: "15px",
      }}
    >
      {children}
    </div>
  );
};

export default RecordNotFound;

import {
  IonRow,
  IonCol,
  IonContent,
  IonLabel,
  IonIcon,
  IonButton,
  IonModal,
  useIonLoading,
  useIonToast,
  IonAlert,
  IonPage,
} from "@ionic/react";
import { addSharp, chevronBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../components/AuthProvider";
import { TProfile } from "../../types/profile.type";
import { EditProfilePage } from "./EditProfilePage";
import { ProfileComponent } from "./ProfileComponent";

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import HeaderComponent from "../../components/HeaderComponent";
import "./profile.scss";

const ProfilePage: React.FC = () => {
  const history = useHistory();
  let {
    user,
    logout,
    profiles,
    profile,
    deleteProfile,
    getProfiles,
    uploadProfilePicture,
  } = useAuthState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const [editProfileId, setEditProfileId] = useState<string>("");
  const [deleteProfileId, setdeleteProfileId] = useState<string>("");
  const [showEditModal, setshowEditModal] = useState<boolean>(false);

  const [showConfirm, setshowConfirm] = useState<boolean>(false);
  profile = profile || { id: "", name: "" };

  const takePhoto = async (profile_id: string) => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        quality: 100,
        source: CameraSource.Prompt,
      });
      await present();
      await uploadProfilePicture(profile_id, cameraPhoto.dataUrl);
      await getProfiles();
      presentToast("Fotó feltöltése rendben!", 1000);
      await dismiss();
    } catch (e: any) {
      console.log(e.response);
      presentToast({
        message: "A fotót nem lehet feltölteni. Próbáld meg később.",
        duration: 3000,
        color: "danger",
        position: "middle",
      });
      await dismiss();
    }
  };

  /**
   * Close modal
   */
  const handleDismiss = () => {
    setshowEditModal(false);
  };

  /**
   * Open edit modal
   * @param profile_id
   */
  const handleEdit = (profile_id: string) => {
    setEditProfileId(profile_id);
    setshowEditModal(true);
  };
  /**
   * Delete profile
   * @param profile_id
   */
  const handleDelete = async () => {
    if (deleteProfileId) {
      try {
        await present();
        await deleteProfile(deleteProfileId);
        await dismiss();
        presentToast("Profil törlése rendben", 1000);
        setdeleteProfileId("");
        await getProfiles();
      } catch (err) {
        await dismiss();
      }
    }
  };
  /**
   * Handle confirm dialog before delete
   * @param profile_id
   */
  const handleConfirm = (profile_id: string) => {
    setdeleteProfileId(profile_id);
    setshowConfirm(true);
  };

  useEffect(() => {
    if (typeof profiles !== undefined && profiles.length === 0) {
      handleEdit("new");
    }
  }, []);

  /**
   * Render
   */
  return (
    <IonPage>
      <HeaderComponent title="Profilbeállítások" />
      <IonContent fullscreen={true}>
        <IonButton fill="clear" size="small" onClick={() => history.goBack()}>
          <IonIcon icon={chevronBack} slot="start" />
          Vissza
        </IonButton>

        <IonAlert
          isOpen={showConfirm}
          onDidDismiss={() => setshowConfirm(false)}
          header="Profil törlése"
          message="Biztos, hogy törölni szerentéd ezt a profilt? A műveletet nem lehet visszavonni!"
          buttons={[
            "Mégsem",
            {
              text: "Igen, törlöm a profilt.",
              handler: (d) => handleDelete(),
            },
          ]}
        />
        <IonModal
          isOpen={showEditModal}
          backdropDismiss={false}
          children={
            <EditProfilePage
              editProfileId={editProfileId}
              onDismiss={handleDismiss}
            />
          }
        />
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
            <IonRow className="ion-align-items-center">
              <IonCol>
                <div className="ion-text-end">
                  <IonButton
                    shape="round"
                    onClick={() => handleEdit("new")}
                    color="success"
                  >
                    Új profil
                    <IonIcon icon={addSharp} slot="start" />
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>

            {profiles &&
              profiles.length &&
              profiles.map((p: TProfile) => (
                <ProfileComponent
                  profile={p}
                  key={p.id}
                  onEditClick={handleEdit}
                  isActive={false}
                  onDeleteClick={handleConfirm}
                  onPhotoClick={takePhoto}
                />
              ))}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;

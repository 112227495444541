import { IonChip, IonAvatar, IonLabel, IonImg } from "@ionic/react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ENV } from "../../config";
import { TEventParticipant } from "../../types/event.type";

type Props = RouteComponentProps & {
  ep: TEventParticipant;
};

const ProfileLink: React.FC<Props> = ({ ep, history }) => {
  const { profile } = ep;
  return (
    <IonChip
      color="primary"
      onClick={(e) =>
        history.push(`/event/${ep.event_id}/participants/${ep.id}`)
      }
    >
      <IonAvatar>
        <IonImg src={ENV.CDN_URL + profile.image_xs} />
      </IonAvatar>
      <IonLabel color="primary">{profile.name}</IonLabel>
    </IonChip>
  );
};

export default withRouter(ProfileLink);

import {
  IonPage,
  IonContent,
  IonModal,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
  useIonLoading,
  IonSearchbar,
  IonRefresherContent,
  IonRefresher,
  IonCard,
  IonCardContent,
  IonInput,
  IonLabel,
  IonItem,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { addSharp, closeSharp, searchSharp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../components/AuthProvider";
import { useEventState } from "../../components/EventProvider";
import HeaderComponent from "../../components/HeaderComponent";
import { TEvent } from "../../types/event.type";
import EventComponent from "./EventComponent";
import RecordNotFound from "../../components/RecordNotFound";

interface Props {}

const EventListPage: React.FC = (props: Props) => {
  const { getEvents } = useEventState();
  const { profile, getVariable, setVariable } = useAuthState();
  const [searchText, setSearchText] = useState<string>("");
  const [actualEvents, setActualEvents] = useState<boolean>(true);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [showSearchForm, setShowSearchForm] = useState<boolean>(false);
  const [eventArray, setEventArray] = useState<Array<TEvent>>([]);
  const loadRecords = async (event?: CustomEvent<RefresherEventDetail>) => {
    try {
      let params = {};
      if (searchText) {
        params = { name: searchText };
      }
      if (actualEvents) {
        params = { ...params, after: moment().format("YYYY-MM-DD") };
      } else {
        params = { ...params, before: moment().format("YYYY-MM-DD") };
      }
      const res = await getEvents(params);
      const ea = res.rows.reduce((acc: Array<TEvent>, event: TEvent) => {
        if (event.myProfile?.is_admin) {
          acc.push(event);
        } else {
          if (moment().diff(moment(event.open_on)) > 0) {
            acc.push(event);
          }
        }
        return acc;
      }, []);
      setEventArray(ea);
    } catch (err) {
      console.log(err);
    } finally {
      if (event) {
        event.detail.complete();
      }
    }
  };

  const handleSearch = async (term: string) => {
    setVariable("eventList.searchText", term);
    setSearchText(term);
  };

  useEffect(() => {
    Promise.all([
      getVariable("eventList.searchText"),
      getVariable("eventList.actualEvents"),
    ]).then((data: any) => {
      setSearchText(data[0]);
      setActualEvents(data[1] === null ? true : data[1]);
      if (data[0] || !data[1]) {
        setShowSearchForm(true);
      } else {
        setShowSearchForm(false);
      }
      setPageLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (pageLoaded) loadRecords();
  }, [searchText, actualEvents, pageLoaded]);

  return (
    profile && (
      <IonPage>
        <HeaderComponent title="Események" />
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={loadRecords}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="10" size-lg="8" size-xl="6">
              {!profile.is_limited && (
                <IonRow>
                  <IonCol>
                    <IonButton
                      shape="round"
                      routerLink="/new-event"
                      color="success"
                    >
                      Új Esemény
                      <IonIcon icon={addSharp} slot="start" />
                    </IonButton>
                  </IonCol>
                  <IonCol className="ion-text-end">
                    <IonButton
                      shape="round"
                      color="light"
                      onClick={() => setShowSearchForm(true)}
                    >
                      <IonIcon icon={searchSharp} slot="icon-only" />
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol>
                  {showSearchForm && (
                    <IonCard>
                      <IonCardContent>
                        <IonSearchbar
                          animated
                          placeholder="Keresés"
                          value={searchText}
                          onIonChange={(e) => handleSearch(e.detail.value!)}
                          debounce={1000}
                        ></IonSearchbar>
                        <IonRadioGroup
                          value={actualEvents}
                          onIonChange={(e: any) => {
                            setVariable(
                              "eventList.actualEvents",
                              e.detail.value
                            );
                            setActualEvents(e.detail.value);
                          }}
                        >
                          <IonItem>
                            <IonLabel>Csak az aktuális események</IonLabel>
                            <IonRadio slot="end" value={true} />
                          </IonItem>
                          <IonItem>
                            <IonLabel>Csak a régebbi események</IonLabel>
                            <IonRadio slot="end" value={false} />
                          </IonItem>
                        </IonRadioGroup>
                        <IonRow>
                          <IonCol className="ion-text-end">
                            <IonButton
                              shape="round"
                              color="light"
                              onClick={() => setShowSearchForm(false)}
                            >
                              Bezár
                              <IonIcon icon={closeSharp} slot="start" />
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>
                  )}
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-start">
                {eventArray.map((e: TEvent) => (
                  <IonCol
                    key={e.id}
                    size="12"
                    size-md="10"
                    size-lg="6"
                    size-xl="6"
                  >
                    <EventComponent event={e} />
                  </IonCol>
                ))}
                {eventArray.length === 0 && (
                  <RecordNotFound>Nincs megjeleníthető esemény!</RecordNotFound>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    )
  );
};

export default EventListPage;

import { StateMachineProvider, createStore } from "little-state-machine";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonPage,
  IonRouterOutlet,
} from "@ionic/react";
import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HeaderComponent from "../../components/HeaderComponent";

interface Props {}

const NewEventPage: React.FC = (props: Props) => {
  const [step, setStep] = useState<number>(1);
  return (
    <StateMachineProvider>
      <IonPage>
        <HeaderComponent title="Új esemény" />
        <IonContent>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                {step === 1 && <Step1 setStep={setStep} />}
                {step === 2 && <Step2 setStep={setStep} />}
                {step === 3 && <Step3 setStep={setStep} />}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </StateMachineProvider>
  );
};

export default NewEventPage;

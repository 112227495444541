import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useWebSocket from "react-use-websocket";
import { ENV } from "../config";
import { TEvent, TWSMessage } from "../types/event.type";
import { useAuthState } from "./AuthProvider";
import { Storage } from "@ionic/storage";
import { useEventState } from "./EventProvider";
const NOTIFICATIONS: string = "NOTIFICATIONS";
const store = new Storage();
var isStoreCreated: boolean = false;
const createStore = async (): Promise<void> => {
  try {
    if (!isStoreCreated) {
      await store.create();
      isStoreCreated = true;
    }
  } catch (e) {
    throw e;
  }
};

export interface ISocketContext {
  notifications: Array<TWSMessage>;
  setNotifications(notification: TWSMessage): Promise<any>;
  clearNotifications(): void;
}

const SocketContext = createContext<ISocketContext | any>(null);

export const useSocketState = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocketState must be used within a SocketProvider");
  }
  return context;
};

export const SocketProvider: React.FC = ({ children }) => {
  const [notifications, setNotifs] = useState<Array<TWSMessage>>([]);
  const { events, event, getEvent, getPendingInvitations, getCurrentEvents } =
    useEventState();
  const { jwt, user, profile } = useAuthState();
  const didUnmount = useRef(false);

  const loadNotifications = async (): Promise<any> => {
    await createStore();
    const notifs = await store.get(NOTIFICATIONS);
    if (notifs) {
      setNotifs(JSON.parse(notifs));
    }
  };

  const setNotifications = async (notification: TWSMessage): Promise<any> => {
    await createStore();
    const notifs = [notification, ...notifications];
    if (notifs) {
      await store.set(NOTIFICATIONS, JSON.stringify(notifs));
    }
    setNotifs(notifs);
  };

  const clearNotifications = async (): Promise<any> => {
    setNotifs([]);
    await store.remove(NOTIFICATIONS);
  };

  const { lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    ENV.WS_URL,
    {
      queryParams: { token: jwt },
      shouldReconnect: (closeEvent) => {
        return didUnmount.current === false;
      },
      reconnectAttempts: 100,
      reconnectInterval: 10000,
    }
  );

  useEffect(() => {
    if (!user) return;
    if (!lastJsonMessage) return;
    if (lastJsonMessage.type === "REFRESH") {
      getPendingInvitations();
      getCurrentEvents();
      return;
    }
    if (user?.id === lastJsonMessage.no_notification_user) return;
    if (
      profile?.id === lastJsonMessage.profile_id &&
      lastJsonMessage.type != "UPGRADE"
    )
      return;
    if (!events.find((e: TEvent) => e.id === lastJsonMessage.event_id)) return;
    if (event.id == lastJsonMessage.event_id) {
      getEvent(event.id);
    }
    setNotifications(lastJsonMessage);
  }, [lastJsonMessage]);

  useEffect(() => {
    loadNotifications();
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const value: ISocketContext = {
    notifications,
    setNotifications,
    clearNotifications,
  };
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { checkmarkSharp, closeSharp } from "ionicons/icons";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuthState } from "../../components/AuthProvider";
import { Controller, useForm } from "react-hook-form";

interface Props {
  onDismiss(): void;
}

type IFormInputs = {
  email_notifications_allowed: boolean;
  email_notifications_schedule: string;
  d0: boolean;
  d1: boolean;
  d2: boolean;
  d3: boolean;
  d4: boolean;
  d5: boolean;
  d6: boolean;
  time: string;
};
const schema = yup
  .object({
    time: yup.string().required("Ez a mező kötelező"),
  })
  .required();

const EditNotificationPage: React.FC<Props> = ({ onDismiss }) => {
  const { user, update, saveJWT, loadCurrentUser } = useAuthState();
  const [presentToast] = useIonToast();
  const [present, dismiss] = useIonLoading();

  const [minute, hour, day, month, weekday] =
    user.email_notifications_schedule.split(" ");
  const weekdays = weekday.split(",") || [];

  const defVal = {
    email_notifications_allowed: user.email_notifications_allowed,
    d1: weekdays.includes("1") || false,
    d2: weekdays.includes("2") || false,
    d3: weekdays.includes("3") || false,
    d4: weekdays.includes("4") || false,
    d5: weekdays.includes("5") || false,
    d6: weekdays.includes("6") || false,
    d0: weekdays.includes("0") || false,
    time:
      (hour < 10 ? `0${hour}` : hour) +
      ":" +
      (minute < 10 ? `0${minute}` : minute),
  };
  let {
    control,
    register,
    setError,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: defVal,
  });
  const watch_email_notifications_allowed = watch(
    "email_notifications_allowed",
    user.email_notifications_allowed
  );

  const onSubmit = async (data: IFormInputs) => {
    const [h, m] = data.time.split(":");
    let days: Array<number> = [];
    if (data.d0) days.push(0);
    if (data.d1) days.push(1);
    if (data.d2) days.push(2);
    if (data.d3) days.push(3);
    if (data.d4) days.push(4);
    if (data.d5) days.push(5);
    if (data.d6) days.push(6);
    data.email_notifications_schedule = `${parseInt(m)} ${parseInt(
      h
    )} * * ${days.join(",")}`;
    try {
      await present();
      const result = await update(data);
      await dismiss(); 
      if (result.status == 200) {
        await saveJWT(result.data?.token);
        await loadCurrentUser();
        presentToast("Adatmentés rendben", 2000);
        onDismiss();
      }
      if (result.data?.error) {
        console.log(result.data?.error);
      }
    } catch (err: any) {
      await dismiss(); 
      console.log(err);
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Email értesítések</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel>E-mail értesítések bekapcsolása</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="email_notifications_allowed"
                    />
                  </IonItem>
                  {watch_email_notifications_allowed && (
                    <>
                      <IonItem>
                        <IonLabel className="ion-text-wrap">
                          Mely napokon szeretnéd megkapni az értesítéseket?
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Hétfő</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d1", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d1"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Kedd</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d2", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d2"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Szerda</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d3", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d3"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Csütörtök</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d4", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d4"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Péntek</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d5", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d5"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Szombat</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d6", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d6"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Vasárnap</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={(e) =>
                                setValue("d0", e.detail.checked)
                              }
                            />
                          )}
                          control={control}
                          name="d0"
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>Mely időpontban?</IonLabel>
                        <Controller
                          render={({ field }) => (
                            <IonDatetime
                              doneText="Rendben"
                              cancelText="Mégsem"
                              displayFormat="H:mm"
                              {...field}
                              onIonChange={field.onChange}
                            />
                          )}
                          control={control}
                          name="time"
                        />
                        {errors.time?.message && (
                          <div className="ion-text-start errorMessage">
                            {errors.time?.message}
                          </div>
                        )}
                      </IonItem>
                    </>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-text-end">
              <IonButton type="submit" shape="round" color="primary">
                Mentés
                <IonIcon icon={checkmarkSharp} slot="end" />
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </>
  );
};

export default EditNotificationPage;

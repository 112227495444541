import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";

import { InAppBrowser } from "@ionic-native/in-app-browser";
import {
  documentTextOutline,
  globeOutline,
  helpCircleOutline,
  lockClosedOutline,
  logoFacebook,
} from "ionicons/icons";

const Info = () => {
  return (
    <IonList>
      <IonItem
        button
        onClick={() => InAppBrowser.create("https://ezkivi.hu", "_blank")}
        lines="full"
      >
        <IonIcon slot="start" icon={globeOutline} />
        <IonLabel>Általános ismertető</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() =>
          InAppBrowser.create("https://www.facebook.com/ezkivi", "_blank")
        }
        lines="full"
      >
        <IonIcon slot="start" icon={logoFacebook} />
        <IonLabel>Facebook</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() =>
          InAppBrowser.create(
            "https://www.facebook.com/groups/254666930035551",
            "_blank"
          )
        }
        lines="full"
      >
        <IonIcon slot="start" icon={helpCircleOutline} />
        <IonLabel>Segítség</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => InAppBrowser.create("https://ezkivi.hu/aszf", "_blank")}
        lines="full"
      >
        <IonIcon slot="start" icon={documentTextOutline} />
        <IonLabel>Általános Szerződési Feltételek</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() =>
          InAppBrowser.create("https://ezkivi.hu/adatkezeles", "_blank")
        }
        lines="full"
      >
        <IonIcon slot="start" icon={lockClosedOutline} />
        <IonLabel>Adatkezelési szabályzat</IonLabel>
      </IonItem>
    </IonList>
  );
};

export default Info;

import React, { useState, useRef, useEffect } from "react";
import {
  useIonLoading,
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonGrid,
  IonAlert,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  useIonToast,
} from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import { Link, useHistory, Redirect } from "react-router-dom";
import querystring from "querystring";
import "./account.scss";
import { useAuthState } from "../../components/AuthProvider";
import logo from "../../assets/logo.svg";
import PasswordReveal from "../../components/PasswordReveal";

const LoginPage: React.FC = () => {
  const AuthState = useAuthState();
  const [present, dismiss] = useIonLoading();
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isError, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageHeader, setmessageHeader] = useState<string>("");
  const [buttonTitle, setButtonTitle] = useState<string>("Következő");
  const [twofaPage, setTwofaPage] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [presentToast] = useIonToast();

  const [showForgottenPassword, setshowForgottenPassword] =
    useState<boolean>(false);
  const emailField = useRef<any>();
  const pwField = useRef<any>();

  useEffect(() => {
    const query: any = querystring.parse(window.location.search.substr(1));
    if (query.email) {
      setEmail(query.email);
    }
    if (query.token) {
      handleToken(query.email, query.token);
    }
  }, []);

  const handleToken = async (email: string, token: string): Promise<void> => {
    try {
      const result = await AuthState.login(email, "", token);
      const { data } = result;
      if (data?.token) {
        debugger;
        AuthState.setLoading(true);
        await AuthState.saveJWT(data?.token);
        const currentUser = await AuthState.loadCurrentUser();
        await AuthState.getProfiles();
        if (currentUser.authentication_method === "PASSWORD") {
          AuthState.setShowForgottenPassword(true);
        }
        AuthState.setLoading(false);
        //resetFields();
        return;
      }
    } catch (err: any) {
      let message: string = "";
      debugger;
      switch (err.response.data.error) {
        case "TOKEN_EXPIRED":
          message = "A megadott link érvényessége már lejárt!";
          setshowForgottenPassword(true);
          break;
        case "INCORRECT_TOKEN":
          message = "A megadott link érvénytelen!";
          setshowForgottenPassword(true);
          break;
        default:
          message = "Ismeretlen hiba";
      }
      setmessageHeader("Hiba történt");
      setMessage(message as string);
      setIserror(true);
    }
  };

  function validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  const resetFields = () => {
    setEmail("");
    setErrorEmail("");
    setPassword("");
    setShowPassword(false);
    setIserror(false);
    setButtonTitle("Tovább");
    setTwofaPage(false);
    setshowForgottenPassword(false);
    setCode("");
  };

  const handleForgottenPassword = async (e: any) => {
    e.preventDefault();
    try {
      console.log("handleForgottenPassword called");
      await present();
      const result = await AuthState.login(email, "", "", "", true);
      await dismiss();
      if (result.data.message === "EMAIL_SENT") {
        setMessage(
          "Elküldtünk egy emailt a megadott címre. A bejelentkezéshez kattints a benne lévő linkre!"
        );
        setmessageHeader("Elfelejtett jelszó");
        setIserror(true);
      }
    } catch (err: any) {
      await dismiss();
      // let message: string = "";
      // switch (err.response.data.error) {
      //   case "INCORRECT_PASSWORD":
      //     message = "A bejelentkezés sikertelen!";
      //     setshowForgottenPassword(true);
      //     break;
      //   default:
      //     message = "Ismeretlen hiba";
      // }
      setMessage(err.response.data.error);
      setIserror(true);
    }
  };

  const handleLogin = async (e: any) => {
    console.log("Handle login called");
    e.preventDefault();
    setErrorEmail("");
    setButtonTitle("Tovább");
    if (!email) {
      setErrorEmail("Ez a mező kötelező!");
      // emailField.current.setFocus();
      return;
    }
    if (!validateEmail(email)) {
      setErrorEmail("Hiba: érvénytelen email-formátum!");
    }

    try {
      await present();
      const result = await AuthState.login(email, password, "", code);
      await dismiss();

      const { data } = result;
      if (data?.token) {
        //resetFields();
        try {
          AuthState.setLoading(true);
          await AuthState.saveJWT(data?.token);
          await AuthState.loadCurrentUser();
          await AuthState.getProfiles();
          AuthState.setLoading(false);
          return;
        } catch (error) {
          AuthState.setLoading(false);
        }
      }
      if (data?.error) {
        let message = "";
        switch (result.error) {
          case "UNKNOWN_USER":
            message = "Ez az email cím még nincs regisztrálva!";
            break;
        }
        setMessage(message);
        setIserror(true);
      }
      if (data.message) {
        switch (data.message) {
          case "CODE_SENT":
            setTwofaPage(true);
            break;
          case "EMAIL_SENT":
            setMessage(
              "Elküldtünk egy üzenetet a megadott e-mail címre. A bejelentkezéshez kattints a benne lévő linkre!"
            );
            setmessageHeader("Jelszómentes bejelentkezés");
            setIserror(true);
            break;
        }
      }
      if (data?.authentication_method === "PASSWORD") {
        setShowPassword(true);
        setshowForgottenPassword(true);
        setTimeout(() => pwField.current.setFocus(), 100);
        setButtonTitle("Bejelentkezés");
      }
      if (data?.authentication_method === "EMAIL") {
        setShowPassword(false);
        setButtonTitle("Bejelentkezés");
      }
    } catch (err: any) {
      console.log(err);
      await dismiss();
      if (err.response) {
        let message: string = "";
        switch (err.response.data.error) {
          case "INCORRECT_PASSWORD":
            message = "A bejelentkezés sikertelen!";
            setshowForgottenPassword(true);
            break;
          case "UNKNOWN_USER":
            message = "Ismeretlen e-mail cím! Regisztráltál már?";
            break;
          case "INCORRECT_CODE":
            message = "A megadott kód érvénytelen!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setmessageHeader("Hiba történt:");
        setMessage(message as string);
        setIserror(true);
      } else {
        presentToast(err.message, 4000);
      }
    }
  };
  const twofa = (
    <>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeMd="9" sizeLg="4">
          <IonItem>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol size="2">
                  <IonIcon
                    icon={alertCircleOutline}
                    style={{ fontSize: "30px" }}
                  />
                </IonCol>
                <IonCol size="10">
                  Kérlek add meg a biztonsági kódot melyet emailben vagy SMS-ben
                  küldtünk el neked
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonLabel position="floating">Biztonsági kód</IonLabel>
            <IonInput
              type="text"
              value={code}
              onIonChange={(e) => setCode(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonCol>
      </IonRow>

      <IonRow className="ion-justify-content-center">
        <IonCol sizeMd="9" sizeLg="4">
          <div className="ion-text-end">
            <IonButton type="submit" color="primary" shape="round">
              Bejelentkezés
            </IonButton>
          </div>
        </IonCol>
      </IonRow>

      <IonRow
        className="ion-justify-content-center"
        style={{ marginTop: "64px" }}
      >
        <IonCol sizeMd="9" sizeLg="4" style={{ fontSize: "small" }}>
          Nem kaptad meg a kódot?{" "}
          <a href="#" onClick={handleLogin}>
            Kód újraküldése
          </a>
          <br /> <br />
          <hr />
          <IonButton
            fill="clear"
            onClick={(e) => {
              e.preventDefault();
              resetFields();
            }}
          >
            Vissza a bejelentkezéshez
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );

  const form = (
    <>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeMd="9" sizeLg="4">
          <IonItem>
            <IonLabel position="floating">Írd be az e-mail címed</IonLabel>
            <IonInput
              type="email"
              ref={emailField}
              value={email}
              onIonChange={(e) => {
                setEmail(e.detail.value!);
                setShowPassword(false);
                setButtonTitle("Következő");
                setPassword("");
                setshowForgottenPassword(false);
              }}
            ></IonInput>
          </IonItem>
          {errorEmail && (
            <div className="ion-text-start errorMessage">{errorEmail}</div>
          )}
        </IonCol>
      </IonRow>
      {showPassword && (
        <IonRow className="ion-justify-content-center">
          <IonCol sizeMd="9" sizeLg="4">
            <IonItem>
              <IonLabel position="floating">Jelszó</IonLabel>
              <PasswordReveal element="loginpassword" />
              <IonInput
                type="password"
                name="loginpassword"
                ref={pwField}
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
      )}
      <IonRow className="ion-justify-content-center">
        <IonCol sizeMd="9" sizeLg="4">
          <div className="ion-text-end">
            <IonButton type="submit" color="primary" shape="round">
              {buttonTitle}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
      <IonRow
        className="ion-justify-content-center"
        style={{ marginTop: "30px" }}
      >
        <IonCol sizeMd="9" sizeLg="4">
          {showForgottenPassword && (
            <a href="#" onClick={(e) => handleForgottenPassword(e)}>
              Elfelejtetted a jelszavadat?
            </a>
          )}
        </IonCol>
      </IonRow>
      <IonRow
        className="ion-justify-content-center"
        style={{ marginTop: "70px" }}
      >
        <IonCol sizeMd="9" sizeLg="4">
          Nem regisztráltál még? <Link to="/register">Regisztráció</Link>
        </IonCol>
      </IonRow>
    </>
  );

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIserror(false)}
          cssClass={"error"}
          header={messageHeader}
          message={message}
          buttons={["Bezár"]}
        />
        <form onSubmit={handleLogin}>
          <IonGrid fixed={true}>
            <IonRow
              style={{ paddingTop: "30px" }}
              className="ion-justify-content-center"
            >
              <IonCol sizeXs="12" sizeMd="9" sizeLg="4">
                <IonIcon
                  style={{ fontSize: "100px", color: "#0040ff" }}
                  icon={logo}
                  onClick={() => history.replace("/intro")}
                />
                <h2>Bejelentkezés</h2>
              </IonCol>
            </IonRow>
            {twofaPage ? twofa : form}
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;

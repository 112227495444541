import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  useIonToast,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import React from "react";
import { useEventState } from "../../components/EventProvider";
import { STYLES, TStyle } from "../../types/style";

interface Props {
  onDismiss(): void;
}

const EditTemplateModal: React.FC<Props> = ({ onDismiss }) => {
  const { event, settings, updateEvent, getEvent } = useEventState();
  const [presentToast, dismissToast] = useIonToast();

  const handleSelect = async (template_id: number) => {
    try {
      const res = await updateEvent(event.id, { template_id });
      const e = await getEvent(event.id);
      presentToast("Oké, beállítottuk az esemény színsablonát!", 3000);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {event && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Válassz egy színsablont</IonTitle>
              <IonButtons slot="end">
                <IonIcon
                  icon={closeSharp}
                  slot="end"
                  onClick={() => onDismiss()}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <div className="styleContainer">
                {STYLES.map((s: TStyle, i: number) => {
                  return (
                    <div
                      key={i}
                      onClick={() => handleSelect(i)}
                      style={{ backgroundColor: s.color }}
                      className="styleBlock"
                    ></div>
                  );
                })}
              </div>
            </IonGrid>
          </IonContent>
        </>
      )}
    </>
  );
};

export default EditTemplateModal;

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./account.scss";
import { useAuthState } from "../../components/AuthProvider";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToggle,
  useIonLoading,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../assets/logo.svg";
import * as yup from "yup";
import YupPassword from "yup-password";
import { Link } from "react-router-dom";
import { ERROR_MESSAGES, getErrorMessage } from "../../types/errorMessages";
import PasswordReveal from "../../components/PasswordReveal";
YupPassword(yup); // extend yup

type IFormInputs = {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  passwordConfirmation: string;
  [key: string]: any;
};

const schema = yup
  .object({
    email: yup
      .string()
      .email("Nem megfelelő email cím formátum!")
      .required("Kérlek, add meg az email címed!"),
    first_name: yup
      .string()
      .min(2, "Legalább ${min} betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    last_name: yup
      .string()
      .min(2, "Legalább 2 betű szükséges")
      .max(30, "Maximum ${max} betű lehetséges")
      .required("Kérlek, töltsd ki ezt a mezőt!"),
    password: yup
      .string()
      .min(8, "Legalább ${min} karakter szükséges")
      .max(72, "Maximum ${min} karakter lehetséges")
      .minLowercase(1, "Legalább 1 kisbetű szükséges")
      .minUppercase(1, "Legalább 1 nagybetű szükséges")
      .minNumbers(1, "Legalább 1 szám szükséges"),
    //  .minSymbols(1, 'Legalább 1 egyéb szimbólum szükséges, például: + - _ * & # ! %')
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "A megadott jelszavak nem egyeznek!"),
  })
  .required();

const RegisterPage: React.FC = () => {
  const AuthState = useAuthState();
  const [present, dismiss] = useIonLoading();
  const [isError, setIserror] = useState<boolean>(false);
  const [isAszfAccepted, setIsAszfAccepted] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageHeader, setMessageHeader] = useState<string>("");
  const history = useHistory();
  let {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data: IFormInputs) => {
    if (Object.keys(errors).length) {
      return;
    }
    try {
      await present();
      const result = await AuthState.register(data);
      await dismiss();
      if (result.data?.token) {
        await AuthState.saveJWT(result.data?.token);
        await AuthState.loadCurrentUser();
        history.replace("/home");
        return;
      }
      if (result.data?.error) {
        console.log(result.data?.error);
      }
    } catch (err: any) {
      await dismiss();
      if (err.response.data.errors) {
        for (let i in err.response.data.errors) {
          const errObj = err.response.data.errors[i];
          for (let field in errObj) {
            setError(field, {
              type: "manual",
              message: getErrorMessage(errObj[field]),
            });
          }
        }
      } else {
        let message: string = "";
        switch (err.response.data.error) {
          case "USER_EXISTS":
            message = "Ez az email cím már regisztrálva van!";
            break;
          default:
            message = "Ismeretlen hiba";
        }
        setMessageHeader("Oppsz!");
        setMessage(message as string);
        setIserror(true);
      }
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIserror(false)}
          cssClass={"error"}
          header={messageHeader}
          message={message}
          buttons={["Bezár"]}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow
              style={{ paddingTop: "20px" }}
              className="ion-justify-content-center"
            >
              <IonCol sizeXs="12" sizeMd="9" sizeLg="4">
                <IonIcon
                  onClick={() => history.replace("/intro")}
                  style={{ fontSize: "100px", color: "#0040ff" }}
                  icon={logo}
                />
                <h2>Regisztráció</h2>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol
                sizeMd="9"
                sizeLg="4"
                className="ion-justify-content-start"
              >
                <IonItem>
                  <IonLabel position="floating">E-mail cím</IonLabel>
                  <Controller
                    render={({ field }) => (
                      <IonInput
                        type="email"
                        {...field}
                        onIonChange={field.onChange}
                      />
                    )}
                    control={control}
                    name="email"
                  />
                </IonItem>
                {errors.email?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.email?.message}
                  </div>
                )}

                <IonItem>
                  <IonLabel position="floating">Vezetéknév</IonLabel>
                  <Controller
                    render={({ field }) => (
                      <IonInput {...field} onIonChange={field.onChange} />
                    )}
                    control={control}
                    name="last_name"
                  />
                </IonItem>
                {errors.last_name?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.last_name?.message}
                  </div>
                )}

                <IonItem>
                  <IonLabel position="floating">Keresztnév</IonLabel>
                  <Controller
                    render={({ field }) => (
                      <IonInput {...field} onIonChange={field.onChange} />
                    )}
                    control={control}
                    name="first_name"
                  />
                </IonItem>
                {errors.first_name?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.first_name?.message}
                  </div>
                )}

                <IonItem>
                  <IonLabel position="floating">Jelszó</IonLabel>
                  <PasswordReveal element="password" />
                  <Controller
                    render={({ field }) => (
                      <IonInput
                        type="password"
                        {...field}
                        onIonChange={field.onChange}
                      />
                    )}
                    control={control}
                    name="password"
                  />
                </IonItem>
                {errors.password?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.password?.message}
                  </div>
                )}

                <IonItem>
                  <IonLabel position="floating">Jelszó ellenőrzése</IonLabel>
                  <PasswordReveal element="passwordConfirmation" />
                  <Controller
                    render={({ field }) => (
                      <IonInput
                        type="password"
                        {...field}
                        onIonChange={field.onChange}
                      />
                    )}
                    control={control}
                    name="passwordConfirmation"
                  />
                </IonItem>
                {errors.passwordConfirmation?.message && (
                  <div className="ion-text-start errorMessage">
                    {errors.passwordConfirmation?.message}
                  </div>
                )}
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeXs="12" sizeMd="9" sizeLg="4">
                A regisztráció előtt kérlek olvasd el az{" "}
                <a
                  href="https://ezkivi.hu/aszf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Általános Szerződési Feltételeket
                </a>
                .
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    Kijelentem, hogy elfogadom az Általános Szerződési
                    Feltételeket
                  </IonLabel>
                  <IonToggle
                    onIonChange={(e) => setIsAszfAccepted(e.detail.checked)}
                  ></IonToggle>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol sizeMd="9" sizeLg="4">
                <div className="ion-text-end">
                  <IonButton
                    type="submit"
                    color="primary"
                    shape="round"
                    disabled={
                      Object.keys(errors).length || !isAszfAccepted
                        ? true
                        : false
                    }
                  >
                    Regisztráció
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center"
              style={{ marginTop: "30px" }}
            >
              <IonCol sizeMd="9" sizeLg="4">
                Regisztráltál már? <Link to="/login">Bejelentkezés</Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPage;

import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonSearchbar,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addSharp, checkmarkSharp, closeSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useEventState } from "../../components/EventProvider";
import { useWishState } from "../../components/WishProvider";
import { ENV } from "../../config";
import { TEventWish } from "../../types/event.type";
import { TWish } from "../../types/wish.type";

interface Props {
  onDismiss(openNewWish: boolean): void;
  eventWishes: Array<TEventWish>;
}

const WishPicker: React.FC<Props> = ({ onDismiss, eventWishes }) => {
  const { wishes, wishCount, getWishes } = useWishState();
  const { delegateWish, getEvent, event } = useEventState();
  const [searchText, setSearchText] = useState<string>("");
  const [selectedWishes, setSelectedWishes] = useState<Array<string>>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [page, setPage] = useState(0);

  const handleSearch = async (term: string) => {
    setSearchText(term);
  };

  const handleDelegateWish = async () => {
    for (let i = 0; i < selectedWishes.length; i++) {
      try {
        await delegateWish(selectedWishes[i]);
      } catch (error) {}
    }
    await getEvent(event.id);
    onDismiss(false);
  };

  const loadWishes = async (ev: any) => {
    try {
      setPage(page + 1);
      const wishes = await getWishes({ name: searchText, page: page + 1 });
      ev.target.complete();
      if (!wishes.length) {
        setInfiniteDisabled(true);
      }
    } catch (error) {
      console.log(error);
      ev.target.complete();
    }
  };

  useEffect(() => {
    setInfiniteDisabled(false);
    setPage(0);
    getWishes({ name: searchText });
  }, [searchText]);

  const filteredWishes = wishes.filter(
    (w: TWish) => !eventWishes.find((e: TEventWish) => e.wish_id === w.id)
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Válassz:</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={closeSharp}
              slot="end"
              onClick={() => onDismiss(false)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className="ion-justify-content-center">
          <IonCol>
            <IonSearchbar
              animated
              placeholder="Keresés"
              value={searchText}
              onIonChange={(e) => handleSearch(e.detail.value!)}
              debounce={1000}
            ></IonSearchbar>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol>
            <div className="ion-text-center">
              <IonNote color="tertiary">
                Összesen {wishCount} kívánság található
              </IonNote>
              <br />
              <IonButton color="warning" onClick={async () => onDismiss(true)}>
                <IonIcon icon={addSharp} /> Új kivánság
              </IonButton>
            </div>
            <IonList style={{ marginBottom: "70px" }}>
              {wishes.map((w: TWish) => (
                <IonItem key={w.id}>
                  <IonThumbnail slot="start">
                    <IonImg src={ENV.CDN_URL + w.image_xs} />
                  </IonThumbnail>
                  <IonLabel>{w.name}</IonLabel>
                  {!eventWishes.find((e: TEventWish) => e.wish_id === w.id) && (
                    <IonCheckbox
                      checked={selectedWishes.includes(w.id!)}
                      onIonChange={(e) => {
                        let i = selectedWishes.indexOf(w.id!);
                        if (i > -1) {
                          selectedWishes.splice(i, 1);
                        } else {
                          selectedWishes.push(w.id!);
                        }
                        setSelectedWishes([...selectedWishes]);
                      }}
                    />
                  )}
                  {eventWishes.find((e: TEventWish) => e.wish_id === w.id) && (
                    <IonNote slot="end">Már hozzáadva!</IonNote>
                  )}
                </IonItem>
              ))}
            </IonList>

            <IonInfiniteScroll
              onIonInfinite={loadWishes}
              threshold="50px"
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Adatok letöltése..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonCol>
        </IonRow>
        {!!selectedWishes.length && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              color="success"
              onClick={async () => await handleDelegateWish()}
            >
              <IonIcon icon={checkmarkSharp} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </>
  );
};

export default WishPicker;

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonImg,
} from "@ionic/react";
import React from "react";
import { NavigationComponent } from "./NavigationComponent";
import { ProfileChipComponent } from "./ProfileChipComponent";
import logo from "../assets/logo.svg";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotificationComponent } from "./NotificationComponent";
type Props = RouteComponentProps & {
  title: string;
};

const HeaderComponent: React.FC<Props> = ({ title, history }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="end">
          <NotificationComponent />
          <ProfileChipComponent />
          <NavigationComponent />
        </IonButtons>
        <IonTitle text-left>
          <IonImg
            src={logo}
            onClick={() => {
              history.replace("/home");
            }}
          />
          {title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(HeaderComponent);

import { IonAvatar, IonChip, IonLabel } from "@ionic/react";
import React from "react";
import { useAuthState } from "./AuthProvider";
import { ENV } from "../config";

interface Props {}

export const ProfileChipComponent: React.FC = (props: Props) => {
  const { profile, setShowProfileChangeModal } = useAuthState();
  if (!profile) {
    return <></>;
  } else {
    let image = <img src={ENV.CDN_URL + profile.image_xs} />;

    return (
      <>
        <IonChip
          color="primary"
          onClick={() => setShowProfileChangeModal(true)}
        >
          <IonAvatar>{image}</IonAvatar>
          <IonLabel>{profile.name}</IonLabel>
        </IonChip>
      </>
    );
  }
};

import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { closeCircleSharp, createSharp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useWishState } from "../../components/WishProvider";
import { TWish, TWishReceive } from "../../types/wish.type";
interface Props {
  wish: TWish;
  editHandler(wishReceive: TWishReceive): void;
  deleteHandler(wishReceive: TWishReceive): void;
}

const ReceiveList: React.FC<Props> = ({ wish, editHandler, deleteHandler }) => {
  const { getWishReceives } = useWishState();
  const [receives, setReceives] = useState<TWishReceive[]>([]);
  const loadWishes = async () => {
    try {
      const res = await getWishReceives(wish.id);
      setReceives(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadWishes();
  }, [wish]);

  return (
    <IonGrid>
      {!!receives.length && (
        <IonRow className="table-header ion-align-items-center">
          <IonCol>Esemény</IonCol>
          <IonCol>Kitől?</IonCol>
          <IonCol>Mennyit?</IonCol>
          <IonCol>Megjegyzés</IonCol>
          <IonCol>Dátum</IonCol>
          <IonCol> </IonCol>
        </IonRow>
      )}

      {receives &&
        receives.map((receive: TWishReceive) => (
          <IonRow key={receive.id} className=" ion-align-items-center">
            <IonCol>{receive.event}</IonCol>
            <IonCol>{receive.received_from}</IonCol>
            <IonCol>{receive.qty}</IonCol>
            <IonCol>{receive.remark}</IonCol>
            <IonCol>{moment(receive.created_at).format("YYYY-MM-DD")}</IonCol>
            <IonCol>
              <IonButton
                shape="round"
                color="warning"
                size="small"
                onClick={() => {
                  console.log(receive);
                  editHandler(receive);
                }}
              >
                <IonIcon slot="icon-only" icon={createSharp} />
              </IonButton>
              <IonButton
                shape="round"
                color="danger"
                size="small"
                onClick={() => deleteHandler(receive)}
              >
                <IonIcon slot="icon-only" icon={closeCircleSharp} />
              </IonButton>
            </IonCol>
          </IonRow>
        ))}
    </IonGrid>
  );
};

export default ReceiveList;

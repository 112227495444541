import React from "react";
import { useAuthState } from "../../components/AuthProvider";
import { Controller, useForm } from "react-hook-form";
import { useEventState } from "../../components/EventProvider";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
  IonSelect,
  IonSelectOption,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { closeSharp, checkmarkSharp } from "ionicons/icons";
import { TProfile } from "../../types/profile.type";
import { TEventParticipant } from "../../types/event.type";
import { getErrorMessage } from "../../types/errorMessages";

interface Props {
  onDismiss(): void;
}

export type IFormInputs = {
  email: string;
  can_post_wish: boolean;
  can_engage_wish: boolean;
  is_admin: boolean;
  [key: string]: any;
};
const schema = yup
  .object({
    profile_id: yup.string().required("Kérlek, töltsd ki ezt a mezőt!"),
    can_post_wish: yup.boolean(),
    can_engage_wish: yup.boolean(),
    is_admin: yup.boolean(),
  })
  .required();

const AddProfileToEvent: React.FC<Props> = ({ onDismiss }) => {
  const { event, addProfile, getEvent } = useEventState();
  const { profile, profiles } = useAuthState();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();
  const {
    control,
    register,
    clearErrors,
    setValue,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      can_engage_wish: false,
      can_post_wish: false,
      is_admin: false,
    },
  });
  const selectedProfileId = watch("profile_id");
  const selectedProfile = profiles.find(
    (p: TProfile) => p.id === selectedProfileId
  );
  var showOption = selectedProfile && selectedProfile.is_limited ? false : true;
  const availableProfiles = profiles.filter(
    (p: TProfile) =>
      !event.participants.find((ep: TEventParticipant) => ep.profile_id == p.id)
  );
  if (availableProfiles.length == 0) {
    setTimeout(() => onDismiss(), 100);
    return <></>;
  }
  const onSubmit = async (data: IFormInputs) => {
    try {
      await present();
      const res = await addProfile(data);
      await getEvent(event.id);
      await dismiss();
      presentToast({
        message: "Oké, hozzáadtuk a profilt!",
        duration: 3000,
        position: "bottom",
        color: "dark",
      });
      setValue("profile_id", "");
      setTimeout(() => clearErrors(), 100);
    } catch (err: any) {
      await dismiss();
      const { error } = err.response.data;
      if (error) {
        setError("profile_id", {
          type: "manual",
          message: getErrorMessage(error),
        });
      }
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profil hozzáadása eseményhez</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel>Profil</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonSelect
                          slot="end"
                          interface="alert"
                          {...field}
                          placeholder="Válassz egy profilt"
                          onIonChange={field.onChange}
                        >
                          {availableProfiles.map((p: TProfile) => (
                            <IonSelectOption value={p.id} key={p.id}>
                              {p.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                      control={control}
                      name="profile_id"
                    />
                  </IonItem>
                  {errors.profile_id?.message && (
                    <div className="ion-text-start errorMessage">
                      {errors.profile_id?.message}
                    </div>
                  )}
                  <IonItem>
                    <IonLabel>Hozzáadhat kívánságot</IonLabel>
                    <Controller
                      render={({ field }) => (
                        <IonToggle
                          checked={field.value}
                          onIonChange={(e) => field.onChange(e.detail.checked)}
                        ></IonToggle>
                      )}
                      control={control}
                      name="can_post_wish"
                    />
                  </IonItem>
                  {showOption && (
                    <IonItem>
                      <IonLabel>Teljesíthet kívánságot</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonToggle
                            checked={field.value}
                            onIonChange={(e) =>
                              field.onChange(e.detail.checked)
                            }
                          ></IonToggle>
                        )}
                        control={control}
                        name="can_engage_wish"
                      />
                    </IonItem>
                  )}
                  {showOption && event.myProfile.is_admin && (
                    <IonItem>
                      <IonLabel>Adminisztrátor</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonToggle
                            checked={field.value}
                            onIonChange={(e) =>
                              field.onChange(e.detail.checked)
                            }
                          ></IonToggle>
                        )}
                        control={control}
                        name="is_admin"
                      />
                    </IonItem>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-text-end">
                <IonButton type="submit" shape="round" color="primary">
                  Mentés
                  <IonIcon icon={checkmarkSharp} slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </>
  );
};

export default AddProfileToEvent;

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ENV } from "../config";
import { TWSMessage } from "../types/event.type";

type Props = RouteComponentProps & {
  messages: Array<TWSMessage>;
  onDismiss(): void;
  deleteMessages(): void;
};

const ShowMessage: React.FC<Props> = ({
  messages,
  onDismiss,
  deleteMessages,
  history,
}) => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle> Legutóbbi tevékenységek</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeSharp} slot="end" onClick={onDismiss} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center">
        <IonList>
          {messages.length &&
            messages.map((message: TWSMessage, i: number) => (
              <IonItem
                key={i}
                onClick={() => {
                  history.replace(message.url.replace(ENV.APP_URL, ""));
                  onDismiss();
                }}
              >
                <IonLabel className="ion-text-wrap">
                  {i + 1}.) {message.entry!}
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
        <div className="ion-text-center">
          <IonButton
            onClick={() => {
              deleteMessages();
              onDismiss();
            }}
          >
            Értesítések törlése
          </IonButton>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(ShowMessage);
